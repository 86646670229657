import { Theme, useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faEllipsisV,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Cell as CellType,
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  Row as RowType,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { noop } from "lodash";
import React, { createElement, ReactNode, useEffect, useMemo } from "react";
import ContentLoader from "react-content-loader";
import { ResourceType } from "../../../constants/enums";
import Box from "../../components/Box";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";
import Flex from "../../components/Flex";
import Icon from "../../components/Icon";
import Pagination from "../../components/Pagination";
import ResourceSelector from "../../components/ResourceSelector";
import Text from "../../components/Text";
import Tooltip from "../../components/Tooltip";
import copyText from "../../copyText";
import useRefFn from "../../hooks/useRefFn";
import { COMPARISON_KEY } from "../utils";
import {
  Cell,
  FooterCell,
  FooterRow,
  HeaderCell,
  HeaderRow,
  Row,
  StyledActionMenuButton,
  StyledTable,
} from "./styled";

interface TableProps<TData> extends TableOptions<TData> {
  alternateRows?: boolean;
  clickableRows?: boolean;
  compact?: boolean;
  footer?: boolean;
  footerDelta?: boolean;
  isLoading?: boolean;
  pinnedColumns?: number;
  placeholderRowLimit?: number;
  resourceType?: ResourceType;
  rowHeight?: string;
  selectedRowID?: string;
  showPagination?: boolean;
  sortable?: boolean;
  truncateRows?: boolean;
  disableRow?: (row: RowType<TData>) => boolean;
  isRowHighlighted?: (datum: TData) => boolean;
  onChangePage?: (pageContent: TData[], pageIndex: number) => void;
  onChangeSortBy?: (sortRule: { id: string; desc: boolean }[]) => void;
  onClick?: (data: TData) => void;
}

interface CustomColumnMeta {
  align?: "center" | "left" | "right";
  disableClick?: boolean;
  truncate?: boolean;
}

const SKELETON_ANIMATION_TIME = 1.5;

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue>
    extends CustomColumnMeta {}
}

export default function Table<TData extends Record<string, unknown>>(
  props: Omit<TableProps<TData>, "getCoreRowModel" | "sortingFns">
): JSX.Element {
  const theme = useTheme();

  const tableInstance = useReactTable<TData>({
    columns: props.columns,
    data: props.data,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel:
      props.showPagination || props.truncateRows
        ? getPaginationRowModel()
        : undefined,
    getSortedRowModel: props.sortable ? getSortedRowModel() : undefined,
    initialState: props.initialState,
  });

  // onSortingChange
  const sortBy = tableInstance.getState().sorting ?? [];

  useEffect(() => {
    if (tableInstance.getState().sorting && props.onChangeSortBy) {
      const sortRules = sortBy.map((sortBy) => ({
        id: sortBy.id,
        desc: !!sortBy.desc,
      }));
      props.onChangeSortBy(sortRules);
    }
  }, [sortBy?.[0]?.id, sortBy?.[0]?.desc]);

  const pinnedColumns = props.pinnedColumns ?? 0;
  const pinnedWidths = tableInstance
    .getAllColumns()
    .slice(0, pinnedColumns)
    .map((column) => column.getSize() ?? 150);

  const smallestColumnWidth = Math.min(...pinnedWidths);

  function getLeftPosition(index: number): number | undefined {
    if (index >= pinnedColumns) {
      return undefined;
    }

    //Shrink column sizes to smallest pinned column when more than 3 dimensions are selected
    const columnWidths =
      pinnedColumns > 3
        ? Array(pinnedColumns).fill(smallestColumnWidth)
        : pinnedWidths;

    let left = 0;

    columnWidths.forEach((width, colIndex) => {
      if (colIndex < index) {
        left += width;
      }
    });

    return left;
  }

  function handleClickRow(row: RowType<TData>): void {
    if (!props.onClick) return;
    props.onClick(row.original);
  }

  const handleChangePage = useRefFn(props.onChangePage ?? (() => {}));

  const { pagination, sorting: sortingState } = tableInstance.getState();

  // If the sorting state has changed, the contents of the page may have changed
  // This matches v7 functionality
  const sortingStateString = sortingState
    .map((state) => `${state.id}-${state.desc}`)
    .join("_");

  const rowModel = tableInstance.getRowModel();
  const rows = rowModel.rows;

  useEffect(() => {
    if (props.isLoading) {
      handleChangePage([], 0);
      return;
    }

    handleChangePage(
      rows.map((row) => row.original),
      pagination.pageIndex
    );
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    props.isLoading,
    sortingStateString,
  ]);

  //
  // Render
  //

  function renderRow(row: RowType<TData>) {
    const clickable = isClickable(row, props.clickableRows, props.disableRow);

    const isRowDisabled = props.disableRow?.(row) ?? false;

    const isRowHighlighted = props.isRowHighlighted?.(row.original) ?? false;

    const isRowSelected =
      props.selectedRowID !== undefined &&
      props.selectedRowID === row.original.id;

    const resourceID = row.original.resourceID as string | undefined;
    const resourceContext = row.original.resourceContext as
      | { forecast: number }
      | undefined;
    const resourceName = row.original.resourceName as string | undefined;

    return (
      <Row
        key={row.id}
        alternate={props.alternateRows}
        clickable={clickable}
        compact={props.compact}
        footer={props.footer}
        height={props.rowHeight}
        role="row"
        onClick={clickable ? () => handleClickRow(row) : noop}
      >
        {props.resourceType && resourceID && resourceName && (
          <ResourceSelector
            key={row.id}
            resourceID={resourceID}
            resourceContext={resourceContext}
            resourceName={resourceName}
            resourceType={props.resourceType}
          />
        )}

        {row.getVisibleCells().map((cell, index) => {
          return renderCell({
            cell,
            index,
            isDisabled: isRowDisabled,
            isHighlighted: isRowHighlighted,
            isSelected: isRowSelected,
          });
        })}
      </Row>
    );
  }

  function renderCell(params: {
    cell: CellType<TData, unknown>;
    index: number;
    isDisabled: boolean;
    isHighlighted: boolean;
    isSelected: boolean;
  }) {
    const { cell, index } = params;
    const { align, disableClick, truncate } = cell.column.columnDef.meta ?? {};
    const key = cell.id;
    const size = cell.column.getSize();
    const value = cell.getValue();

    return (
      <Cell
        key={key}
        align={align}
        disabled={params.isDisabled}
        footer={props.footer}
        isCellMasked={key.toString().includes(COMPARISON_KEY)}
        isHighlighted={params.isHighlighted}
        isSelected={params.isSelected}
        left={getLeftPosition(index)}
        role="cell"
        size={size}
        onClick={disableClick ? (event) => event.stopPropagation() : noop}
      >
        {truncate ? (
          <Box width="100%">
            <Tooltip
              hide={typeof value !== "string"}
              content={typeof value === "string" ? value : ""}
              delayHide={250}
            >
              <Text
                color={
                  params.isSelected
                    ? theme.text_color_inverse
                    : theme.text_color
                }
                truncate
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Text>
            </Tooltip>
          </Box>
        ) : (
          flexRender(cell.column.columnDef.cell, cell.getContext())
        )}
      </Cell>
    );
  }

  if (props.isLoading) {
    return (
      <LoadingTable
        alternateRows={props.alternateRows}
        columns={props.columns}
        compact={props.compact}
        footer={props.footer}
        footerDelta={props.footerDelta}
        rowHeight={props.rowHeight}
        rowLimit={
          props.placeholderRowLimit
            ? props.placeholderRowLimit
            : props.showPagination
              ? pagination.pageSize
              : undefined
        }
        showPagination={props.showPagination}
      />
    );
  }

  return (
    <>
      <StyledTable compact={props.compact} role="table" width="100%">
        <thead
          style={{
            position: props.showPagination ? "inherit" : "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          {tableInstance.getHeaderGroups().map((headerGroup) => {
            return (
              <HeaderRow
                key={headerGroup.id}
                compact={props.compact}
                role="row"
              >
                {headerGroup.headers.map((header, columnIndex) => {
                  const column = header.column;

                  const align = column.columnDef.meta?.align;
                  const canSort = !!props.sortable && column.getCanSort();
                  const isSorted = !!column.getIsSorted();
                  const isSortedDesc = column.getIsSorted() === "desc";
                  const size = column.getSize();

                  const sortArrow =
                    canSort && isSorted ? (
                      <Box marginHorizontal={theme.space_xs}>
                        <FontAwesomeIcon
                          color={theme.primary_color_text}
                          icon={isSortedDesc ? faArrowDown : faArrowUp}
                        />
                      </Box>
                    ) : null;

                  return (
                    <HeaderCell
                      align={align}
                      colSpan={header.colSpan}
                      isPinned={props.pinnedColumns !== undefined}
                      key={header.id}
                      left={getLeftPosition(columnIndex)}
                      role="columnheader"
                      size={size}
                      sortable={canSort}
                      onClick={
                        canSort ? header.column.getToggleSortingHandler() : noop
                      }
                    >
                      <Flex>
                        {align === "right" ? sortArrow : null}
                        {flexRender(
                          column.columnDef.header,
                          header.getContext()
                        )}
                        {align !== "right" ? sortArrow : null}
                      </Flex>
                    </HeaderCell>
                  );
                })}
              </HeaderRow>
            );
          })}
        </thead>

        {/* BODY */}
        <tbody role="rowgroup">
          {rows.length === 0 && (
            <tr>
              <EmptyPlaceholder
                height={500}
                icon={faList}
                loading={false}
                text={copyText.noDataPlaceholderMessage}
              />
            </tr>
          )}

          {/* ROWS */}
          {rows.map(renderRow)}
        </tbody>

        {/* FOOTER */}
        {props.footer && (
          <tfoot>
            {tableInstance.getFooterGroups().map((group) => {
              return (
                <FooterRow
                  key={group.id}
                  compact={props.compact}
                  footerDelta={props.footerDelta}
                >
                  {group.headers.map((footer, i: number) => {
                    const align = footer.column.columnDef.meta?.align;
                    const size = footer.column.getSize();

                    return (
                      <FooterCell
                        key={footer.id}
                        colSpan={footer.colSpan}
                        left={getLeftPosition(i)}
                        size={size}
                        align={align}
                        isSelected={false}
                      >
                        <Box width="100%">
                          {i === 0
                            ? copyText.dataTableTotalsHeader
                            : flexRender(
                                footer.column.columnDef.footer,
                                footer.getContext()
                              )}
                          {props.footerDelta && i === 0 && (
                            <Text color={theme.text_color} truncate>
                              {copyText.dataTableTotalsDeltasHeader}
                            </Text>
                          )}
                        </Box>
                      </FooterCell>
                    );
                  })}
                </FooterRow>
              );
            })}
          </tfoot>
        )}
      </StyledTable>
      {props.data.length > 0 && props.showPagination ? (
        <Pagination
          currentPageIndex={pagination.pageIndex}
          loading={false}
          pageSize={pagination.pageSize}
          totalPageCount={tableInstance.getPageCount()}
          totalRows={props.data.length}
          onPageChange={(page) => tableInstance.setPageIndex(page)}
        />
      ) : null}
    </>
  );
}

export function Skeleton(props: { height: string; theme: Theme }): JSX.Element {
  const RADIUS = Number(props.theme.borderRadius_1.replace("px", ""));

  return (
    <ContentLoader
      backgroundColor={props.theme.loading_skeleton_bg_color}
      foregroundColor={props.theme.loading_skeleton_fg_color}
      speed={SKELETON_ANIMATION_TIME}
      viewBox="0 0 300 100"
      preserveAspectRatio="none"
      style={{
        width: "80%",
        height: props.height,
      }}
    >
      <rect x="0" y="0" rx={RADIUS} ry={RADIUS} width="300" height="90" />
    </ContentLoader>
  );
}

function isClickable<TData>(
  row: RowType<TData>,
  clickableRows?: boolean,
  disableRow?: (row: RowType<TData>) => boolean
) {
  if (clickableRows && disableRow) {
    return !disableRow(row);
  }
  return clickableRows;
}

//
// Loading Table
//

type LoadingData = {
  loading: true;
};

type LoadingTableProps<TData extends Record<string, unknown>> = {
  alternateRows?: boolean;
  columns: ColumnDef<TData>[];
  compact?: boolean;
  footer?: boolean;
  footerDelta?: boolean;
  rowHeight?: string;
  rowLimit?: number;
  showPagination?: boolean;
};

function LoadingTable<TData extends Record<string, unknown>>(
  props: LoadingTableProps<TData>
) {
  const theme = useTheme();

  const loadingColumnHelper = createColumnHelper<LoadingData>();

  const loadingColumns = useMemo(
    () =>
      props.columns.map((column, index) =>
        loadingColumnHelper.display({
          id: `loading-${index}`,
          cell: () => <Skeleton height={theme.h3_fontSize} theme={theme} />,
          header: typeof column.header === "string" ? column.header : "",
          meta: { align: column.meta?.align },
          size: column.size,
        })
      ),
    [props.columns]
  );

  const loadingData = useMemo<LoadingData[]>(
    () => new Array(10).fill({ loading: true }),
    []
  );

  const loadingTableInstance = useReactTable<LoadingData>({
    columns: loadingColumns,
    data: loadingData,
    getCoreRowModel: getCoreRowModel(),
  });

  const loadingRowModel = loadingTableInstance.getRowModel();
  const loadingRows = props.rowLimit
    ? loadingRowModel.rows.slice(0, props.rowLimit)
    : loadingRowModel.rows;

  return (
    <StyledTable compact={props.compact} role="table" width="100%">
      <thead
        style={{
          position: props.showPagination ? "inherit" : "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        {loadingTableInstance.getHeaderGroups().map((headerGroup) => (
          <HeaderRow key={headerGroup.id} compact={props.compact} role="row">
            {headerGroup.headers.map((header) => {
              const column = header.column;

              const align = column.columnDef.meta?.align;
              const size = column.getSize();

              return (
                <HeaderCell
                  align={align}
                  colSpan={header.colSpan}
                  isPinned={false}
                  key={header.id}
                  role="columnheader"
                  size={size}
                  sortable={false}
                >
                  <Flex>
                    {flexRender(column.columnDef.header, header.getContext())}
                  </Flex>
                </HeaderCell>
              );
            })}
          </HeaderRow>
        ))}
      </thead>

      {/* BODY */}
      <tbody role="rowgroup">
        {loadingRows.map((row) => (
          <Row
            key={row.id}
            alternate={props.alternateRows}
            clickable={false}
            compact={props.compact}
            footer={props.footer}
            height={props.rowHeight}
            role="row"
          >
            {row.getVisibleCells().map((cell) => (
              <Cell
                key={cell.id}
                align={cell.column.columnDef.meta?.align}
                footer={props.footer}
                isSelected={false}
                role="cell"
                size={cell.column.getSize()}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Cell>
            ))}
          </Row>
        ))}
      </tbody>

      {/* FOOTER */}
      {props.footer && (
        <tfoot>
          {loadingTableInstance.getFooterGroups().map((group) => {
            return (
              <FooterRow
                key={group.id}
                compact={props.compact}
                footerDelta={props.footerDelta}
              >
                {group.headers.map((footer, i: number) => {
                  const align = footer.column.columnDef.meta?.align;
                  const size = footer.column.getSize();

                  return (
                    <FooterCell
                      key={footer.id}
                      colSpan={footer.colSpan}
                      size={size}
                      align={align}
                      isSelected={false}
                    >
                      <Box width="100%">
                        {i === 0
                          ? copyText.dataTableTotalsHeader
                          : flexRender(
                              footer.column.columnDef.footer,
                              footer.getContext()
                            )}
                        {props.footerDelta && i === 0 && (
                          <Text color={theme.text_color} truncate>
                            {copyText.dataTableTotalsDeltasHeader}
                          </Text>
                        )}
                      </Box>
                    </FooterCell>
                  );
                })}
              </FooterRow>
            );
          })}
        </tfoot>
      )}
    </StyledTable>
  );
}

export function ActionMenuButton(props: { children?: ReactNode }) {
  const {
    children = createElement(Icon, { icon: faEllipsisV }),
    ...restProps
  } = props;

  return <StyledActionMenuButton children={children} {...restProps} />;
}
