import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useMemo } from "react";
import paths from "../../../constants/paths";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import Modal from "../../../ui-lib/components/Modal";
import copyText from "../copyText";

type Tenant = {
  id: string;
  name: string;
};

const columnHelper = createColumnHelper<Tenant>();

interface Props {
  isLoading: boolean;
  tenants: Tenant[];
  onInteraction: (interaction: TenantListModal.Interaction) => void;
}

export function TenantListModal(props: Props) {
  const navigate = useNavigateWithSearchParams();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 170,
      }),
      columnHelper.accessor("id", {
        header: copyText.tableHeaderID,
        size: 170,
      }),
    ],
    [props.isLoading]
  );

  return (
    <Modal isOpen={true} width={1000}>
      <Modal.Header>
        <Text appearance="h4">{copyText.modalTitleMspPartnerTenants}</Text>
        <Button
          iconStart={<Icon icon={faArrowLeft} />}
          onClick={() => navigate(paths._home)}
        >
          {copyText.goBackButtonLabel}
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Box height={500}>
          <Table
            clickableRows
            columns={columns}
            compact
            data={props.tenants}
            initialState={{ sorting: [{ id: "name", desc: false }] }}
            isLoading={props.isLoading}
            showPagination
            sortable
            onClick={(row) =>
              props.onInteraction({
                type: TenantListModal.INTERACTION_ROW_CLICKED,
                tenantID: row.id,
              })
            }
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

TenantListModal.INTERACTION_ROW_CLICKED =
  "TenantListModal.INTERACTION_ROW_CLICKED" as const;

type InteractionRowClicked = {
  type: typeof TenantListModal.INTERACTION_ROW_CLICKED;
  tenantID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TenantListModal {
  export type Interaction = InteractionRowClicked;
}

export default TenantListModal;
