import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import useRefFn from "@ternary/api-lib/ui-lib/hooks/useRefFn";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import { floor } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AzureDatabaseInstanceGroupEntity,
  AzureDatabaseInstanceGroupFilters,
  azureDatabaseSQLDimensions,
} from "../types";

type Props = {
  instanceGroups: AzureDatabaseInstanceGroupEntity[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: AzureDatabaseInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureDatabaseInstanceGroupEntity>();

function AzureDatabaseInstanceGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip content={copyText.azureComputeTableViewInstances}>
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        size: 50,
      }),
      ...(
        [
          [azureDatabaseSQLDimensions.projectId, 120],
          [azureDatabaseSQLDimensions.location, 120],
          [azureDatabaseSQLDimensions.databaseEngine, 160],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `azureDatabaseTableInstanceGroupHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text
              color={theme.primary_color_text}
              truncate
              cursor="pointer"
              onClick={() =>
                onInteraction({
                  type: AzureDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: dimensionKey,
                  filterValue: getValue(),
                })
              }
            >
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor("resourceCount", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceGroupHeader_resourceCount,
        meta: { align: "center" },
      }),
      columnHelper.accessor("usageAmount", {
        cell: ({ getValue }) => floor(getValue(), 3),
        header: copyText.azureDatabaseTableInstanceGroupHeader_usageAmount,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureDatabaseTableInstanceGroupHeader_cost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("connections", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceGroupHeader_connections,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cores", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceGroupHeader_cores,
        meta: { align: "center" },
      }),
      columnHelper.accessor("coreUsage", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceGroupHeader_coreUsage,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cpuPercentAvg", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceGroupHeader_cpuPercentAvg,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cpuPercentMax", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceGroupHeader_cpuPercentMax,
        meta: { align: "center" },
      }),
      columnHelper.accessor("memoryGB", {
        cell: ({ getValue }) => `${floor(getValue(), 3)} GB`,
        header: copyText.azureDatabaseTableInstanceGroupHeader_memoryGB,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("memoryBytesUsage", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.azureDatabaseTableInstanceGroupHeader_memoryBytesUsage,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("memoryPercentAvg", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceGroupHeader_memoryPercentAvg,
        meta: { align: "center" },
      }),
      columnHelper.accessor("memoryPercentMax", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceGroupHeader_memoryPercentMax,
        meta: { align: "center" },
      }),
      columnHelper.accessor("storageCapacityGB", {
        cell: ({ getValue }) => floor(getValue(), 3),
        header:
          copyText.azureDatabaseTableInstanceGroupHeader_storageCapacityGB,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("storagePercent", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceGroupHeader_storagePercent,
        meta: { align: "center" },
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.instanceGroups}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingInstanceGroups}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}

function getSelectedGroupJSON(datum: AzureDatabaseInstanceGroupEntity) {
  try {
    return JSON.stringify({
      databaseEngine: datum.databaseEngine,
      location: datum.location,
      projectId: datum.projectId,
    });
  } catch (error) {
    return "";
  }
}

AzureDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AzureDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AzureDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AzureDatabaseInstanceGroupFilters;
  filterValue: AzureDatabaseInstanceGroupFilters[keyof AzureDatabaseInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureDatabaseInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AzureDatabaseInstanceGroupTable;
