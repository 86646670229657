import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import useRefFn from "@ternary/api-lib/ui-lib/hooks/useRefFn";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { floor } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AzureVMComputeInstanceGroup,
  azureVMComputeDimensions,
} from "../types";
import { AzureComputeCategoryOption } from "./AzureVMComputeContainer";

type Props = {
  instanceGroups: AzureVMComputeInstanceGroup[];
  isLoadingInstanceGroups: boolean;
  selectedCategory: AzureComputeCategoryOption;
  onInteraction: (
    interaction: AzureVMComputeInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureVMComputeInstanceGroup>();

function AzureVMComputeInstanceGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const blockStorageColumns = [
    [azureVMComputeDimensions.blockStorageClass, 120],
    [azureVMComputeDimensions.blockStorageType, 120],
    [azureVMComputeDimensions.location, 120],
    [azureVMComputeDimensions.projectId, 160],
  ] as const;

  const instanceColumns = [
    [azureVMComputeDimensions.family, 120],
    [azureVMComputeDimensions.instanceType, 120],
    [azureVMComputeDimensions.operatingSystem, 160],
    [azureVMComputeDimensions.location, 120],
    [azureVMComputeDimensions.projectId, 160],
  ] as const;

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip content={copyText.azureComputeTableViewInstances}>
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        size: 50,
      }),
      ...(props.selectedCategory === AzureComputeCategoryOption.BLOCK
        ? blockStorageColumns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `azureComputeTableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text
                  color={theme.primary_color_text}
                  truncate
                  cursor="pointer"
                  onClick={() =>
                    onInteraction({
                      type: AzureVMComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                      filterKey: dimensionKey,
                      filterValue: getValue(),
                    })
                  }
                >
                  <Tooltip
                    content={getValue() || copyText.azureComputeTableNull}
                  >
                    {getValue() || copyText.azureComputeTableNull}
                  </Tooltip>
                </Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      ...(props.selectedCategory === AzureComputeCategoryOption.INSTANCE
        ? instanceColumns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `azureComputeTableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text
                  color={theme.primary_color_text}
                  truncate
                  cursor="pointer"
                  onClick={() =>
                    onInteraction({
                      type: AzureVMComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                      filterKey: dimensionKey,
                      filterValue: getValue(),
                    })
                  }
                >
                  <Tooltip
                    content={getValue() || copyText.azureComputeTableNull}
                  >
                    {getValue() || copyText.azureComputeTableNull}
                  </Tooltip>
                </Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      columnHelper.accessor("instanceCount", {
        header: copyText.azureComputeTableHeader_instanceCount,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("usageAmount", {
        cell: ({ getValue }) => floor(getValue(), 3),
        header: copyText.azureComputeTableHeader_usageAmount,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureComputeTableHeader_cost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("productVCPU", {
        header: copyText.azureComputeTableHeader_productVCPU,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      ...(
        ["cpuUtilizationMin", "cpuUtilizationAvg", "cpuUtilizationMax"] as const
      ).map((accessor) =>
        columnHelper.accessor(accessor, {
          cell: ({ getValue }) => formatPercentage(getValue() / 100),
          header: copyText[`azureComputeTableHeader_${accessor}`],
          meta: { align: "center" },
          sortDescFirst: true,
        })
      ),
      columnHelper.accessor("diskReadBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_diskReadBytes,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("diskWriteBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_diskWriteBytes,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_productMemoryBytes,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      ...(
        [
          "ramAvailableBytesMin",
          "ramAvailableBytesAvg",
          "ramAvailableBytesMax",
        ] as const
      ).map((accessor) =>
        columnHelper.accessor(accessor, {
          cell: ({ getValue }) => prettyBytes(getValue()),
          header: copyText[`azureComputeTableHeader_${accessor}`],
          meta: { align: "center" },
          sortDescFirst: true,
        })
      ),
      columnHelper.accessor("receivedBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_receivedBytes,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("sentBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_sentBytes,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.instanceGroups}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: AzureVMComputeInstanceGroup) {
  try {
    return JSON.stringify({
      blockStorageClass: datum.blockStorageClass,
      blockStorageType: datum.blockStorageType,
      family: datum.family,
      instanceType: datum.instanceType,
      location: datum.location,
      operatingSystem: datum.operatingSystem,
      projectId: datum.projectId,
    });
  } catch (error) {
    return "";
  }
}

AzureVMComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AzureVMComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AzureVMComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AzureVMComputeInstanceGroup;
  filterValue: AzureVMComputeInstanceGroup[keyof AzureVMComputeInstanceGroup];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureVMComputeInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AzureVMComputeInstanceGroupTable;
