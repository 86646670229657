import { useAuthHandler } from "@/api/AuthHandlerProvider";
import copyText from "@/constants/copyText";
import externalLinks from "@/constants/externalLinks";
import paths from "@/constants/paths";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import Avatar from "@/ui-lib/components/Avatar";
import Dropdown from "@/ui-lib/components/Dropdown";
import getMergeState from "@/utils/getMergeState";
import { updateTenantID } from "@/utils/window";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faChevronUp,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import AnnounceKit from "announcekit-react";
import React, { useState } from "react";
import { AuthenticatedUserEntity } from "../api/core/types";
import CaseFormContainer from "../features/case-management/components/CaseFormContainer";
import useGatekeeper from "../hooks/useGatekeeper";
import { getFullName } from "../utils/UserUtils";
import GlobalDatePickerManagementContainer from "./GlobalDatePickerManagementContainer";
import GlobalFilterManagementContainer from "./GlobalFilterManagementContainer";
import TenantModal from "./TenantModal";

const TENANT_NAME_WIDTH = 200;
const SMALL_SCREEN_SIZE = 1600;

interface Props {
  authenticatedUser: AuthenticatedUserEntity;
  enableCaseManagement: boolean;
  enableGlobalFiltering: boolean;
  isSharedView: boolean;
}

interface State {
  showCopiedMessage: boolean;
  showDropdown: boolean;
  showTenantModal: boolean;
}

const initialState: State = {
  showCopiedMessage: false,
  showDropdown: false,
  showTenantModal: false,
};

export default function TopNav(props: Props): JSX.Element {
  const authHandler = useAuthHandler();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleShare(): () => void {
    navigator.clipboard.writeText(window.location.href);
    mergeState({ showCopiedMessage: true });
    const timeId = setTimeout(() => {
      mergeState({ showCopiedMessage: false });
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }

  //
  // Render
  //

  const dropdownItems = [
    {
      label: copyText.topNavDropdownItemSwitchTenant,
      onClick: () => mergeState({ showDropdown: false, showTenantModal: true }),
    },
    {
      label: copyText.topNavDropdownItemCases,
      onClick: () => navigate(paths._cases),
    },
    {
      label: copyText.topNavDropdownItemSettings,
      onClick: () => navigate(paths._settings),
    },
  ];

  if (gatekeeper.canAccessInternalAdmin) {
    dropdownItems.push({
      label: copyText.topNavDropdownItemInternalAdmin,
      onClick: () => navigate(paths._internalAdmin),
    });
  }

  dropdownItems.push({
    label: copyText.topNavDropdownLogout,
    onClick: () => authHandler.logout(),
  });

  // Removes settings page when in shared view.
  if (props.isSharedView) {
    dropdownItems.splice(2, 1);
  }

  const tenants = props.authenticatedUser.tenants.sort((a, b) =>
    a.name < b.name ? -1 : 1
  );

  if (tenants.length === 1) {
    dropdownItems.shift();
  }

  const resizeTenantName =
    window.innerWidth < SMALL_SCREEN_SIZE &&
    props.enableCaseManagement &&
    props.enableGlobalFiltering;

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.top_nav_background_color}
      flexWrap="nowrap"
      height={64}
      justifyContent="space-between"
      padding={`${theme.space_md} ${theme.space_xl}`}
    >
      <TenantModal
        currentTenantID={props.authenticatedUser.tenant.id}
        isOpen={state.showTenantModal}
        tenants={tenants}
        onClose={() => mergeState({ showTenantModal: false })}
        onSelect={updateTenantID}
      />
      <Flex alignItems="center">
        <Box maxWidth={resizeTenantName ? TENANT_NAME_WIDTH : undefined}>
          <Text appearance="h4" marginRight={theme.space_md} truncate>
            {props.authenticatedUser.tenant.name}
          </Text>
        </Box>
        <GlobalFilterManagementContainer
          authenticatedUser={props.authenticatedUser}
          isEnabled={props.enableGlobalFiltering}
        />
        <CaseFormContainer
          isEnabled={props.enableCaseManagement && !props.isSharedView}
        />
      </Flex>
      <Flex alignItems="center">
        <GlobalDatePickerManagementContainer />
        <Tooltip
          content={copyText.shareButtonTooltip}
          delayHide={3000}
          hide={!state.showCopiedMessage}
        >
          <Button
            iconStart={
              <Icon color={theme.text_color_inverse} icon={faUserFriends} />
            }
            marginRight={theme.space_lg}
            primary
            size="small"
            onClick={handleShare}
          >
            {copyText.shareButton}
          </Button>
        </Tooltip>
        <Box marginRight={theme.space_lg} position="relative">
          <AnnounceKit
            widget={externalLinks.announceKitWidgetID}
            widgetStyle={{ position: "absolute", right: "-5px", top: "-8px" }}
          >
            <Button size="small">{copyText.announceKitButtonLabel}</Button>
          </AnnounceKit>
        </Box>
        <Dropdown
          isOpen={state.showDropdown}
          options={dropdownItems}
          placement="bottom-end"
          onClose={() => mergeState({ showDropdown: false })}
          onOpen={() => mergeState({ showDropdown: true })}
        >
          <Flex alignItems="center" cursor="pointer">
            <Avatar src={props.authenticatedUser.avatarSrc} />
            <Text marginHorizontal={theme.space_sm} truncate>
              {getFullName(props.authenticatedUser)}
            </Text>
            <Icon
              icon={state.showDropdown ? faChevronUp : faChevronDown}
              size="xs"
              color={theme.text_color_secondary}
            />
          </Flex>
        </Dropdown>
      </Flex>
    </Flex>
  );
}
