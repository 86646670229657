import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useTheme } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { RawData, RawValue } from "@ternary/api-lib/analytics/types";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import getMergeState, {
  COMPARISON_KEY,
  FORECASTED_KEY,
} from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { endOfMonth, format, getDate, startOfDay } from "date-fns";
import { sortBy } from "lodash";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { billingMeasures } from "../../../api/analytics/billingSchema";
import useAvailableDimensionsByDataSource from "../../../hooks/useAvailableDimensionsByDataSource";
import { DateHelper } from "../../../lib/dates";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { LoadingEllipsis } from "../../../ui-lib/components/SelectCheckbox";
import SelectDropdown from "../../../ui-lib/components/SelectDropdown";
import IconExport from "../../../ui-lib/icons/IconExport";
import copyText from "../copyText";
import useGetBillingVendors from "../hooks/useGetBillingVendors";
import { useGetCostTableData } from "../hooks/useGetCostTableData";
import { useGetForecastingTimeSeriesData } from "../hooks/useGetForecastingTimeSeriesData";
import { useGetForecastingTotalsData } from "../hooks/useGetForecastingTotalsData";
import ForecastingMeters from "./ForecastingMeters";
import ForecastingTable from "./ForecastingTable";
const CHART_SECTION_HEIGHT_CLOSED = 68;
const CHART_SECTION_HEIGHT_OPEN = 450;

const defaultDimensions = ["category", "serviceDescription"];

const LookBack = {
  NINETY_DAYS: "NINETY_DAYS",
  ONE_HUNDRED_EIGHTY_DAYS: "ONE_HUNDRED_EIGHTY_DAYS",
  ONE_HUNDRED_FIFTY_DAYS: "ONE_HUNDRED_FIFTY_DAYS",
  ONE_HUNDRED_TWENTY_DAYS: "ONE_HUNDRED_TWENTY_DAYS",
  SIXTY_DAYS: "SIXTY_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
} as const;

type LookBack = (typeof LookBack)[keyof typeof LookBack];

const ChartOption = {
  CURRENT_MONTH: "CURRENT_MONTH",
  LOOKBACK: "LOOKBACK",
} as const;

type ChartOption = (typeof ChartOption)[keyof typeof ChartOption];

type CsvData = {
  headers: { key: string; label: string }[];
  rows: Record<string, RawValue>[];
};

type State = {
  dimensions: string[];
  lookBackRange: LookBack;
  measure: string;
  selectedChartOption: ChartOption;
  showChart: boolean;
  vendor: string;
};

const initialState: State = {
  dimensions: defaultDimensions,
  lookBackRange: LookBack.THIRTY_DAYS,
  measure: billingMeasures.netCost,
  selectedChartOption: ChartOption.CURRENT_MONTH,
  showChart: true,
  vendor: "ALL",
};

export function ForecastingViewContainer() {
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  const lookBackDate = getForecastLookbackDateRange(state.lookBackRange);

  //
  // Queries
  //

  const { data: costTableData, isLoading: isLoadingCostTableData } =
    useGetCostTableData({
      dimensions: state.dimensions,
      lookBackDate: lookBackDate,
      measure: state.measure,
      vendor: state.vendor !== "ALL" ? state.vendor : undefined,
    });

  const {
    data: forecastingTimeSeriesData,
    isLoading: isLoadingForecastingTimeSeriesData,
  } = useGetForecastingTimeSeriesData({
    dimensions: state.dimensions,
    lookBackDate,
    measure: state.measure,
    vendor: state.vendor !== "ALL" ? state.vendor : undefined,
  });

  const forecastingTotals = useGetForecastingTotalsData({
    lookBackDate,
    measure: state.measure,
    vendor: state.vendor !== "ALL" ? state.vendor : undefined,
  });

  const { data: vendorData = [], isLoading: isLoadingVendors } =
    useGetBillingVendors();

  const { data: currentMtdSpend = 0 } = forecastingTotals[0];

  const { data: lastMtdSpend = 0 } = forecastingTotals[1];

  const { data: lastMonthTotalSpend = 0 } = forecastingTotals[2];

  const { data: last3MonthAverage = 0 } = forecastingTotals[3];

  const { data: currentMtdForecastedSpend = 0 } = forecastingTotals[4];

  const isLoadingTotals = forecastingTotals.some((result) => result.isLoading);

  //
  // Render
  //

  const csvData = useMemo(
    () => getCsvData(state.dimensions, state.measure, costTableData ?? []),
    [costTableData]
  );

  const filteredForecastTimeSeries = useMemo(() => {
    if (state.selectedChartOption === ChartOption.LOOKBACK)
      return forecastingTimeSeriesData;

    const dateHelper = new DateHelper();

    const lastDayOfLastMonth = dateHelper.lastDayLastMonth();
    const lastDayOfThisMonth = dateHelper.lastDayThisMonth();

    return (forecastingTimeSeriesData ?? []).filter((datum) => {
      if (typeof datum["timestamp"] === "string") {
        const timestampDate = new Date(datum["timestamp"]);
        const check =
          timestampDate >= lastDayOfLastMonth &&
          timestampDate <= lastDayOfThisMonth;
        return check;
      }
    });
  }, [forecastingTimeSeriesData, state.selectedChartOption]);

  const _vendorOptions = sortBy(
    vendorData.map((datum) => ({
      label: datum.vendor,
      value: datum.vendor,
      onClick: (value) => {
        mergeState({ vendor: value });

        activityTracker.captureAction(actions.FORECASTING_VENDOR_SELECT, {
          vendor: value,
        });
      },
    })),
    "label"
  );

  const vendorOptions = [
    {
      label: "All",
      value: "ALL",
      onClick: (value) => {
        mergeState({ vendor: value });

        activityTracker.captureAction(actions.FORECASTING_VENDOR_SELECT, {
          vendor: value,
        });
      },
    },
    ..._vendorOptions,
  ];

  const selectedVendorOption = vendorOptions.find(
    (option) => option.value === state.vendor
  );

  const billingDimensions = useAvailableDimensionsByDataSource(
    DataSource.BILLING
  );

  const dimensionOptions = billingDimensions.map((dimension) => {
    const dimensionLabel =
      dimension.length > 50
        ? `${dimension.slice(0, 50)}...${dimension.slice(dimension.length - 10, -1)}`
        : dimension;

    return {
      label: dimensionLabel,
      value: dimension,
    };
  });

  const selectedDimensionOptions = dimensionOptions.filter((option) =>
    state.dimensions.some((dimension) => dimension === option.value)
  );

  const measureOptions = [
    billingMeasures.amortizedCost,
    billingMeasures.cost,
    billingMeasures.customNetCost,
    billingMeasures.indirectCost,
    billingMeasures.netAmortizedCost,
    billingMeasures.netCost,
  ].map((measure) => ({
    label: copyText[`measureLabel_${measure}`],
    value: measure,
    onClick: (value) => {
      mergeState({ measure: value });

      activityTracker.captureAction(actions.FORECASTING_MEASURE_SELECT, {
        measure: value,
      });
    },
  }));

  const selectedMeasureOption = measureOptions.find(
    (option) => option.value === state.measure
  );

  const combinedCostMeasures = [
    { name: state.measure, unit: UnitType.CURRENCY },
    { name: `${state.measure}${FORECASTED_KEY}`, unit: UnitType.CURRENCY },
  ];

  const lookBackOptions = [
    LookBack.THIRTY_DAYS,
    LookBack.SIXTY_DAYS,
    LookBack.NINETY_DAYS,
    LookBack.ONE_HUNDRED_TWENTY_DAYS,
    LookBack.ONE_HUNDRED_FIFTY_DAYS,
    LookBack.ONE_HUNDRED_EIGHTY_DAYS,
  ].map((option) => {
    const value = LookBack[option];
    return {
      label: copyText[`lookBackRangeLabel_${option}`],
      value,
      onClick: () => {
        mergeState({ lookBackRange: value });
        activityTracker.captureAction(
          actions.FORECASTING_LOOKBACK_RANGE_SELECT,
          {
            range: value,
          }
        );
      },
    };
  });

  const selectedLookBackOption = lookBackOptions.find(
    (option) => option.value === state.lookBackRange
  );

  const chartOptions = [
    {
      label: copyText.forecastingChartOptionCurrentMonth,
      value: ChartOption.CURRENT_MONTH,
      onClick: (value) => {
        mergeState({ selectedChartOption: value });

        activityTracker.captureAction(actions.FORECASTING_CHART_OPTION_SELECT, {
          chartOption: value,
        });
      },
    },
    {
      label: copyText.forecastingChartOptionLookbackRange,
      value: ChartOption.LOOKBACK,
      onClick: (value) => {
        mergeState({ selectedChartOption: value });

        activityTracker.captureAction(actions.FORECASTING_CHART_OPTION_SELECT, {
          chartOption: value,
        });
      },
    },
  ];

  const selectedChartOption = chartOptions.find(
    (option) => option.value === state.selectedChartOption
  );

  return (
    <Box>
      <Text appearance="h1" marginBottom={theme.space_md}>
        {copyText.forecastingPageTitle}
      </Text>
      <Flex
        alignItems=" center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        justifyContent="space-between"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Flex>
          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelVendor}</Text>
            <Dropdown
              options={vendorOptions}
              selectedOption={selectedVendorOption}
            >
              <Flex alignItems="center" justifyContent="center" height={24}>
                {isLoadingVendors ? (
                  <LoadingEllipsis margin={theme.space_xxs} />
                ) : (
                  <Flex alignItems="center">
                    <Text bold marginRight={theme.space_xs}>
                      {selectedVendorOption?.label}
                    </Text>
                    <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
                  </Flex>
                )}
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1} />
          </Box>
          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelGroupings}</Text>
            <SelectDropdown
              closeOnSubmit
              hideSelectedOptions={false}
              isMulti
              options={dimensionOptions}
              selectedValues={state.dimensions}
              onChange={(options) => {
                options.length < 3 && mergeState({ dimensions: options });

                activityTracker.captureAction(
                  actions.FORECASTING_GROUPINGS_SELECT,
                  { dimensions: options }
                );
              }}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs} truncate={150}>
                  {selectedDimensionOptions.length > 0
                    ? selectedDimensionOptions
                        .map((dimension) => dimension.label)
                        .join(", ")
                    : copyText.groupingsDropdownPlaceholder}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </SelectDropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>
          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelMeasure}</Text>
            <Dropdown
              options={measureOptions}
              selectedOption={selectedMeasureOption}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs}>
                  {selectedMeasureOption?.label}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>

          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelLookbackRange}</Text>
            <Dropdown
              options={lookBackOptions}
              selectedOption={selectedLookBackOption}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs}>
                  {selectedLookBackOption?.label ??
                    copyText.lookbackRangeLabel_default}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>
        </Flex>

        <CSVLink
          data={csvData.rows}
          filename={`forecasting-${format(new Date(), "MM-dd-yyyy")}`}
          headers={csvData.headers}
        >
          <Button
            disabled={isLoadingCostTableData}
            iconStart={<IconExport />}
            secondary
            size="small"
            onClick={() => {
              activityTracker.captureAction(actions.FORECASTING_EXPORT);
            }}
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Flex>
      <ForecastingMeters
        isLoading={isLoadingTotals}
        currentMtdForecastedSpend={currentMtdForecastedSpend}
        currentMtdSpend={currentMtdSpend}
        last3MonthAverage={last3MonthAverage}
        lastMonthTotalSpend={lastMonthTotalSpend}
        lastMtdSpend={lastMtdSpend}
      />
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={
          state.showChart
            ? CHART_SECTION_HEIGHT_OPEN
            : CHART_SECTION_HEIGHT_CLOSED
        }
        marginBottom={theme.space_lg}
        padding={theme.space_md}
        transition="height 400ms"
      >
        <Flex alignItems="center">
          <Flex
            justifyContent="space-between"
            marginBottom={theme.space_sm}
            width={"100%"}
          >
            <Flex>
              <Button
                iconEnd={
                  <Icon icon={state.showChart ? faChevronDown : faChevronUp} />
                }
                marginRight={theme.space_sm}
                size="small"
                onClick={() => {
                  setState((currentState) => ({
                    ...currentState,
                    showChart: !currentState.showChart,
                  }));

                  state.showChart
                    ? activityTracker.captureAction(
                        actions.FORECASTING_CHART_COLLAPSE
                      )
                    : activityTracker.captureAction(
                        actions.FORECASTING_CHART_EXPAND
                      );
                }}
              />
              <Text appearance="h3">{copyText.chartTitleTotalSpend}</Text>
            </Flex>

            <Dropdown
              options={chartOptions}
              selectedOption={selectedChartOption}
            >
              <Button secondary size="small">
                {selectedChartOption?.label}
              </Button>
            </Dropdown>
          </Flex>
        </Flex>
        <Box
          height="90%"
          opacity={state.showChart ? 1 : 0}
          transition="opacity 300ms"
        >
          <StackedBarChart
            data={filteredForecastTimeSeries ?? []}
            dimensions={state.dimensions.map((dimension) => ({
              name: dimension,
            }))}
            isLoading={isLoadingForecastingTimeSeriesData}
            isForecastingMode
            measures={combinedCostMeasures}
            showLegend
            showExperimentalTooltip
            timeSeriesGranularity={TimeGranularity.DAY}
          />
        </Box>
      </Box>
      <ForecastingTable
        data={costTableData ?? []}
        dimensions={state.dimensions}
        measure={state.measure}
        isLoading={isLoadingCostTableData}
      />
    </Box>
  );
}

function getForecastLookbackDateRange(range: LookBack): Date {
  const lookBackDays = getLookbackRangeInDays(range);
  const pastDate = new Date();

  pastDate.setDate(pastDate.getDate() - lookBackDays);

  if (isThirtyOneDayMonth(pastDate)) {
    pastDate.setDate(pastDate.getDate() - 1);
  }

  return startOfDay(pastDate);
}

function isThirtyOneDayMonth(date: Date): boolean {
  const lastDay = getDate(endOfMonth(date));
  const currentDay = getDate(date);

  return currentDay === 31 && lastDay === 31;
}

function getLookbackRangeInDays(range: LookBack): number {
  let days = 30;
  switch (range) {
    case LookBack.THIRTY_DAYS: {
      days = 30;
      break;
    }
    case LookBack.SIXTY_DAYS: {
      days = 60;
      break;
    }
    case LookBack.NINETY_DAYS: {
      days = 90;
      break;
    }
    case LookBack.ONE_HUNDRED_TWENTY_DAYS: {
      days = 120;
      break;
    }
    case LookBack.ONE_HUNDRED_FIFTY_DAYS: {
      days = 150;
      break;
    }
    case LookBack.ONE_HUNDRED_EIGHTY_DAYS: {
      days = 180;
      break;
    }
  }
  return days;
}

//
// CSV
//

function getCsvData(
  dimensions: string[],
  measure: string,
  data: RawData[]
): CsvData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const csvAccessors = [
    ...dimensions,
    measure,
    "estimatedTotalSpend",
    "estimatedDeltaSpend",
  ];

  const rows = data.map((datum) => {
    const totalForecastedSpend =
      Number(datum[measure] ?? 0) +
      Number(datum[`${measure}${FORECASTED_KEY}`] ?? 0);

    return {
      ...dimensions.reduce((accum, dimension) => {
        return { ...accum, [dimension]: datum[dimension] };
      }, {}),
      [measure]: datum[measure],
      estimatedTotalSpend: totalForecastedSpend,
      estimatedDeltaSpend:
        totalForecastedSpend -
        Number(datum[`${measure}${COMPARISON_KEY}`] ?? 0),
    };
  });

  const headers = csvAccessors.map((csvAccessor) => ({
    key: csvAccessor,
    label: csvAccessor,
  }));

  return { headers, rows };
}

export default ForecastingViewContainer;
