import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/table-core";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import Box from "@ternary/web-ui-lib/components/Box";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { toZonedTime } from "date-fns-tz";
import React, { useMemo } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";
import { RuleSet } from "../types";

interface Props {
  isLoading: boolean;
  ruleSets: (RuleSet & { createdByEmail: string })[];
  onInteraction: (interaction: RuleSetTable.Interaction) => void;
}

type TableData = {
  id: string;
  createdBy: string;
  endTime: string | null;
  name: string;
  rules: number;
  startTime: string;
  timeLastModified: string;
};

const columnHelper = createColumnHelper<TableData>();

export function RuleSetTable(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderRuleSetName,
        size: 250,
      }),
      columnHelper.accessor("rules", {
        header: copyText.tableHeaderRules,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.tableHeaderCreatedBy,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: (context) => (
          <>{formatDate(new Date(context.getValue()), "MM/dd/yyyy hh:mm a")}</>
        ),
        header: copyText.tableHeaderLastModified,
        size: 160,
      }),
      columnHelper.display({
        cell: ({ row }) => (
          <>
            {`${formatDate(toZonedTime(new Date(row.original.startTime), "UTC"), "MM/dd/yyyy")} - ${
              row.original.endTime
                ? formatDate(
                    toZonedTime(new Date(row.original.endTime), "UTC"),
                    "MM/dd/yyyy"
                  )
                : copyText.nowDateLabel
            }`}
          </>
        ),
        header: copyText.tableHeaderDateRange,
        size: 160,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: function renderButton({ row }) {
          const dropdownOptions = [
            {
              disabled:
                !gatekeeper.canAccessMspAdmin &&
                !gatekeeper.canUpdateMspBillingRuleSet,
              label: copyText.actionEdit,
              onClick: () =>
                props.onInteraction({
                  type: RuleSetTable.INTERACTION_EDIT_CLICKED,
                  ruleSetID: row.original.id,
                }),
            },
            {
              disabled:
                !gatekeeper.canAccessMspAdmin &&
                !gatekeeper.canDeleteMspBillingRuleSet,
              label: copyText.actionDelete,
              onClick: () =>
                props.onInteraction({
                  type: RuleSetTable.INTERACTION_DELETE_CLICKED,
                  ruleSetID: row.original.id,
                }),
            },
          ];

          return (
            <Dropdown options={dropdownOptions} placement="bottom">
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                primary
                size="tiny"
              />
            </Dropdown>
          );
        },
        size: 30,
      }),
    ],
    [props.ruleSets]
  );

  const data = useMemo(() => {
    return props.ruleSets.map((ruleSet) => ({
      id: ruleSet.id,
      createdBy: ruleSet.createdByEmail ?? ruleSet.createdByID,
      endTime: ruleSet.endTime,
      name: ruleSet.name,
      rules: ruleSet.rules ? ruleSet.rules.length : 0,
      startTime: ruleSet.startTime,
      timeLastModified: ruleSet.updatedAt ?? ruleSet.createdAt,
    }));
  }, [props.ruleSets]);

  return (
    <>
      <Box height={500}>
        <Table
          columns={columns}
          data={data}
          initialState={{
            pagination: { pageSize: 8 },
            sorting: [{ id: "name", desc: false }],
          }}
          isLoading={props.isLoading}
          showPagination
          sortable
        />
      </Box>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RuleSetTable {
  export const INTERACTION_CREATE_CLICKED = `RuleSetTable.INTERACTION_CREATE_CLICKED`;
  export const INTERACTION_DELETE_CLICKED = `RuleSetTable.INTERACTION_DELETE_CLICKED`;
  export const INTERACTION_EDIT_CLICKED = `RuleSetTable.INTERACTION_EDIT_CLICKED`;

  interface InteractionCreateClicked {
    type: typeof RuleSetTable.INTERACTION_CREATE_CLICKED;
  }

  interface InteractionDeleteClicked {
    type: typeof RuleSetTable.INTERACTION_DELETE_CLICKED;
    ruleSetID: string;
  }

  interface InteractionEditClicked {
    type: typeof RuleSetTable.INTERACTION_EDIT_CLICKED;
    ruleSetID: string;
  }

  export type Interaction =
    | InteractionCreateClicked
    | InteractionDeleteClicked
    | InteractionEditClicked;
}

export default RuleSetTable;
