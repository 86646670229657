/* prettier-ignore */
export default {
  actionArchive: "Archive",
  actionBack: "Back",
  actionCancel: "Cancel",
  actionClose: "Close",
  actionCopy: "Copy",
  actionDelete: "Delete",
  actionDismissAll: "Dismiss All",
  actionEdit: "Edit",
  actionExport:"Export",
  actionExportAll:"Export All",
  actionMenuItemManageDashboard: "Manage Dashboard",
  actionMenuItemResetToDefaultDashboard: "Reset to Default Dashboard",
  actionMenuItemSwitchToHomeClassic: "Switch to Home Classic",
  actionMenuItemSwitchToHomeEnahnced: "Switch to Home Enhanced",
  actionSave: "Save",
  actionSaveAs: "Save As",
  actionSubmit: "Submit",
  actionUnarchive: "Unarchive",
  addDatasetButtonLabel: "Add Dataset",
  addFilterButtonLabel: "Add Filter",
  additionalFiltersHeader: "Additional Filters",
  adminFiltersHeader: "Org Level Scoped Views",
  adminPageTitle: "Admin",
  anomalyDetectionPageTitle: "Anomaly Detection",
  applyFiltersButtonLabel: "Apply",
  awsRedshiftInsightsPageTitle: "AWS Redshift Insights",
  awsRedshiftPageTitle: "Amazon Redshift",
  awsRedshiftTitlePage: "Amazon Redshift",
  backButtonLabel: "Back",
  bigQueryPageTitle: "BigQuery",
  budgetsCurrentDailyGraphTooltip: "See your current month spend and the forecast for the remainder of the month.",
  budgetsHistoricalDailyGraphTooltip: "See your daily spend, overlayed over your previous months' spend on the same days to reveal daily trends.",
  budgetsPageTitle: "Budget Management",
  cancelButtonLabel: "Cancel",
  cannotShowRecommendationsMessage: "Cannot display recommendation information with filters applied.",
  caseManagementPageTitle: "Case Management",
  cloudSQLInsightsPageTitle: "Cloud SQL Insights",
  comingSoonTooltipCaption: "Coming Soon...",
  committedDiscountPageTitle: "Commitment Discounts",
  committedUsePageTitle: "Commitment Discounts",
  companyName: "Ternary Inc.",
  computeInsightsPageTitle: "Compute Insights",
  costComparePageTitle: "Cost Compare",
  costReportsPageTitle: "Cost Reports",
  customLabelsPageTitle: "Custom Labels",
  dashboardManagementPageTitle: "Dashboard Management",
  databaseInsightsPageTitle: "Database Insights",
  databaseInsightAWSMemoryDBTitle: "MemoryDB Insights",
  dataSource_BILLING_export_label: "Billing Export",
  dataSource_BILLING_label: "Billing",
  dataSource_KUBERNETES_CONTAINER_USAGE_label: "Kube Container",
  dataSource_KUBERNETES_NODE_USAGE_label: "Kube Nodes",
  dataStatusCloudHeaderPrefix: "Cloud:",
  dataStatusFastPathToolTip: "Operating at optimal query performance leveraging current Custom Label designations.",
  dataStatusLearnMore: "Learn more here.",
  dataStatusOptimizedPerformance: "Optimized Performance",
  dataStatusOptimizingPerformance: "Optimizing Performance",
  dataStatusSlowPathToolTip: "The Optimized performance mode will be restored automatically no later than 24 hours after the latest Custom Labels change",
  dataWarehouseInsightsPageTitle: "Data Warehouse Insights",
  descriptionInputLabel: "Description",
  deselectFiltersButtonLabel: "Deselect all",
  dimensionOtherNotShown: "Other (not shown)",
  downloadButtonLabel: "Download",
  durationTypeYesterday: "yesterday",
  durationTypeLastSevenDays: "last seven days",
  durationTypeLastThirtyDays: "last thirty days",
  durationTypeLastNinetyDays: "last ninety days",
  durationTypeMonthToDate: "month to date",
  durationTypeYearToDate: "year to date",
  emptyPlaceholderInsufficientPermission: "Insufficient Permissions",
  enabledStrictScopedViewTooltip: "Must have at least one active strictly enabled scoped view at all times",
  enforcedScopedViewTooltip: "Cannot remove enforced Scoped Views",
  ERROR_title: "Operation Failed",
  errorBarDescription: "An error has occured. Please try again",
  errorBarDescriptionShort: "Please try again.",
  errorBarTitle: "Error",
  errorBoundaryContent: "Ternary has been alerted. Please refresh the page to continue.",
  errorBoundaryTitle: "An error has occurred.",
  errorLoadingAnomaliesMessage: "There was an error loading anomalies. Please try again.",
  errorLoadingAnomalyDataMessage: "An error occured while attempting to fetch anomaly chart data. Please try again.",
  errorLoadingBudgetsMessage: "There was an error loading budgets. Please try again.",
  errorLoadingDataIntegrationsMessage: "There was an error loading data integrations. Please try again.",
  errorLoadingJiraIntegrationMessage: "An error occurred while loading the Jira Integration. Please try again.",
  errorLoadingLabelMapsMessage: "There was an error loading custom labels. Please refresh the page and try again.",
  errorLoadingRecommendationsMessage: "There was an error loading recommendations. Please try again.",
  errorLoadingScopedViewFiltersMessage: "There was an error loading shared scoped view filters. Please refresh the page and try again.",
  errorUpdatingRecommendationMessage: "An error occured while attempting to update the recommendation. Please try again.",
  errorUpdatingReportsMessage: "An error occured while attempting to update the reports. Please try again.",
  exportButtonLabel: "Export",
  filterButtonLabel: "Table Filter",
  exportTransposedButtonLabel: "Export Transposed",
  FAILURE_message: "Something went wrong. Please try again.",
  FAILURE_title: "Operation Failed",
  globalFilterButtonLabel: "Scoped Views",
  globalFilterModalTitle: "Scoped Views",
  goToResourceLinkCaption: "Go to resource",
  granularityOptionLabelDay: "Day",
  granularityOptionLabelHour: "Hour",
  granularityOptionLabelMonth: "Month",
  granularityOptionLabelQuarter: "Quarter",
  granularityOptionLabelWeek: "Week",
  widgetTooltip_ALLOYDB: "GCP CUD - Alloy DB",
  widgetTooltip_awsReservedInstance: "AWS - Reserved Instances",
  widgetTooltip_awsSavingsPlan: "AWS - Savings Plans",
  widgetTooltip_BIGQUERY: "Big Query",
  widgetTooltip_CLOUD_BIGTABLE: "GCP CUD - Cloud Big Table",
  widgetTooltip_CLOUD_MEMORY_STORE: "GCP CUD - Cloud Memory Store",
  widgetTooltip_CLOUD_RUN: "GCP CUD - Cloud Run",
  widgetTooltip_CLOUD_SPANNER: "GCP CUD - Cloud Spanner",
  widgetTooltip_CLOUD_SQL: "GCP CUD - Cloud SQL",
  widgetTooltip_CLOUD_VMWARE_ENGINEL: "GCP CUD - Cloud VMWare Engine",
  widgetTooltip_COMPUTE_ENGINE: "GCP CUD - Compute Engine",
  widgetTooltip_EBS: "Elastic Block Storage",
  widgetTooltip_EC2: "Elastic Compute Cloud",
  widgetTooltip_EKS: "Elastic Kubernetes Service",
  widgetTooltip_GCE: "Google Compute Engine",
  widgetTooltip_GCS: "Google Cloud Storage",
  widgetTooltip_GKE: "Google Kubernetes Engine",
  widgetTooltip_KUBERNETES_ENGINE: "GCP CUD - Kubernetes Engine",
  widgetTooltip_RDS: "Relational Database Service",
  widgetTooltip_S3: "Simple Storage Service",
  widgetTooltip_SQL: "Cloud SQL",
  widgetTooltipHeaderCategory: "Category",
  widgetTooltipHeaderTotal: "Total",
  widgetTooltipTotalLabel: "Total:",
  homePageTitle: "Home",
  internalAdminTitle: "Internal Admin",
  kubernetesInsightsPageTitle: "Kubernetes Insights",
  labelGroupingRulesPageTitle: "Custom Groupings",
  loadingLabel: "Loading...",
  meterAssignedRecommendationsCaption: "percent assigned",
  meterAssignedRecommendationsTitle: "Total Current Recommendations",
  meterForecastedSpendComparisonCaption: "compared to last month",
  meterForecastedSpendComparisonTitle: "Forecasted Spend",
  meterLastMonthSpendComparisonCaption: "compared to last month",
  meterLastMonthSpendComparisonTitle: "Last Month Total Spend",
  meterMTDSpendComparisonCaption: "vs. this time last month",
  meterMTDSpendComparisonTitle: "Monthly Spend To Date",
  more: "more...",
  moreInfoLabel: "More...",
  mspAdminTitle: "MSP Admin",
  muteTooltipCaption: "Click to disable",
  nameCopyLabel: "Copy",
  nameInputLabel: "Name",
  navSearchModalQuickLinksText: "Quick Links",
  navSearchModalSuggestedLinksText: "Suggested Links",
  navSearchModalTitle: "Search Ternary",
  navSearchOptionNameAdminClouds: "Admin - Clouds",
  navSearchOptionNameAdminCustomLabel: 'Admin - Custom Label',
  navSearchOptionNameAdmingGlobal: "Admin - Global",
  navSearchOptionNameAdminIntegrations: "Admin - Integrations",
  navSearchOptionNameAdminScopedViews: "Admin - Scoped Views",
  navSearchOptionNameAdminSSO: "Adming - User Group Configurations",
  navSearchOptionNameAdminTenant: "Admin - Tenants",
  navSearchOptionNameAdminUsers: "Admin - Users",
  navSearchOptionNameAKS: "Azure Kubernetes Service",
  navSearchOptionNameAnomalyDetection: "Anomaly Detection",
  navSearchoptionNameAzureCompute: "Azure Virtual Machines",
  navSearchOptionNameAzureSQL: "Azure SQL",
  navSearchOptionNameBudgets: "Budget",
  navSearchOptionNameCloudSQL: "Cloud SQL",
  navSearchOptionNameCloudStorage: 'Cloud Storage',
  navSearchOptionNameCommittedUseAWS: "AWS Commitments",
  navSearchOptionNameCommittedUseAzure: "Azure Commitments",
  navSearchOptionNameCommittedUseGCP: "GCP Commitments",
  navSearchOptionNameComputeEngine: "Compute Engine",
  navSearchOptionNameDashboard: "Dashboard",
  navSearchOptionNameDashboards: "Dashboards",
  navSearchOptionNameEBS: "Elastic Block Store",
  navSearchOptionNameEC2: "Elastic Compute Cloud",
  navSearchOptionNameEKS: "Elastic Kubernetes Service",
  navSearchOptionNameElastiCache: "ElastiCache",
  navSearchOptionNameFiscalCalendar: "Admin - Fiscal Calendar",
  navSearchOptionNameForecasting: "Forecasting",
  navSearchOptionNameGKE: "Google Kubernetes Engine",
  navSearchOptionNameInternalAdminMSP: "Internal Admin MSP",
  navSearchOptionNameMSPAdminTenants: "MSP Admin Tenants",
  navSearchOptionNameMSPAdminUsers: "MSP Admin Users",
  navSearchOptionNameOpenSearch: "OpenSearch",
  navSearchOptionNameRDS: "Relational Database Service",
  navSearchOptionNameRedshiftWarehouse: "AWS Redshift Warehouse",
  navSearchOptionNameReportBuilder: "Report Builder",
  navSearchOptionNameReports: "Reports",
  navSearchOptionNameS3: "Simple Storage Service",
  navSearchOptionNameSettingsAPIKeys: "Settings - API Keys",
  navSearchOptionNameSettingScopedViews: "Settings - Scoped Views",
  navSearchOptionNameSnowflakeDatabase: "Snowflake Database",
  navSearchOptionNameSnowflakeWarehouse: "Snowflake Warehouse",
  noAdminScopedViewsMessage: "No org level scoped views have been applied to you.",
  noDataIntegrationsMessage: "No cloud data integrations to display",
  noDataPlaceholderMessage: "No Data To Display",
  notAvailable: "n/a",
  openSearchInsightsPageTitle: "OpenSearch",
  operatorOptionContainsLabel: "Contains",
  operatorOptionEqualsLabel: "Equals",
  operatorOptionGreaterEqual: "Greater Than or Equal To",
  operatorOptionNotContainsLabel: "Does not contain",
  operatorOptionNotEqualsLabel: "Does not equal",
  operatorOptionNotSetLabel: "Is not set",
  operatorOptionSetLabel: "Is set",
  pricingHeader: "Pricing",
  pricingOptionCustom: "Custom",
  pricingOptionList: "List",
  privateFiltersHeader: "My Scoped Views",
  rampPlansPageTitle: "Ramp Plans",
  reallocationsPageTitle: "Cost Allocation",
  REASON_INACTIVE_TENANT_message: "This tenant is no longer active. Please contact your ternary administrator for more information.",
  REASON_INACTIVE_TENANT_title: "Inactive Tenant",
  REASON_NO_ACCESS_TO_TENANTS_message: "You do not have access to a Tenant. You must have access to at least one Tenant to access Ternary. Please contact your administrator to get access.",
  REASON_NO_ACCESS_TO_TENANTS_title: "Permission Denied",
  REASON_UNEXPECTED_message: "An unexpected error occured. Please refresh the page and try again.",
  REASON_UNEXPECTED_title: "Uh oh",
  removeFilterTooltipCaption: "Click to remove filter",
  removeScopedViewTooltipCaption: "Click to remove scoped view",
  reportsPageTitle: "Report Management",
  rowLimitReached: "Row limit reached. To see the full result, you may want to export the dataset as a CSV",
  saveButtonLabel: "Save",
  searchInputPlaceholder: "Search...",
  selectFilterNameLabel: "Select Label...",
  selectionsAppliedLabel: "(selections applied)",
  selectResourceBannerText: "Select a resource to include in the case",
  selectValuesLabel: "Select Values...",
  sharedViewButtonLabel: "Exit shared view",
  sharedViewMessage: "You are currently in a shared view because you followed a shared link with filters.",
  sideDrawerTitleCreate: "Create",
  sideDrawerTitleEdit: "Edit",
  sideNavItemLabelAdmin: "Admin",
  sideNavItemLabelAlertTracking: "Anomaly Detection",
  sideNavItemLabelBudgets: "Budgets",
  sideNavItemLabelCloudSql: "Cloud SQL",
  sideNavItemLabelCommitmentDiscounts: "Commitment Discounts",
  sideNavItemLabelCommitmentDiscountsAWS: "AWS Commitments",
  sideNavItemLabelCommitmentDiscountsAzure: "Azure Commitments",
  sideNavItemLabelCommitmentDiscountsGCP: "GCP Commitments",
  sideNavItemLabelCommittedUse: "Committed Use",
  sideNavItemLabelCostAssist: "Cost Assist",
  sideNavItemLabelCostAssistCostCompare: "Cost Compare",
  sideNavItemLabelCostAssistRampPlans: "Ramp Plans",
  sideNavItemLabelCostAssistReallocations: "Cost Allocation",
  sideNavItemLabelCostReports: "Cost Reports",
  sideNavItemLabelCustomerManagement: "Customer Management",
  sideNavItemLabelCustomLabels: "Custom Labels",
  sideNavItemLabelDashboard: "Dashboard",
  sideNavItemLabelDashboards: "Dashboards",
  sideNavItemLabelForecasting: "Forecasting",
  sideNavItemLabelGlobalReport: "Global Report",
  sideNavItemLabelHome: "Home",
  sideNavItemLabelInsights: "Resource Utilization",
  sideNavItemLabelInsightsCompute: "Compute",
  sideNavItemLabelInsightsDatabase: "Database",
  sideNavItemLabelInsightsDataWarehouse: "Data Warehouse",
  sideNavItemLabelInsightsKubernetes: "Kubernetes",
  sideNavItemLabelInsightsStorage: "Storage",
  sideNavItemLabelInsightsStorageAWS: "AWS Storage",
  sideNavItemLabelLabelGroupingRules: "Custom Groupings",
  sideNavItemLabelLabelManagement: "Label Management",
  sideNavItemLabelMspBillingRulesEngine: "Billing Rules Engine",
  sideNavItemLabelMspManagement: "MSP",
  sideNavItemLabelReportingEngine: "Reporting Engine",
  sideNavItemLabelReports: "Reports",
  sideNavItemLabelSearch: "Search",
  sideNavItemLabelUserGroupConfigs: "User Groups",
  snowflakeInsightsPageTitle: "Snowflake Insights",
  snowflakeTitlePage: "Snowflake",
  storageInsightsAWSDatabasePageTitle: "RDS Insights",
  storageInsightsAwsS3Title: "AWS S3 Insights",
  storageInsightsPageTitle: "Storage Insights",
  strictlyEnabledFiltersHeader: "Strictly Enabled Scoped Views",
  strictlyEnabledFiltersTooltip: "Have at least one strictly enabled scoped view on at all times.",
  submitButtonLabel: "Submit",
  SUCCESS_title: "Operation Successful!",
  successBarTitle: "Success",
  successUpdatingAnomalyMessage: "The anomaly has been successfully updated.",
  successUpdatingRecommendationMessage: "The recommendation has been successfully updated.",
  successUpdatingReportsMessage: "Reports has been successfully updated.",
  textInputLabel: "Search",
  timeDistanceText: "%distance% ago",
  unitByteMonths: "byte-months",
  unitMonthLabel: "Month",
  unitNodeHours: "node-hours",
  unmuteTooltipCaption: "Click to enable",
  UNVERIFIED_EMAIL_message: "Please close this browser tab and check your email for a message from Ternary to verify your email address.",
  UNVERIFIED_EMAIL_title: "Let’s Verify your Email Address",
  userSettingsManagementPageTitle: "Settings",
  valueLabel: "Value",
};
