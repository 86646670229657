import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useRef } from "react";
import copyText from "../constants/copyText";
import Modal from "../ui-lib/components/Modal";
import FeedbackBar, { FeedbackBarVariants } from "./FeedbackBar";

interface Props {
  error?: boolean;
  isOpen: boolean;
  loading: boolean;
  text: string;
  onAffirmative: () => void;
  onClose: () => void;
}

export default function ConfirmationModal(props: Props): JSX.Element {
  const wasLoading = useRef(false);

  useEffect(() => {
    if (
      props.loading === false &&
      wasLoading.current === true &&
      !props.error
    ) {
      props.onClose();
    }
    wasLoading.current = props.loading;
  }, [props.loading]);

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      showCloseButton
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">{copyText.confirmation}</Text>
      </Modal.Header>
      <Modal.Body>
        {props.error && (
          <FeedbackBar
            text={copyText.errorBarDescription}
            title={copyText.errorBarTitle}
            variant={FeedbackBarVariants.error}
          />
        )}
        <Text>{props.text}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button secondary width={70} onClick={props.onClose}>
          {copyText.confirmationNo}
        </Button>
        <Button primary width={70} onClick={props.onAffirmative}>
          {props.loading ? <LoadingSpinner /> : copyText.confirmationYes}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
