import Permission from "./permissions";

export const Role = {
  BASIC_USER: "BASIC_USER",
  FULL_ACCESS_USER: "FULL_ACCESS_USER",
  LIMITED_USER: "LIMITED_USER",
  PARTNER_ADMIN: "PARTNER_ADMIN",
  SYSTEM_ADMIN: "SYSTEM_ADMIN",
  TENANT_ADMIN: "TENANT_ADMIN",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

// Least amount of perms. Meant for cost reporting only.
export const RoleLimitedUser = [
  Permission.CREATE_BUDGET,
  Permission.CREATE_CASE_COMMENT,
  Permission.CREATE_CASE,
  Permission.CREATE_DASHBOARD_SUBSCRIPTION,
  Permission.CREATE_DASHBOARD,
  Permission.CREATE_RESOURCE_SUBSCRIPTION,
  Permission.CREATE_REPORT,
  Permission.CREATE_SAVING_OPPORTUNITY,
  Permission.DELETE_BUDGET_SELF,
  Permission.DELETE_DASHBOARD_SELF,
  Permission.DELETE_DASHBOARD_SUBSCRIPTION,
  Permission.DELETE_REPORT_SELF,
  Permission.DELETE_RESOURCE_SUBSCRIPTION,
  Permission.READ_ALERT_RULE_EVENTS,
  Permission.READ_ALERT_RULES,
  Permission.READ_AWS_RATE_RECOMMENDATIONS,
  Permission.READ_AZURE_RATE_RECOMMENDATIONS,
  Permission.READ_BUDGETS,
  Permission.READ_CASES_SELF,
  Permission.READ_COST_ALERTS,
  Permission.READ_CUD_INVENTORY,
  Permission.READ_CUD_RECOMMENDATIONS,
  Permission.READ_CUSTOM_METRICS,
  Permission.READ_DASHBOARDS,
  Permission.READ_DATA_INTEGRATIONS,
  Permission.READ_FEATURE_FLAGS,
  Permission.READ_INTEGRATIONS,
  Permission.READ_LABEL_MAP,
  Permission.READ_LABEL_PREFERENCE,
  Permission.READ_MEASURE_PREFERENCE,
  Permission.READ_RECOMMENDATIONS,
  Permission.READ_REPORT_DATA,
  Permission.READ_RESOURCE_SUBSCRIPTIONS,
  Permission.READ_REPORTS,
  Permission.READ_USERS,
  Permission.UPDATE_BUDGET_SELF,
  Permission.UPDATE_CASE_SELF,
  Permission.UPDATE_DASHBOARD_SELF,
  Permission.UPDATE_DASHBOARD_SUBSCRIPTION,
  Permission.UPDATE_REPORT_SELF,
  Permission.UPDATE_RESOURCE_SUBSCRIPTION,
] as const;

// Basic User can do many things in the app. Can't delete resources
// or access admin page or custom labels.
export const RoleBasicUser = [
  ...RoleLimitedUser,
  Permission.CREATE_ALERT_RULE,
  Permission.CREATE_COST_COMPARE_BILL,
  Permission.CREATE_RAMP_PLAN,
  Permission.GENERATE_COST_COMPARE_TAKEOUT_REPORT,
  Permission.READ_CASES,
  Permission.READ_COMMITTED_USE_DATA,
  Permission.READ_COST_COMPARE_BILLS,
  Permission.READ_RAMP_PLANS,
  Permission.READ_REALLOCATION_JOBS,
  Permission.READ_REALLOCATIONS,
  Permission.READ_TENANTS,
  Permission.UPDATE_ALERT_RULE,
  Permission.UPDATE_BUDGET,
  Permission.UPDATE_CASE,
  Permission.UPDATE_DASHBOARD,
  Permission.UPDATE_RAMP_PLAN,
  Permission.UPDATE_RECOMMENDATION,
  Permission.UPDATE_REPORT,
] as const;

// Full Access User can do most things in the app. Can delete
// resources. Can't view/edit Integrations or Users.
export const RoleFullAccessUser = [
  ...RoleBasicUser,
  Permission.CREATE_ADMIN_SCOPED_VIEW,
  Permission.CREATE_CUSTOM_LABEL,
  Permission.CREATE_CUSTOM_METRIC,
  Permission.CREATE_LABEL_GROUPING_RULE,
  Permission.CREATE_LABEL_PREFERENCE,
  Permission.CREATE_MEASURE_PREFERENCE,
  Permission.CREATE_REALLOCATION,
  Permission.DELETE_ADMIN_SCOPED_VIEW,
  Permission.DELETE_BUDGET,
  Permission.DELETE_COST_COMPARE_BILL,
  Permission.DELETE_CUSTOM_LABEL,
  Permission.DELETE_CUSTOM_METRIC,
  Permission.DELETE_DASHBOARD,
  Permission.DELETE_LABEL_GROUPING_RULE,
  Permission.DELETE_RAMP_PLAN,
  Permission.DELETE_REALLOCATION,
  Permission.DELETE_REPORT,
  Permission.DELETE_SAVING_OPPORTUNITY,
  Permission.READ_ADMIN_SCOPED_VIEWS,
  Permission.READ_CUSTOM_LABELS,
  Permission.READ_LABEL_GROUPING_RULES,
  Permission.TRIGGER_REALLOCATION,
  Permission.UPDATE_ADMIN_SCOPED_VIEW,
  Permission.UPDATE_CUSTOM_LABEL,
  Permission.UPDATE_CUSTOM_METRIC,
  Permission.UPDATE_LABEL_GROUPING_RULE,
  Permission.UPDATE_LABEL_PREFERENCE,
  Permission.UPDATE_MEASURE_PREFERENCE,
  Permission.UPDATE_REALLOCATION,
] as const;

// This user has access to all GA sections of the app.
// Can do anything on admin page.
export const RoleTenantAdmin = [
  ...RoleFullAccessUser,
  Permission.CREATE_DATA_INTEGRATION,
  Permission.CREATE_INTEGRATAION,
  Permission.CREATE_USER,
  Permission.CREATE_USER_GROUP_CONFIG,
  Permission.DELETE_DATA_INTEGRATION,
  Permission.DELETE_INTEGRATION,
  Permission.DELETE_USER_GROUP_CONFIG,
  Permission.GRANT_TENANT_ACCESS,
  Permission.READ_GRANTS,
  Permission.READ_USER_GROUP_CONFIGS,
  Permission.REVOKE_TENANT_ACCESS,
  Permission.UPDATE_DATA_INTEGRATION,
  Permission.UPDATE_GRANT,
  Permission.UPDATE_INTEGRATION,
  Permission.UPDATE_TENANT,
  Permission.UPDATE_USER_GROUP_CONFIG,
] as const;

// This role is for supporting our Partner customers who need to be able to
// create numerous tenants for their clients.
export const RolePartnerAdmin = [
  ...RoleTenantAdmin,
  Permission.CREATE_MSP_BILLING_INFO,
  Permission.CREATE_MSP_BILLING_RULE_SET,
  Permission.CREATE_TENANT,
  Permission.DELETE_MSP_BILLING_INFO,
  Permission.DELETE_MSP_BILLING_RULE_SET,
  Permission.GRANT_SELF_TENANT_ACCESS,
  Permission.READ_GRANTS_PARTNER,
  Permission.READ_MSP_BILLING_INFO,
  Permission.READ_MSP_BILLING_RULE_SETS,
  Permission.READ_TENANTS_PARTNER,
  Permission.READ_USERS_PARTNER,
  Permission.UPDATE_MSP_BILLING_INFO,
  Permission.UPDATE_MSP_BILLING_RULE_SET,
];

// This role is essentialy "god mode". Intended for Ternary employees only.
export const RoleSystemAdmin = [
  ...RolePartnerAdmin,
  Permission.CREATE_FEATURE_FLAG,
  Permission.DELETE_FEATURE_FLAG,
  Permission.DELETE_TENANT,
  Permission.DISTRIBUTE_SYSTEM_REPORT,
  Permission.READ_AUDIT_LOGS,
  Permission.READ_GRANTS_SYSTEM,
  Permission.READ_REPORTS_SYSTEM,
  Permission.READ_TENANTS_SYSTEM,
  Permission.READ_USER_TENANT_SETTINGS_SYSTEM,
  Permission.READ_USERS_SYSTEM,
  Permission.REMOVE_SYSTEM_REPORT,
  Permission.UPDATE_USER_TENANT_SETTINGS,
];
