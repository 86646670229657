/* prettier-ignore */
export default {
  announceKitWidgetID: "https://announcekit.co/widgets/v2/2RdKeY",
  companyWebsite: "https://ternary.app",
  gcpGeneralPurposeCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#general-purpose_commitments",
  gcpComputeOptimizedCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#compute-optimized_commitments",
  zendeskBillingDataPerformanceModeDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/8396043225239",
  zendeskCostCompareDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/6963565240855-AWS-GCP-Cost-Compare",
  zendeskCustomLabelsDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/360063060593-Custom-Labels",
  zendeskGCPOnboardingDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/4403370660119-GCP-Onboarding",
  zendeskJiraIntegrationDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/15532661630743-Jira-Integration",
  zendeskRolePermissionsDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/18768198692503-User-Role-Permissions",
  zendeskSlackIntegrationDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/4413439510167",
};
