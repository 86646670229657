import Permission from "@ternary/api-lib/constants/permissions";
import { isGlobalAdmin } from "@ternary/api-lib/constants/system";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type ReportGates = ReturnType<typeof reportGates>;

export default function reportGates(params: GateGetterParameters) {
  const canUpdateReports = (createdByID: string) =>
    params._permissionsIncludeAllOf([Permission.UPDATE_REPORT]) ||
    createdByID === params.authenticatedUser.id;

  const canUpdateSystemReport = isGlobalAdmin(params.authenticatedUser.email);

  return {
    canCreateReports: params._permissionsIncludeAllOf([
      Permission.CREATE_REPORT,
    ]),
    canListReports: params._permissionsIncludeAllOf([Permission.READ_REPORTS]),
    canListSystemReports: params._permissionsIncludeAllOf([
      Permission.READ_REPORTS_SYSTEM,
    ]),
    canUpdateReports,
    canUpdateSystemReport,
    getCanDeleteSpecificReport: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_REPORT]) ||
      Boolean(createdByID === params.authenticatedUser.id),
  };
}
