import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { defaultDimensionsMap } from "@ternary/api-lib/constants/analytics";
import {
  DataSource,
  MspBillingRuleType,
  TenantType,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { keyBy, uniq } from "lodash";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import useGetDimensionValuesByDataSourceV2 from "../../../api/analytics/useGetDimensionValuesByDataSourceV2";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import SideDrawer from "../../../components/SideDrawer";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import { roundDate } from "../../../utils/dates";
import getMergeState from "../../../utils/getMergeState";
import TenantListModal from "../../global-admin/components/TenantListModal";
import useGetTenants from "../../global-admin/hooks/useGetTenants";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useCreateRuleSet from "../hooks/useCreateRuleSet";
import useDeleteRuleSet from "../hooks/useDeleteRuleSet";
import useGetMspBillingAccountsByTenantIDs from "../hooks/useGetMspBillingAccountsByTenantIDs";
import useGetMspBillingRuleSetsByTenantID from "../hooks/useGetRuleSetsByTenantID";
import useUpdateRuleSet from "../hooks/useUpdateRuleSet";
import { RuleSet } from "../types";
import MspBillingRuleSetForm from "./MspBillingRuleSetForm";
import RuleSetTable from "./MspBillingRuleSetTable";

type Interaction =
  | MspBillingRuleSetForm.Interaction
  | RuleSetTable.Interaction
  | TenantListModal.Interaction;

type State = {
  dimensions: string[];
  parentTenantID: string | null;
  ruleSets: RuleSet[];
  searchText: string;
  selectedRuleSetID: string | null;
  showForm: boolean;
  showModal: boolean;
  targetTenantDocIDs: string[];
};

const initialState = {
  dimensions: [],
  ruleSets: [],
  searchText: "",
  selectedRuleSetID: null,
  showForm: false,
  showModal: false,
  targetTenantDocIDs: [],
};

const defaultRuleSets = [];

export default function MspBillingRuleSetContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const dateHelper = new DateHelper();

  //
  // State
  //

  const [state, setState] = useState<State>({
    ...initialState,
    ...(gatekeeper.canAccessInternalAdmin
      ? { parentTenantID: null }
      : { parentTenantID: authenticatedUser.parentTenantID as string }),
  });

  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _tenants = [], isLoading: isLoading } = useGetTenants({
    enabled: gatekeeper.canReadTenantsSystem && !state.parentTenantID,
  });

  const { data: _mspTenants = [], isLoading: _isLoadingMspTenants } =
    useGetTenantsByParentTenantID(state.parentTenantID ?? "", {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const {
    data: _ruleSets = defaultRuleSets,
    isLoading: isLoadingRuleSets,
    refetch: refetchRuleSets,
  } = useGetMspBillingRuleSetsByTenantID(state.parentTenantID as string, {
    enabled: !!state.parentTenantID,
  });

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersByTenantID(
    state.parentTenantID as string,
    { enabled: !!state.parentTenantID }
  );

  const tenantsKeyedByID = keyBy(_mspTenants, "id");

  const availableDimensions = defaultDimensionsMap[DataSource.BILLING];

  const {
    data: _dimensionValuesMap = {},
    isFetching: isLoadingDimensionValues,
  } = useGetDimensionValuesByDataSourceV2(
    {
      dataSource: DataSource.BILLING,
      dateRange: [
        roundDate(dateHelper.nMonthsAgo(24)),
        roundDate(dateHelper.date),
      ],
      dimensions: state.dimensions,
      parentTenantID: state.parentTenantID as string,
    },
    { enabled: state.dimensions.length > 0 }
  );

  const { data: billingAccountsMap, isLoading: isLoadingBillingAccounts } =
    useGetMspBillingAccountsByTenantIDs(
      {
        dateRange: [dateHelper.nMonthsAgo(36), dateHelper.date],
        parentTenantID: state.parentTenantID as string,
        tenantDocIDs: state.targetTenantDocIDs.map((docID) => docID),
      },
      {
        enabled:
          gatekeeper.canReadTenantsPartner &&
          state.targetTenantDocIDs.length > 0,
      }
    );

  //
  // Mutations
  //

  const { isPending: isCreatingRuleSet, mutate: createRuleSet } =
    useCreateRuleSet({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingRuleSetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchRuleSets();

        mergeState({ showForm: false });

        postAlert({
          message: copyText.SUCCESS_RULE_SET_CREATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isDeletingRuleSet, mutate: deleteRuleSet } =
    useDeleteRuleSet({
      onError: () => {
        postAlert({
          message: copyText.errorDeletingRuleSetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchRuleSets();

        mergeState({ selectedRuleSetID: null, showModal: false });

        postAlert({
          message: copyText.SUCCESS_RULE_SET_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isUpdatingRuleSet, mutate: updateRuleSet } =
    useUpdateRuleSet({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingRuleSetsMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchRuleSets();

        mergeState({ selectedRuleSetID: null, showForm: false });

        postAlert({
          message: copyText.SUCCESS_RULE_SET_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  //
  // Computed Values
  //

  const ruleSetsKeyedByID = keyBy(_ruleSets, "id");

  const selectedRuleSet = state.selectedRuleSetID
    ? ruleSetsKeyedByID[state.selectedRuleSetID]
    : undefined;

  const usersKeyedByID = keyBy(users, "id");

  let ruleSets = _ruleSets.map((rule) => ({
    ...rule,
    createdByEmail: usersKeyedByID[rule.createdByID]?.email ?? "",
  }));

  if (state.searchText.length > 0) {
    ruleSets = ruleSets.filter((ruleSet) => {
      return [ruleSet.id, ruleSet.name, ruleSet.createdByEmail]
        .join(" ")
        .toLowerCase()
        .includes(state.searchText.toLowerCase());
    });
  }

  //
  // Interaction Handlers
  //

  function handleDeleteRuleSet() {
    if (!state.selectedRuleSetID) return;

    deleteRuleSet({ ruleSetID: state.selectedRuleSetID });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspBillingRuleSetForm.INTERACTION_ADD_FILTER_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          dimensions: uniq([...currentState.dimensions, interaction.filter]),
        }));
        return;
      }
      case MspBillingRuleSetForm.INTERACTION_ADD_TARGET_TENANT_ID: {
        setState((currentState) => ({
          ...currentState,
          targetTenantDocIDs: [
            ...currentState.targetTenantDocIDs,
            tenantsKeyedByID[interaction.tenantID].fsDocID,
          ],
        }));
        return;
      }
      case MspBillingRuleSetForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ showForm: false, selectedRuleSetID: undefined });
        return;
      }
      case MspBillingRuleSetForm.INTERACTION_DIMENSION_NAME_SELECTED: {
        setState((currentState) => ({
          ...currentState,
          dimensions: uniq([...currentState.dimensions, interaction.name]),
        }));
        return;
      }
      case MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createRuleSet({
          tenantID: state.parentTenantID as string,
          applyToAllTenants: interaction.applyToAllTenants,
          endTime: interaction.endTime.length > 0 ? interaction.endTime : null,
          name: interaction.name,
          rules: interaction.rules,
          startTime: interaction.startTime,
          tenantIDs: interaction.tenantIDs,
        });
        return;
      }
      case MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!state.selectedRuleSetID) return;

        updateRuleSet({
          ruleSetID: state.selectedRuleSetID,
          applyToAllTenants: interaction.applyToAllTenants,
          endTime: interaction.endTime,
          name: interaction.name,
          rules: interaction.rules,
          startTime: interaction.startTime,
          tenantIDs: interaction.tenantIDs,
        });
        return;
      }
      case RuleSetTable.INTERACTION_DELETE_CLICKED: {
        mergeState({
          selectedRuleSetID: interaction.ruleSetID,
          showModal: true,
        });
        return;
      }
      case RuleSetTable.INTERACTION_EDIT_CLICKED: {
        const selectedRuleSet = ruleSetsKeyedByID[interaction.ruleSetID];

        if (!selectedRuleSet) return;

        const dimensions = selectedRuleSet.rules
          ? [
              ...selectedRuleSet.rules.reduce((accum: string[], rule) => {
                if (!rule.meta.filters) return accum;

                rule.meta.filters.forEach((filter) =>
                  accum.push(filter.schema_field_name)
                );

                return accum;
              }, []),
            ]
          : [];

        const targetTenantIDs = selectedRuleSet.rules
          ? [
              ...selectedRuleSet.rules.reduce((accum: string[], rule) => {
                if (rule.type === MspBillingRuleType.MOVE_BILLING_ACCOUNT) {
                  const tenantDocID =
                    tenantsKeyedByID[rule.meta.tenantID as string].fsDocID;

                  accum.push(tenantDocID);
                }

                return accum;
              }, []),
            ]
          : [];

        mergeState({
          dimensions,
          showForm: true,
          selectedRuleSetID: interaction.ruleSetID,
          targetTenantDocIDs: targetTenantIDs,
        });
        return;
      }
      case TenantListModal.INTERACTION_ROW_CLICKED: {
        mergeState({ parentTenantID: interaction.tenantID });
        return;
      }
    }
  }

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  // If a Ternary System admin is attempting to access MSP Admin, they need to select
  // Which MSP Partner Tennat they want to look at first.
  if (!state.parentTenantID && gatekeeper.canReadTenantsSystem) {
    const tenants = _tenants.filter(
      (tenant) => tenant.type === TenantType.MSP_PARENT
    );

    return (
      <TenantListModal
        isLoading={isLoading}
        tenants={tenants}
        onInteraction={handleInteraction}
      />
    );
  }

  return (
    <>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isDeletingRuleSet}
          message={copyText.deleteRuleSetModalMessage}
          title={copyText.deleteRuleSetModalTitle}
          variant="danger"
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleDeleteRuleSet}
        />
      )}
      {state.showForm && (
        <SideDrawer
          isOpen
          title={
            state.selectedRuleSetID
              ? copyText.sideDrawerTitleEdit
              : copyText.sideDrawerTitleCreate
          }
          onClose={() =>
            mergeState({
              showForm: false,
              selectedRuleSetID: undefined,
            })
          }
          renderContent={() => (
            <MspBillingRuleSetForm
              availableDimensions={availableDimensions}
              billingAccounts={billingAccountsMap ?? {}}
              childTenants={_mspTenants}
              dimensionValues={_dimensionValuesMap}
              isLoading={isLoadingRuleSets || _isLoadingMspTenants}
              isLoadingBillingAccounts={isLoadingBillingAccounts}
              isLoadingDimensionValues={isLoadingDimensionValues}
              isProcessing={isCreatingRuleSet || isUpdatingRuleSet}
              selectedRuleSet={
                selectedRuleSet as Omit<RuleSet, "createdByEmail">
              }
              onInteraction={handleInteraction}
            />
          )}
        />
      )}

      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        <Text appearance="h2">{copyText.ruleSetPageTitle}</Text>
        <Flex alignItems="center" justifyContent="right">
          <Box width={250} marginRight={theme.space_md}>
            <TextInput
              iconStart={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              onChange={(event) =>
                mergeState({ searchText: event.target.value })
              }
            />
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            secondary
            size="small"
            onClick={() => mergeState({ showForm: true })}
          >
            {copyText.createRuleSetButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <RuleSetTable
        ruleSets={ruleSets as RuleSet[]}
        isLoading={isLoadingRuleSets || isLoadingUsers}
        onInteraction={handleInteraction}
      />
    </>
  );
}
