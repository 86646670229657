import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faCalendar,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  ChartType,
  DataSource,
  DurationType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { getCompareDateRangeFromDurationType } from "@ternary/api-lib/utils/ReportUtils";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React, { useMemo, useState } from "react";
import paths from "../../constants/paths";
import useAvailableGlobalDate from "../../hooks/useAvailableGlobalDate";
import { useMatchPath } from "../../lib/react-router";
import copyText from "../copyText";
import DatePickerModal from "./DatePickerModal";
import Switch from "./Switch";

const StyledButton = styled(Button)<{ isGrouped?: boolean }>(
  ({ isGrouped, theme }) => ({
    paddingLeft: isGrouped ? theme.space_sm : theme.space_xs,
    paddingRight: isGrouped ? theme.space_sm : theme.space_xs,
  })
);

const StyledButtonGroup = styled("div")<{ isGrouped?: boolean }>(
  ({ isGrouped }) => ({
    alignItems: "center",
    display: "flex",
    ...(isGrouped
      ? {
          "div > button, button:not(:first-of-type)": {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          },

          "div > button, button:not(:last-child)": {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
        }
      : {}),
  })
);

export interface Props {
  chartType?: ChartType;
  compareDateRange?: DateRange | null;
  compareType?: DurationType | null;
  dataSource?: DataSource;
  dateRange?: DateRange | null;
  disabled?: boolean;
  durationType: DurationType;
  hiddenOptions?: DurationType[];
  isFiscalMode?: boolean;
  isGlobalDateControls?: boolean;
  isGrouped?: boolean;
  maxDate?: Date;
  minDate?: Date;
  n?: number | null;
  showRollingLookback?: boolean;
  suspendCustomDates?: boolean;
  onChangeCompareType?: () => void;
  onChangeCompareDateRange?: (
    durationType: DurationType,
    dateRange?: DateRange
  ) => void;
  onChangeDateRange: (
    durationType: DurationType,
    dateRange?: DateRange,
    isCompareChecked?: boolean
  ) => void;
  onChangeRollingLookback?: (
    durationType: DurationType,
    nLookback: number
  ) => void;
}

const initialState = {
  isInvoiceMonth: false,
  isModalOpen: false,
};

const dateRangeText = {
  [DurationType.LAST_MONTH]: {
    default: copyText.dateRangeControlsLastMonthLabel_default,
  },
  [DurationType.LAST_NINETY_DAYS]: {
    default: copyText.dateRangeControlsLast90DaysLabel_default,
    fiscal: copyText.dateRangeControlsLast90DaysLabel_fiscal,
  },
  [DurationType.LAST_SEVEN_DAYS]: {
    default: copyText.dateRangeControlsLast7DaysLabel_default,
    fiscal: copyText.dateRangeControlsLast7DaysLabel_fiscal,
  },
  [DurationType.LAST_THIRTY_DAYS]: {
    default: copyText.dateRangeControlsLast30DaysLabel_default,
    fiscal: copyText.dateRangeControlsLast30DaysLabel_fiscal,
  },
  [DurationType.MONTH_TO_DATE]: {
    default: copyText.dateRangeControlsMTDLabel_default,
    fiscal: copyText.dateRangeControlsMTDLabel_fiscal,
  },
  [DurationType.QUARTER_TO_DATE]: {
    default: copyText.dateRangeControlsQTDLabel_default,
    fiscal: copyText.dateRangeControlsQTDLabel_fiscal,
  },
  [DurationType.YEAR_TO_DATE]: {
    default: copyText.dateRangeControlsYTDLabel_default,
    fiscal: copyText.dateRangeControlsYTDLabel_fiscal,
  },
};

// TODO: Replace Font Awesome with BB Icons
export default function DateRangeControls(props: Props): JSX.Element {
  const theme = useTheme();
  const currentPath = useMatchPath();
  const globalDate = useAvailableGlobalDate();

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  const isComparisonMode = !!props.compareType;
  const durationType =
    !props.isGlobalDateControls && globalDate.date
      ? DurationType.CUSTOM
      : props.durationType;

  function handleChangeDurationType(durationType: DurationType) {
    props.onChangeDateRange(durationType, undefined, isComparisonMode);
  }

  function handleChangeDateRange(
    compareDateRange: DateRange | undefined,
    dateRange: DateRange,
    isInvoiceMonth: boolean
  ) {
    props.onChangeDateRange(
      isInvoiceMonth ? DurationType.INVOICE : DurationType.CUSTOM,
      dateRange
    );

    if (isComparisonMode && props.onChangeCompareDateRange) {
      props.onChangeCompareDateRange(
        isInvoiceMonth ? DurationType.INVOICE : DurationType.CUSTOM,
        compareDateRange
      );
    }
  }

  function handleToggleCompareSwitch() {
    if (!props.onChangeCompareType) return null;

    props.onChangeCompareType();
  }

  const dateRangeFromDurationType = useMemo(
    () => getCubeDateRangeFromDurationType(props.durationType),
    [props.durationType]
  );

  const compareDateRangeFromDurationType = useMemo(
    () =>
      getCompareDateRangeFromDurationType(
        props.durationType,
        dateRangeFromDurationType
      ),
    [props.durationType]
  );

  const dateRange = props.dateRange ?? dateRangeFromDurationType;
  const isDisabledDetailedBilling =
    props.dataSource === DataSource.DETAILED_BILLING || props.disabled
      ? true
      : false;
  const isDisabled =
    (!props.isGlobalDateControls && globalDate.date !== null) || props.disabled;

  const compareDateRange =
    props.compareDateRange ?? compareDateRangeFromDurationType;

  const isHidden = (duration: DurationType) =>
    !props.hiddenOptions ? false : props.hiddenOptions.includes(duration);

  const textKey = props.isFiscalMode ? "fiscal" : "default";

  const rollingLookbackDurationType = [
    DurationType.LAST_N_DAYS,
    DurationType.LAST_N_DAYS_TO_DATE,
    DurationType.LAST_N_MONTHS,
    DurationType.LAST_N_MONTHS_TO_DATE,
  ].find((lookbackType) => lookbackType === props.durationType);

  const showCompareSwitch =
    currentPath === paths._report ||
    currentPath === paths._reportBuilder ||
    currentPath === paths._reportBuilderNew ||
    currentPath === paths._reports;

  return (
    <Flex alignItems="center">
      <DatePickerModal
        n={props.n}
        compareDateRange={compareDateRange}
        dateRange={dateRange}
        dataSource={props.dataSource}
        lookbackType={rollingLookbackDurationType}
        isComparisonMode={isComparisonMode}
        isInvoiceMonth={state.isInvoiceMonth}
        isOpen={state.isModalOpen}
        maxDate={props.maxDate}
        minDate={props.minDate}
        showRollingLookback={props.showRollingLookback && !state.isInvoiceMonth}
        onChangeRollingLookback={props.onChangeRollingLookback}
        onChangeDateRange={handleChangeDateRange}
        onClose={() =>
          mergeState({ isInvoiceMonth: false, isModalOpen: false })
        }
      />
      <StyledButtonGroup isGrouped={props.isGrouped}>
        {/* Last Month */}
        {!isHidden(DurationType.LAST_MONTH) && (
          <StyledButton
            disabled={isDisabled}
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.LAST_MONTH}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.LAST_MONTH)}
          >
            {copyText.dateRangeControlsLastMonthLabel_default}
          </StyledButton>
        )}
        {/* Yesterday */}
        {!isHidden(DurationType.YESTERDAY) && (
          <StyledButton
            disabled={isDisabled}
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.YESTERDAY}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.YESTERDAY)}
          >
            {copyText.dateRangeControlsYesterdayLabel}
          </StyledButton>
        )}
        {/* 7 Day */}
        {!isHidden(DurationType.LAST_SEVEN_DAYS) && (
          <StyledButton
            disabled={isDisabled}
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.LAST_SEVEN_DAYS}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.LAST_SEVEN_DAYS)
            }
          >
            {dateRangeText[DurationType.LAST_SEVEN_DAYS][textKey]}
          </StyledButton>
        )}
        {/* 30 Day */}
        {!isHidden(DurationType.LAST_THIRTY_DAYS) && (
          <StyledButton
            disabled={isDisabled}
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.LAST_THIRTY_DAYS}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.LAST_THIRTY_DAYS)
            }
          >
            {dateRangeText[DurationType.LAST_THIRTY_DAYS][textKey]}
          </StyledButton>
        )}
        {/* 90 Day */}
        {!isHidden(DurationType.LAST_NINETY_DAYS) && (
          <StyledButton
            disabled={isDisabledDetailedBilling || isDisabled}
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.LAST_NINETY_DAYS}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.LAST_NINETY_DAYS)
            }
          >
            <Tooltip
              hide={props.dataSource !== DataSource.DETAILED_BILLING}
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.LAST_NINETY_DAYS][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* MTD */}
        {!isHidden(DurationType.MONTH_TO_DATE) && (
          <StyledButton
            disabled={
              isDisabled || (isDisabledDetailedBilling && props.isFiscalMode)
            }
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.MONTH_TO_DATE}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.MONTH_TO_DATE)}
          >
            <Tooltip
              hide={
                props.dataSource !== DataSource.DETAILED_BILLING ||
                !props.isFiscalMode
              }
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.MONTH_TO_DATE][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* QTD */}
        {!isHidden(DurationType.QUARTER_TO_DATE) && (
          <StyledButton
            disabled={
              isDisabled || (isDisabledDetailedBilling && props.isFiscalMode)
            }
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.QUARTER_TO_DATE}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.QUARTER_TO_DATE)
            }
          >
            <Tooltip
              hide={
                props.dataSource !== DataSource.DETAILED_BILLING ||
                !props.isFiscalMode
              }
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.QUARTER_TO_DATE][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* YTD */}
        {!isHidden(DurationType.YEAR_TO_DATE) && (
          <StyledButton
            disabled={isDisabledDetailedBilling || isDisabled}
            isGrouped={props.isGrouped}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.YEAR_TO_DATE}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.YEAR_TO_DATE)}
          >
            <Tooltip
              hide={props.dataSource !== DataSource.DETAILED_BILLING}
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.YEAR_TO_DATE][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* Custom */}
        {!isHidden(DurationType.CUSTOM) && (
          <StyledButton
            disabled={isDisabled}
            isGrouped={props.isGrouped}
            iconStart={<Icon icon={faCalendar} />}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.CUSTOM ||
              durationType === DurationType.LAST_N_DAYS ||
              durationType === DurationType.LAST_N_DAYS_TO_DATE ||
              durationType === DurationType.LAST_N_MONTHS_TO_DATE ||
              durationType === DurationType.LAST_N_MONTHS
            }
            size="small"
            onClick={() =>
              props.suspendCustomDates
                ? handleChangeDurationType(DurationType.CUSTOM)
                : mergeState({ isModalOpen: true })
            }
          >
            {copyText.dateRangeControlsCustomLabel}
          </StyledButton>
        )}
        {/* Invoice */}
        {!isHidden(DurationType.INVOICE) && (
          <StyledButton
            disabled={isDisabledDetailedBilling || isDisabled}
            isGrouped={props.isGrouped}
            iconStart={<Icon icon={faFileInvoiceDollar} />}
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={durationType === DurationType.INVOICE}
            size="small"
            onClick={() =>
              mergeState({ isModalOpen: true, isInvoiceMonth: true })
            }
          >
            <Tooltip
              hide={props.dataSource !== DataSource.DETAILED_BILLING}
              content={copyText.disableDateControlTooltipCaption}
            >
              {copyText.dateRangeControlsInvoiceLabel}
            </Tooltip>
          </StyledButton>
        )}
        {/* Custom Compare Date */}
        {showCompareSwitch && !props.isGlobalDateControls && (
          <Tooltip
            content={copyText.disableCompareToggleTooltipCaption}
            hide={
              props.chartType === ChartType.KPI ||
              props.chartType === ChartType.LINE ||
              props.chartType === ChartType.TABLE
            }
          >
            <Flex alignItems="center">
              <Text marginHorizontal={theme.space_xs}>
                {copyText.compareToggleLabel}
              </Text>
              <Switch
                checked={isComparisonMode}
                disabled={
                  props.chartType !== ChartType.KPI &&
                  props.chartType !== ChartType.LINE &&
                  props.chartType !== ChartType.TABLE
                }
                onChange={handleToggleCompareSwitch}
              />
            </Flex>
          </Tooltip>
        )}
      </StyledButtonGroup>
    </Flex>
  );
}
