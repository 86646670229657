import { useTheme } from "@emotion/react";
import { faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import React, { ChangeEvent, useState } from "react";
import { DimensionValuesMap } from "../../../api/analytics/useGetDimensionValues";
import DualListbox from "../../../components/DualListBox";
import Switch from "../../../ui-lib/components/Switch";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import { serviceTypeToLabelMap } from "./EditUserNotificationSettingsForm";

type RecommendationConfig = {
  enableRecsDaily: boolean;
  enableRecsMonthly: boolean;
  enableRecsWeekly: boolean;
  isEnabled: boolean;
  services: string[];
  subAccountIDs: string[];
  vendors: string[];
};
interface Props {
  dimensionValuesMap: DimensionValuesMap;
  isLoadingDimensionValueMap: boolean;
  recNotificationConfig: RecommendationConfig;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
}

type State = {
  isUpdatingServices: boolean;
  isUpdatingSubAccounts: boolean;
  isUpdatingVendor: boolean;
};

const initialState: State = {
  isUpdatingServices: false,
  isUpdatingSubAccounts: false,
  isUpdatingVendor: false,
};

export default function RecommendationsNotificationForm(
  props: Props
): JSX.Element {
  const theme = useTheme();

  //
  // state
  //

  const [state, setState] = useState<State>({
    ...initialState,
    isUpdatingVendor: props.recNotificationConfig.vendors.length > 0,
    isUpdatingServices: props.recNotificationConfig.services.length > 0,
    isUpdatingSubAccounts: props.recNotificationConfig.subAccountIDs.length > 0,
  });
  const mergeState = getMergeState(setState);

  //
  // Render
  //

  const vendorOptions = Object.values(CloudProviderType).map((value) => ({
    label: value,
    value: value,
  }));

  const projectIDOptions =
    props.dimensionValuesMap.projectId?.map((value) => ({
      label: value,
      value: value,
    })) ?? [];

  const serviceTypeOptions = Object.values(serviceTypeToLabelMap).map(
    (value) => ({
      label: value,
      value: value,
    })
  );

  return (
    <Flex direction="column">
      <Flex direction="column">
        <Flex alignItems="center" marginTop={theme.space_sm}>
          <Text appearance="h4">
            {copyText.recNotificationAllRecommendations}
          </Text>
          <Tooltip
            content={copyText.recNotificationFilterInfoTooltipCaption}
            icon={faInfoCircle}
            width="250px"
          />
        </Flex>

        <Box marginTop={theme.space_md}>
          <Text appearance="h4">{copyText.filtersHeader}</Text>
        </Box>

        {/* Vendor */}
        <Flex direction="column" justifyContent="space-evenly">
          <Flex
            alignItems="center"
            paddingVertical={theme.space_sm}
            paddingLeft={theme.space_md}
          >
            <Text marginRight={theme.space_sm}>
              {copyText.recNotificationsVendor}
            </Text>
            <Button
              iconEnd={<Icon icon={faEdit}></Icon>}
              secondary
              size="tiny"
              type="button"
              onClick={() => {
                mergeState({
                  isUpdatingVendor: !state.isUpdatingVendor,
                });
              }}
            ></Button>
          </Flex>
          {state.isUpdatingVendor && (
            <Box
              height={250}
              paddingLeft={theme.space_md}
              marginBottom={theme.space_sm}
            >
              <DualListbox
                isLoading={props.isLoadingDimensionValueMap}
                options={vendorOptions}
                selectedOptions={props.recNotificationConfig.vendors}
                onChange={(selectedOptions) => {
                  props.onChange({
                    target: {
                      name: "vendors",
                      value: JSON.stringify(selectedOptions),
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
              />
            </Box>
          )}

          {/* Sub account IDs  */}
          <Flex
            alignItems="center"
            paddingLeft={theme.space_md}
            paddingVertical={theme.space_sm}
          >
            <Text marginRight={theme.space_sm}>
              {copyText.recNotificationsSubAccounts}
            </Text>
            <Button
              iconEnd={<Icon icon={faEdit}></Icon>}
              secondary
              size="tiny"
              type="button"
              onClick={() => {
                mergeState({
                  isUpdatingSubAccounts: !state.isUpdatingSubAccounts,
                });
              }}
            ></Button>
          </Flex>
          {state.isUpdatingSubAccounts && (
            <Box
              height={250}
              marginBottom={theme.space_sm}
              paddingLeft={theme.space_md}
            >
              <DualListbox
                isLoading={props.isLoadingDimensionValueMap}
                options={projectIDOptions}
                selectedOptions={props.recNotificationConfig.subAccountIDs}
                onChange={(selectedOptions) => {
                  props.onChange({
                    target: {
                      name: "subAccounts",
                      value: JSON.stringify(selectedOptions),
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
              />
            </Box>
          )}

          {/* Services  */}
          <Flex
            alignItems="center"
            paddingLeft={theme.space_md}
            paddingVertical={theme.space_sm}
          >
            <Text marginRight={theme.space_sm}>
              {copyText.recNotificationsServices}
            </Text>
            <Button
              iconEnd={<Icon icon={faEdit}></Icon>}
              secondary
              size="tiny"
              type="button"
              onClick={() => {
                mergeState({
                  isUpdatingServices: !state.isUpdatingServices,
                });
              }}
            ></Button>
          </Flex>
          {state.isUpdatingServices && (
            <Box height={250} paddingLeft={theme.space_md}>
              <DualListbox
                isLoading={props.isLoadingDimensionValueMap}
                options={serviceTypeOptions}
                selectedOptions={props.recNotificationConfig.services}
                onChange={(selectedOptions) => {
                  props.onChange({
                    target: {
                      name: "services",
                      value: JSON.stringify(selectedOptions),
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
              />
            </Box>
          )}
        </Flex>

        <Flex alignItems="center" marginTop={theme.space_jumbo}>
          <Text appearance="h4">
            {copyText.recNotificationsRecommendationSummary}
          </Text>
          <Tooltip
            content={copyText.recNotificationSummaryInfoTooltipCaption}
            icon={faInfoCircle}
            width="250px"
          />
        </Flex>

        {/* Cadence */}
        <Box marginTop={theme.space_lg} marginBottom={theme.space_sm}>
          <Text appearance="h4">{copyText.cadenceHeader}</Text>
        </Box>

        <Flex
          marginHorizontal={theme.space_md}
          justifyContent="space-evenly"
          width={"80%"}
        >
          <Flex>
            <Switch
              name="enableRecsDaily"
              checked={props.recNotificationConfig.enableRecsDaily}
              onChange={(checked) =>
                props.onChange({
                  target: {
                    name: "enableRecsDaily",
                    value: String(checked),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
            <Box marginHorizontal={theme.space_xs}>
              <Text fontSize={theme.fontSize_ui}>
                {copyText.recNotificationsDaily}
              </Text>
            </Box>
          </Flex>

          <Flex>
            <Switch
              name="enableRecsWeekly"
              checked={props.recNotificationConfig.enableRecsWeekly}
              onChange={(checked) =>
                props.onChange({
                  target: {
                    name: "enableRecsWeekly",
                    value: String(checked),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
            <Box marginHorizontal={theme.space_xs}>
              <Text fontSize={theme.fontSize_ui}>
                {copyText.recNotificationsWeekly}
              </Text>
            </Box>
          </Flex>

          <Flex>
            <Switch
              name="enableRecsMonthly"
              checked={props.recNotificationConfig.enableRecsMonthly}
              onChange={(checked) =>
                props.onChange({
                  target: {
                    name: "enableRecsMonthly",
                    value: String(checked),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
            <Box marginHorizontal={theme.space_xs}>
              <Text fontSize={theme.fontSize_ui}>
                {copyText.recNotificiationsMonthly}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" marginTop={theme.space_jumbo}>
        <Button secondary marginRight={"1rem"} onClick={props.onClose}>
          {copyText.cancelButtonLabel}
        </Button>
        <Button primary width={100} onClick={props.onClose}>
          {copyText.saveButtonLabel}
        </Button>
      </Flex>
    </Flex>
  );
}
