import paths from "@/constants/paths";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Dropdown from "@/ui-lib/components/Dropdown";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faChartLine,
  faCheck,
  faEllipsisH,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  DashboardScope,
  DataSource,
  DurationType,
  ReportScope,
  WidgetType,
} from "@ternary/api-lib/constants/enums";
import systemUser, {
  SYSTEM_TENANT_ID,
} from "@ternary/api-lib/constants/system";
import {
  DashboardEntity,
  ReportEntity,
  WidgetSpec,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import {
  getDashboardDateRange,
  getDateRangeFromDashboard,
  getReportsWithModifiedDates,
} from "@ternary/api-lib/utils/ReportUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import NameInputModal from "../../../components/NameInputModal";
import useAvailableGlobalDate, {
  GlobalDateResult,
} from "../../../hooks/useAvailableGlobalDate";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls";
import { DateRange } from "../../../utils/dates";
import useGetBudgetsByTenantID from "../../budget-management/hooks/useGetBudgetsByTenantID";
import copyText from "../copyText";
import useCreateDashboard from "../hooks/useCreateDashboard";
import useCreateSavingsOpportunityFilter from "../hooks/useCreateSavingsOpportunityFilter";
import useDeleteDashboard from "../hooks/useDeleteDashboard";
import useDeleteSavingsOpportunityFilter from "../hooks/useDeleteSavingsOpportunityFilter";
import useGetDashboardByID from "../hooks/useGetDashboardByID";
import useGetDashboardsByTenantID from "../hooks/useGetDashboardsByTenantID";
import useGetReportsByTenantID from "../hooks/useGetReportsByTenantID";
import useGetSavingsOpportunityFiltersByTenantID from "../hooks/useGetSavingsOpportunityFiltersByTenantID";
import useUpdateDashboard from "../hooks/useUpdateDashboard";
import useUpdateSavingsOpportunityFilter from "../hooks/useUpdateSavingsOpportunityFilter";
import { formatWidgetSpec, getDurationTypeFromDashboard } from "../utils";
import BudgetListModal from "./BudgetListModal";
import BudgetViewContainer from "./BudgetViewContainer";
import DashboardFormModal from "./DashboardFormModal";
import DashboardSubscriptionContainer from "./DashboardSubscriptionContainer";
import RealizedCommitmentSavingsContainer from "./RealizedCommitmentSavingsContainer";
import ReportGrid from "./ReportGrid";
import ReportListModal from "./ReportListModal";
import ReportViewContainer from "./ReportViewContainer";
import SavingsOpportunityForm, { Action } from "./SavingsOpportunityForm";
import SavingsOpportunityListModal from "./SavingsOpportunityListModal";
import { SavingsOpportunityViewContainer } from "./SavingsOpportunityViewContainer";

export const MAX_ALLOWED_WIDGETS = 16;

const MODAL_ADD_BUDGET = "MODAL_ADD_BUDGET";
const MODAL_ADD_SAVING_OPP = "MODAL_ADD_SAVING_OPP";
const MODAL_CREATE_DASHBOARD = "MODAL_CREATE_DASHBOARD";
const MODAL_DELETE_BUDGET = "MODAL_DELETE_BUDGET";
const MODAL_DELETE_DASHBOARD = "MODAL_DELETE_DASHBOARD";
const MODAL_DELETE_SAVING_OPP_FILTER = "MODAL_DELETE_SAVING_OPP_FILTER";
const MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS =
  "MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS";
const MODAL_DELETE_REPORT = "MODAL_DELETE_REPORT";
const MODAL_DELETE_SAVING_OPP = "MODAL_DELETE_SAVING_OPP";
const MODAL_EDIT_DASHBOARD = "MODAL_EDIT_DASHBOARD";
const MODAL_LIST_REPORTS = "MODAL_LIST_REPORTS";
const MODAL_UPDATE_SAVING_OPP = "MODAL_UPDATE_SAVING_OPP";
const MODAL_SUBSCRIBE_DASHBOARD = "MODAL_SUBSCRIBE_DASHBOARD";

type Interaction =
  | BudgetViewContainer.Interaction
  | BudgetListModal.Interaction
  | RealizedCommitmentSavingsContainer.Interaction
  | ReportGrid.Interaction
  | ReportListModal.Interaction
  | ReportViewContainer.Interaction
  | SavingsOpportunityForm.Interaction
  | SavingsOpportunityListModal.Interaction
  | SavingsOpportunityViewContainer.Interaction;

interface State {
  actionPanelKey: Action | null;
  dateRange: DateRange | null;
  durationType: DurationType;
  editLayout: boolean;
  isHiddenFilters: boolean;
  invoiceMonthRange: DateRange | null;
  modalKey: string;
  widgetSpecs: WidgetSpec[];
  selectedSavingOppID: string | null;
  selectedWidgetIndex: number;
}

const initialState: State = {
  actionPanelKey: null,
  dateRange: null,
  durationType: DurationType.LAST_THIRTY_DAYS,
  editLayout: false,
  modalKey: "",
  widgetSpecs: [],
  selectedSavingOppID: null,
  selectedWidgetIndex: -1,
  isHiddenFilters: true,
  invoiceMonthRange: null,
};

export function DashboardViewContainer(): JSX.Element {
  const { dashboardID = "" } = useParams();

  const authenticatedUser = useAuthenticatedUser();
  const navigate = useNavigateWithSearchParams();
  const globalDate = useAvailableGlobalDate();

  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const gatekeeper = useGatekeeper();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: dashboard,
    isFetching: isFetchingDashboard,
    isLoading: isLoadingDashboard,
    refetch: refetchDashboard,
  } = useGetDashboardByID(dashboardID, authenticatedUser.tenant.fsDocID);

  const { data: dashboards = [], isLoading: isLoadingDashboards } =
    useGetDashboardsByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: _reports = [], isLoading: isLoadingReports } =
    useGetReportsByTenantID(authenticatedUser.tenant.id);

  const { data: budgets, isLoading: isLoadingBudgets } =
    useGetBudgetsByTenantID(authenticatedUser.tenant.fsDocID);

  const {
    data: filters,
    isLoading: isLoadingFilters,
    refetch: refetchFilters,
  } = useGetSavingsOpportunityFiltersByTenantID(authenticatedUser.tenant.id);

  const { data: users = [] } = useGetUsersByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  //
  // Mutations
  //

  const { isPending: isCreatingDashboard, mutate: createDashboard } =
    useCreateDashboard({
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorCreatingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (dashboardID) => {
        navigate(paths._dashboard.replace(":dashboardID", dashboardID));

        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.SUCCESS_DASHBOARD_CREATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isCreatingSavingsOpportunityFilter,
    mutate: createSavingsOpportunityFilter,
  } = useCreateSavingsOpportunityFilter({
    onError: () => {
      mergeState({ actionPanelKey: null, modalKey: "" });
      postAlert({
        message: copyText.errorCreatingSavingOpportunityMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (filterID) => {
      refetchFilters();

      const widgetSpec = formatWidgetSpec(
        filterID,
        WidgetType.SAVINGS_OPPORTUNITY_FILTER,
        state.widgetSpecs
      );

      setState((currentState) => {
        return {
          ...currentState,
          actionPanelKey: null,
          selectedSavingOppID: null,
          modalKey: "",
          ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
            ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
            : {}),
        };
      });
      postAlert({
        message: copyText.SUCCESS_SAVING_OPP_CREATED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdateSavingsOpportunityFilter,
    mutate: updateSavingsOpportunityFilter,
  } = useUpdateSavingsOpportunityFilter({
    onError: () => {
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });
      postAlert({
        message: copyText.errorUpdateSavingOpportunityMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      refetchFilters();
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });

      postAlert({
        message: copyText.SUCCESS_SAVING_OPP_UPDATED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isDeleteSavingsOpportunityFilter,
    mutate: deleteSavingsOpportunityFilter,
  } = useDeleteSavingsOpportunityFilter({
    onError: () => {
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });
      postAlert({
        message: copyText.errorDeletingSavingOpportunityMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (filterID) => {
      refetchFilters();
      if (state.selectedWidgetIndex === -1) {
        const updateWidgetSpecs = state.widgetSpecs.filter(
          (widgetSpecs) => widgetSpecs.resourceID !== filterID
        );
        setState((currentState) => ({
          ...currentState,
          actionPanelKey: null,
          modalKey: "",
          selectedSavingOppID: null,
          widgetSpecs: updateWidgetSpecs,
        }));
      }
      handleDeleteWidget();
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });
      postAlert({
        message: copyText.SUCCESS_SAVING_OPP_DELETED_message,
        type: AlertType.SUCCESS,
      });
    },
  });
  const { isPending: isDeletingDashboard, mutate: deleteDashboard } =
    useDeleteDashboard({
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorDeletingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (dashboardID) => {
        queryClient.setQueryData<DashboardEntity[]>(
          ["dashboards"],
          (dashboards = []) =>
            dashboards.filter((dashboard) => dashboard.id !== dashboardID)
        );

        navigate(paths._dashboards);

        postAlert({
          message: copyText.SUCCESS_DASHBOARD_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isError: isErrorUpdatingDashboard,
    isPending: isUpdatingDashboard,
    mutate: updateDashboard,
    reset: resetUpdateDashboard,
  } = useUpdateDashboard({
    retry: false,
    onError: () => {
      mergeState({ modalKey: "" });
      refetchDashboard();
      postAlert({
        message: copyText.errorUpdatingDashboardMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "" });
      refetchDashboard();
    },
  });

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy([...users, systemUser], "id");

  const reports = _reports.map((report) => {
    const createdByUser = usersKeyedByID[report.createdByID];
    const updatedByUser = usersKeyedByID[report.updatedByID ?? ""];

    return {
      ...report,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const modifiedReports = getModifiedReports(reports, state, globalDate);

  const selectedReports = modifiedReports.filter((report) =>
    state.widgetSpecs
      .filter((spec) => spec.widgetType === WidgetType.REPORT)
      .some((reportSpec) => reportSpec.resourceID === report.id)
  );

  const isEcoMode = selectedReports.some(
    (report) => report.dataSource === DataSource.CARBON_FOOTPRINT
  );

  //
  // Side Effects
  //

  // NOTE: This is to account for the multitenant case where you could directly
  // set the URL with a dashboard from a different tenant that you have access to.
  useEffect(() => {
    if (!dashboard) return;

    if (
      authenticatedUser.tenant.fsDocID !== dashboard.tenantDocID &&
      dashboard.scope !== DashboardScope.GLOBAL
    ) {
      navigate(paths._dashboards);
    }
  }, [dashboard]);

  // Load existing dashboard into state
  useEffect(() => {
    if (!dashboard) return;

    mergeState({
      dateRange:
        dashboard.durationType === DurationType.CUSTOM
          ? getDateRangeFromDashboard(dashboard)
          : null,
      durationType: getDurationTypeFromDashboard(
        dashboard,
        isEcoMode,
        globalDate.enabled ? globalDate : null
      ),
      isHiddenFilters: dashboard.durationType === null,
      invoiceMonthRange:
        dashboard.invoiceMonthRange &&
        dashboard.durationType === DurationType.INVOICE
          ? getDateRangeFromDashboard(dashboard)
          : null,
      widgetSpecs: dashboard.widgetSpecs,
    });
  }, [dashboardID, dashboard === undefined]);

  // Autosave
  useEffect(() => {
    const changeSet = getChangeSet(dashboard, state);

    if (
      state === initialState ||
      isUpdatingDashboard ||
      isFetchingDashboard ||
      Object.keys(changeSet).length === 0
    ) {
      return;
    }

    updateDashboard({
      dashboardID: dashboardID,
      params: changeSet,
    });
  }, [dashboard, dashboardID, isFetchingDashboard, isUpdatingDashboard, state]);

  // Recover from autosave error
  useEffect(() => {
    if (!dashboard || !isErrorUpdatingDashboard || isFetchingDashboard) {
      return;
    }

    // sets isErrorUpdatingDashboard back to false
    resetUpdateDashboard();

    mergeState({
      dateRange:
        dashboard.durationType === DurationType.CUSTOM
          ? getDateRangeFromDashboard(dashboard)
          : null,
      durationType: getDurationTypeFromDashboard(
        dashboard,
        isEcoMode,
        globalDate.enabled ? globalDate : null
      ),
      isHiddenFilters: dashboard.durationType === null,
      invoiceMonthRange:
        dashboard.invoiceMonthRange &&
        dashboard.durationType === DurationType.INVOICE
          ? getDateRangeFromDashboard(dashboard)
          : null,
      widgetSpecs: dashboard.widgetSpecs,
    });
  }, [dashboard, globalDate, isErrorUpdatingDashboard, isFetchingDashboard]);

  //
  // Interaction Handlers
  //

  function handleAddRealizedSavings() {
    setState((currentState) => {
      const widgetSpec = formatWidgetSpec(
        WidgetType.REALIZED_COMMITMENT_SAVINGS,
        WidgetType.REALIZED_COMMITMENT_SAVINGS,
        currentState.widgetSpecs
      );

      return {
        ...currentState,
        actionPanelKey: null,
        modalKey: "",
        ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
          ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
          : {}),
      };
    });
  }

  function handleCreateDashboard(name: string): void {
    if (!dashboard) return;

    const widgetSpecs = state.widgetSpecs.map((spec) => {
      return {
        resourceID: spec.resourceID,
        height: spec.height,
        widgetType: spec.widgetType,
        width: spec.width,
        xCoordinate: spec.xCoordinate,
        yCoordinate: spec.yCoordinate,
      };
    });

    createDashboard({
      tenantID: authenticatedUser.tenant.fsDocID,
      name,
      dateRange:
        state.dateRange && state.durationType === DurationType.CUSTOM
          ? getDashboardDateRange(state.dateRange, state.durationType)
          : undefined,
      durationType: !state.isHiddenFilters ? state.durationType : undefined,
      invoiceMonthRange:
        state.invoiceMonthRange && state.durationType === DurationType.INVOICE
          ? getDashboardDateRange(state.invoiceMonthRange, state.durationType)
          : undefined,
      widgetSpecs,
      scope: DashboardScope.SHARED,
    });
  }

  function handleDeleteDashboard(): void {
    deleteDashboard({ dashboardID: dashboardID });
  }

  function handleDeleteWidget(): void {
    if (state.selectedWidgetIndex === -1) return;

    setState((currentState) => ({
      ...currentState,
      widgetSpecs: [
        ...currentState.widgetSpecs.slice(0, state.selectedWidgetIndex),
        ...currentState.widgetSpecs.slice(state.selectedWidgetIndex + 1),
      ],
    }));

    mergeState({ modalKey: "" });
  }

  function handleUpdateDashboard(params: {
    name: string;
    tags: string[];
  }): void {
    updateDashboard({
      dashboardID: dashboardID,
      params: { name: params.name, tags: params.tags },
    });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case BudgetViewContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_BUDGET,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case BudgetViewContainer.INTERACTION_BUDGET_NAME_CLICKED: {
        const { budgetID } = interaction;

        navigate(paths._budgets, { searchParams: { budget_id: budgetID } });
        return;
      }
      case BudgetListModal.INTERACTION_ADD_BUDGET_CLICKED: {
        const widgetSpec = formatWidgetSpec(
          interaction.budgetID,
          interaction.widgetType,
          state.widgetSpecs
        );

        setState((currentState) => {
          return {
            ...currentState,
            ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });
        return;
      }
      case RealizedCommitmentSavingsContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED: {
        mergeState({ widgetSpecs: interaction.widgetSpecs });
        return;
      }
      case ReportListModal.INTERACTION_ROW_CLICKED: {
        const newReport = reports?.find(
          (report) => report.id === interaction.reportID
        );

        const isEcoMode = newReport?.dataSource === DataSource.CARBON_FOOTPRINT;

        const widgetSpec = formatWidgetSpec(
          interaction.reportID,
          WidgetType.REPORT,
          state.widgetSpecs
        );

        setState((currentState) => {
          const hasHiddenOptions = isEcoMode
            ? hiddenEcoModeOptions.some(
                (option) => option === currentState.durationType
              )
            : false;

          return {
            ...currentState,
            durationType: hasHiddenOptions
              ? DurationType.LAST_NINETY_DAYS
              : currentState.durationType,
            ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });

        return;
      }
      case ReportViewContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_REPORT,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED: {
        const { reportID, report } = interaction;

        navigate(paths._report.replace(":reportID", reportID), {
          state: {
            report,
          },
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_SAVINGS_ROW_CLICKED: {
        const widgetSpec = formatWidgetSpec(
          interaction.filterID,
          WidgetType.SAVINGS_OPPORTUNITY_FILTER,
          state.widgetSpecs
        );

        setState((currentState) => {
          return {
            ...currentState,
            actionPanelKey: null,
            modalKey: "",
            ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_ADD_SAVINGS_OPP_CLICKED: {
        mergeState({ actionPanelKey: Action.CREATE });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_BACK_BUTTON_CLICKED: {
        mergeState({ actionPanelKey: null });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: null,
          modalKey: "",
          selectedSavingOppID: null,
          selectedWidgetIndex: -1,
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_SAVING_OPP_FILTER,
          selectedSavingOppID: interaction.filterID,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case SavingsOpportunityViewContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_SAVING_OPP,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_UPDATE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE_SAVING_OPP,
          actionPanelKey: Action.UPDATE,
          selectedSavingOppID: interaction.filterID,
        });
        return;
      }
      case SavingsOpportunityViewContainer.INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE_SAVING_OPP,
          actionPanelKey: Action.UPDATE,
          selectedSavingOppID: interaction.savingsOpportunityFilterID,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }

      case SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createSavingsOpportunityFilter({
          tenantID: interaction.isConfirmingSystemSavingOpp
            ? SYSTEM_TENANT_ID
            : authenticatedUser.tenant.id,
          category: interaction.categoryInput,
          cloudProviderType: interaction.cloudProviderTypeInput,
          name: interaction.nameInput,
          savingsType: interaction.savingsTypeInput,
          serviceType: interaction.serviceTypeInput,
        });
        return;
      }
      case SavingsOpportunityForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: null,
          modalKey: "",
          selectedWidgetIndex: -1,
        });
        return;
      }
      case SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        updateSavingsOpportunityFilter({
          filterID: interaction.savingOpportunityFilterID,
          category: interaction.category,
          cloudProviderType: interaction.cloudProviderType,
          name: interaction.name,
          savingsType: interaction.savingsType,
          serviceType: interaction.serviceType,
        });

        return;
      }
    }
  }
  //
  // Render
  //

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_ADD_BUDGET: {
        return (
          <BudgetListModal
            isLoading={isLoadingBudgets}
            budgets={budgets ?? []}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_CREATE_DASHBOARD: {
        const name = dashboard
          ? `${dashboard.name} (${copyText.dashboardNameCopyLabel})`
          : undefined;

        return (
          <NameInputModal
            isProcessing={isCreatingDashboard}
            name={name}
            title={copyText.modalTitleCreateDashboard}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleCreateDashboard}
          />
        );
      }
      case MODAL_DELETE_BUDGET: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeBudgetConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_DASHBOARD: {
        return (
          <ConfirmationModal
            isLoading={isDeletingDashboard}
            message={copyText.deleteDashboardConfirmationMessage}
            title={copyText.deleteDashboardConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteDashboard}
          />
        );
      }
      case MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeRealizedSavingsConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_REPORT: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeReportConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_EDIT_DASHBOARD: {
        const existingTags = dashboards.reduce((accum: string[], dashboard) => {
          dashboard.tags.forEach((tag) => {
            if (!accum.includes(tag)) {
              accum.push(tag);
            }
          });
          return accum;
        }, []);

        return (
          <DashboardFormModal
            isProcessing={isUpdatingDashboard || isLoadingDashboards}
            existingTags={existingTags}
            tags={dashboard?.tags ?? []}
            title={copyText.modalTitleEditDashboard}
            name={dashboard?.name}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleUpdateDashboard}
          />
        );
      }
      case MODAL_LIST_REPORTS: {
        let filteredReports = reports ?? [];

        filteredReports =
          dashboard?.scope === DashboardScope.GLOBAL
            ? filteredReports.filter(
                (report) => report.scope === ReportScope.GLOBAL
              )
            : filteredReports;
        return (
          <ReportListModal
            isLoading={isLoadingReports}
            reports={filteredReports}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_ADD_SAVING_OPP: {
        let filteredSavingsOpp = filters ?? [];

        filteredSavingsOpp =
          dashboard?.scope === DashboardScope.GLOBAL
            ? filteredSavingsOpp.filter(
                (savingsOpp) => savingsOpp.tenantID === SYSTEM_TENANT_ID
              )
            : filteredSavingsOpp;

        return (
          <SavingsOpportunityListModal
            actionPanelKey={state.actionPanelKey}
            isLoading={isLoadingFilters}
            filters={filteredSavingsOpp ?? []}
            selectedWidgetIndex={state.selectedWidgetIndex}
            selectedSavingOppID={state?.selectedSavingOppID}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_UPDATE_SAVING_OPP: {
        return (
          <SavingsOpportunityListModal
            actionPanelKey={state.actionPanelKey}
            isLoading={isLoadingFilters}
            filters={filters ?? []}
            selectedSavingOppID={state?.selectedSavingOppID}
            selectedWidgetIndex={state.selectedWidgetIndex}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_DELETE_SAVING_OPP: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeSavingOppConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_SAVING_OPP_FILTER: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeSavingOppFilterConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({
                actionPanelKey: null,
                modalKey: "",
                selectedSavingOppID: null,
                selectedWidgetIndex: -1,
              })
            }
            onConfirm={() =>
              deleteSavingsOpportunityFilter({
                filterID: state.selectedSavingOppID ?? "",
              })
            }
          />
        );
      }
      case MODAL_SUBSCRIBE_DASHBOARD: {
        if (dashboard) {
          return (
            <DashboardSubscriptionContainer
              isLoadingDashboard={isLoadingDashboard}
              dashboard={dashboard}
              users={users}
              refetch={refetchDashboard}
              onClose={() => mergeState({ modalKey: "" })}
            />
          );
        }
        return null;
      }
      default:
        return null;
    }
  }

  const options = [
    {
      label: copyText.actionMenuItemEditDashboard,
      locked: !canUpdateGlobalDashboard(),
      onClick: () => mergeState({ modalKey: MODAL_EDIT_DASHBOARD }),
    },
    {
      label: copyText.actionMenuItemeditDashboardLayout,
      locked:
        dashboard?.widgetSpecs.length === 0 || !canUpdateGlobalDashboard(),
      onClick: () => mergeState({ editLayout: true }),
    },
    {
      label: copyText.actionMenuItemDeleteDashboard,
      locked:
        !dashboard?.id ||
        !gatekeeper.getCanDeleteSpecificDashboard(dashboard.createdBy),
      onClick: () => mergeState({ modalKey: MODAL_DELETE_DASHBOARD }),
    },
    {
      label: copyText.actionMenuItemSubscribeToDashboard,
      locked: !gatekeeper.canUpdateDashboards,
      onClick: () => mergeState({ modalKey: MODAL_SUBSCRIBE_DASHBOARD }),
    },
  ];

  const addWidgetLocked =
    state.widgetSpecs.length >= MAX_ALLOWED_WIDGETS ||
    state.editLayout ||
    !gatekeeper.canUpdateDashboards ||
    !canUpdateGlobalDashboard();

  const addWidgetOptions = [
    {
      label: copyText.actionMenuItemAddReport,
      locked: addWidgetLocked,
      onClick: () => mergeState({ modalKey: MODAL_LIST_REPORTS }),
    },
    {
      label: copyText.addBudget,
      locked:
        addWidgetLocked ||
        (dashboard && dashboard.scope === DashboardScope.GLOBAL),
      onClick: () => mergeState({ modalKey: MODAL_ADD_BUDGET }),
    },
    {
      label: copyText.addSavingOpportunity,
      locked: addWidgetLocked,
      onClick: () => mergeState({ modalKey: MODAL_ADD_SAVING_OPP }),
    },
    {
      label: copyText.addSavingsRealized,
      locked: addWidgetLocked,
      onClick: () => handleAddRealizedSavings(),
    },
  ];

  function canUpdateGlobalDashboard(): boolean {
    if (dashboard && dashboard.scope === DashboardScope.GLOBAL) {
      return (
        gatekeeper.canUpdateDashboards && gatekeeper.canEditGlobalDashboards
      );
    } else {
      return gatekeeper.canUpdateDashboards;
    }
  }

  const hiddenEcoModeOptions = [
    DurationType.LAST_MONTH,
    DurationType.YESTERDAY,
    DurationType.LAST_SEVEN_DAYS,
    DurationType.LAST_THIRTY_DAYS,
    DurationType.MONTH_TO_DATE,
    DurationType.QUARTER_TO_DATE,
  ];

  return (
    <Box minWidth={1000}>
      {state.modalKey && renderModal()}
      <Flex justifyContent="space-between" marginBottom={theme.space_xs}>
        <Text fontSize={theme.h3_fontSize}>{dashboard?.name}</Text>
        <Flex>
          <AutosaveIndicator
            key={dashboard?.id}
            isSaving={isUpdatingDashboard}
          />

          <Button
            disabled={state.widgetSpecs.length === 0 || state.editLayout}
            locked={!gatekeeper.canCreateDashboards}
            marginHorizontal={theme.space_sm}
            primary={state.widgetSpecs.length !== 0}
            secondary={state.widgetSpecs.length === 0}
            size="small"
            onClick={() => mergeState({ modalKey: MODAL_CREATE_DASHBOARD })}
          >
            {copyText.saveAsButtonLabel}
          </Button>

          <Button
            disabled={state.editLayout}
            locked={!gatekeeper.canUpdateDashboards}
            marginRight={theme.space_sm}
            primary={state.widgetSpecs.length !== 0}
            secondary={state.widgetSpecs.length === 0}
            size="small"
            onClick={() =>
              setState((currentState) => ({
                ...currentState,
                isHiddenFilters: !currentState.isHiddenFilters,
              }))
            }
          >
            {state.isHiddenFilters
              ? copyText.dashboardFilterButtonLabel
              : copyText.removeFilterButtonLabel}
          </Button>

          <Dropdown
            disabled={addWidgetLocked}
            options={addWidgetOptions}
            placement="bottom-end"
          >
            <Button
              disabled={addWidgetLocked}
              locked={!gatekeeper.canUpdateDashboards}
              marginRight={theme.space_sm}
              primary={state.widgetSpecs.length < MAX_ALLOWED_WIDGETS}
              secondary={state.widgetSpecs.length === MAX_ALLOWED_WIDGETS}
              size="small"
            >
              {copyText.addWidgetButtonLabel}
            </Button>
          </Dropdown>

          {state.editLayout ? (
            <Button
              primary
              size="small"
              onClick={() => mergeState({ editLayout: false })}
            >
              {copyText.actionClose}
            </Button>
          ) : (
            <Dropdown options={options} placement="bottom-end">
              <Button
                iconStart={<Icon icon={faEllipsisH} />}
                primary
                size="small"
              />
            </Dropdown>
          )}
        </Flex>
      </Flex>

      {!state.isHiddenFilters && (
        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          justifyContent="right"
          padding={theme.space_md}
        >
          <DateRangeControls
            dateRange={
              state.durationType === DurationType.INVOICE
                ? state.invoiceMonthRange
                : state.dateRange
            }
            durationType={state.durationType}
            hiddenOptions={
              isEcoMode ? hiddenEcoModeOptions : [DurationType.QUARTER_TO_DATE]
            }
            isFiscalMode={authenticatedUser.settings.fiscalMode}
            onChangeDateRange={(durationType, dateRange) => {
              if (durationType === DurationType.INVOICE) {
                mergeState({
                  dateRange: null,
                  durationType,
                  invoiceMonthRange: dateRange,
                });
              } else {
                mergeState({
                  dateRange,
                  durationType,
                  invoiceMonthRange: null,
                });
              }
            }}
          />
        </Flex>
      )}
      {!dashboard || !reports || state.widgetSpecs.length === 0 ? (
        <Box height={800} marginTop={theme.space_lg}>
          <EmptyPlaceholder
            icon={faChartLine}
            loading={
              isCreatingDashboard ||
              isLoadingDashboard ||
              isLoadingReports ||
              isLoadingFilters ||
              isCreatingSavingsOpportunityFilter ||
              isUpdateSavingsOpportunityFilter ||
              isDeleteSavingsOpportunityFilter
            }
            text={copyText.emptyReports}
            skeletonVariant="cards_large"
          />
        </Box>
      ) : (
        <ReportGrid
          budgets={budgets ?? []}
          editLayout={state.editLayout}
          reports={selectedReports}
          filters={filters ?? []}
          widgetSpecs={state.widgetSpecs}
          onInteraction={handleInteraction}
        />
      )}
    </Box>
  );
}

type AutosaveIndicatorProps = {
  isSaving: boolean;
};

function AutosaveIndicator(props: AutosaveIndicatorProps) {
  const [hasSaved, setHasSaved] = useState(false);
  const theme = useTheme();

  if (props.isSaving && !hasSaved) {
    setHasSaved(true);
  }

  if (!props.isSaving && !hasSaved) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      paddingHorizontal={theme.space_sm}
      transition="none"
    >
      <Text color={theme.text_color_secondary}>
        {props.isSaving
          ? copyText.dashboardAutosaveSaving
          : copyText.dashboardAutosaveChangesSaved}
      </Text>

      <Box color={theme.text_color_secondary} marginLeft={theme.space_sm}>
        {props.isSaving ? <Icon icon={faRotate} /> : <Icon icon={faCheck} />}
      </Box>
    </Flex>
  );
}

function getChangeSet(dashboard: DashboardEntity | undefined, state: State) {
  if (!dashboard) return {};

  const pendingChanges = state.isHiddenFilters
    ? {
        dateRange: null,
        durationType: null,
        isHiddenFilters: state.isHiddenFilters,
        invoiceMonthRange: null,
        widgetSpecs: state.widgetSpecs,
      }
    : {
        dateRange:
          state.durationType === DurationType.CUSTOM && state.dateRange
            ? getDashboardDateRange(state.dateRange, state.durationType)
            : null,
        durationType: state.durationType,
        isHiddenFilters: state.isHiddenFilters,
        invoiceMonthRange:
          state.invoiceMonthRange && state.durationType === DurationType.INVOICE
            ? getDashboardDateRange(state.invoiceMonthRange, state.durationType)
            : null,
        widgetSpecs: state.widgetSpecs.map((spec) => ({
          resourceID: spec.resourceID,
          height: spec.height,
          widgetType: spec.widgetType,
          width: spec.width,
          xCoordinate: spec.xCoordinate,
          yCoordinate: spec.yCoordinate,
        })),
      };

  const dateRangeChanged = !isEqual(
    dashboard.dateRange,
    pendingChanges.dateRange
  );

  const invoiceMonthRangeChanged = !isEqual(
    dashboard.invoiceMonthRange,
    pendingChanges.invoiceMonthRange
  );

  const widgetSpecsChanged = !isEqual(dashboard.widgetSpecs, state.widgetSpecs);

  const changeSet = {
    ...(dateRangeChanged ? { dateRange: pendingChanges.dateRange } : {}),
    ...(dashboard.durationType !== pendingChanges.durationType
      ? { durationType: pendingChanges.durationType }
      : {}),
    ...(invoiceMonthRangeChanged
      ? { invoiceMonthRange: pendingChanges.invoiceMonthRange }
      : {}),
    ...(widgetSpecsChanged ? { widgetSpecs: pendingChanges.widgetSpecs } : {}),
  };

  return changeSet;
}

export function getModifiedReports(
  reports: ReportEntity[] | undefined,
  state: State,
  globalDate: GlobalDateResult
): ReportEntity[] {
  if (state.isHiddenFilters && !globalDate.enabled) {
    return reports ?? [];
  }

  if (!globalDate.enabled) {
    return getReportsWithModifiedDates(reports, {
      dateRange: state.dateRange,
      durationType: state.durationType,
      invoiceMonthRange: state.invoiceMonthRange,
    });
  }

  return getReportsWithModifiedDates(reports, {
    dateRange: globalDate.date,
    durationType: globalDate.durationType,
    invoiceMonthRange: null,
  });
}

export default DashboardViewContainer;
