import externalLinks from "@/constants/externalLinks";
import paths from "@/constants/paths";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import { useTheme } from "@emotion/react";
import {
  faAngleLeft,
  faLock,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import copyText from "../copyText";
import useGenerateTakeoutReport from "../hooks/useGenerateTakeoutReport";
import useGetBillsByTenantID from "../hooks/useGetBillsByTenantID";
import SavingsBreakdownMeter from "./SavingsBreakdownMeter";
import SavingsBreakdownTable from "./SavingsBreakdownTable";

export default function CostCompareTakeoutContainer(): JSX.Element {
  const { billID = "" } = useParams();

  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // Queries
  //

  const { data: bills = [], isLoading: isLoadingBills } = useGetBillsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const { data: takeoutReport, isLoading: isGeneratingTakeoutReport } =
    useGenerateTakeoutReport(
      { billID: billID, targetCloudProviderType: CloudProviderType.GCP },
      {
        enabled: billID.length > 0 && gatekeeper.canGenerateTakeoutReport,
      }
    );

  const billName = bills.find((bill) => bill.id === billID)?.name;

  //
  // Side Effects
  //

  useEffect(() => {
    if (!gatekeeper.canViewCostCompare) {
      navigate("/", { replace: true });
    }
  }, []);

  //
  // Render
  //

  if (!gatekeeper.canGenerateTakeoutReport || !gatekeeper.canListBills) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  if (
    !billName ||
    isGeneratingTakeoutReport ||
    isLoadingBills ||
    !takeoutReport
  ) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        direction="column"
        padding={theme.space_lg}
        height={625}
      >
        {isGeneratingTakeoutReport && (
          <Text
            color={theme.text_color_secondary}
            fontSize={theme.h2_fontSize}
            fontWeight={theme.h2_fontWeight}
          >
            {copyText.emptyPlaceholderTitleGeneratingReport}
          </Text>
        )}
        <Box height="50%">
          <EmptyPlaceholder
            icon={faTable}
            loading={isGeneratingTakeoutReport}
            skeletonVariant="data_processing"
            text={copyText.noBillsMessage}
          />
        </Box>
      </Flex>
    );
  }

  const coveragePercentage = formatPercentage(
    takeoutReport.annotations.coverageSummary.sourceCostConverted /
      takeoutReport.annotations.coverageSummary.totalSourceCost
  );

  const normalRecordsCSVData = getNormalRecordsCSVDataFromEntries(
    takeoutReport.normalRecords
  );

  const targetRecordsCSVData = getTargetRecordsCSVDataFromEntries(
    takeoutReport.targetRecords
  );

  return (
    <>
      <Button
        iconStart={<Icon icon={faAngleLeft} />}
        marginBottom={theme.space_sm}
        onClick={() => navigate(paths._costAssistCostCompare)}
      >
        {copyText.backButtonLabel}
      </Button>
      <Flex height={650} justifyContent="space-between">
        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          flexGrow={1}
          marginBottom={theme.space_xl}
          marginRight={theme.space_md}
          minWidth={700}
          padding={theme.space_lg}
        >
          <Flex direction="column" overflow="auto" width="100%">
            <SavingsBreakdownMeter
              billName={billName}
              coverageSummary={takeoutReport.annotations.coverageSummary}
            />
            <SavingsBreakdownTable
              annotations={takeoutReport.annotations}
              billName={billName}
            />
          </Flex>
        </Flex>
        <Flex flexGrow={1}>
          <Flex direction="column" height="100%" width="50%">
            <Flex
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              direction="column"
              marginBottom={theme.space_xl}
              padding={theme.space_lg}
              width="100%"
            >
              <Text color={theme.text_color_secondary}>
                {copyText.coverageReportHeader}
              </Text>
              <Flex justifyContent="space-between" width="100%">
                <Text>{copyText.coverageHeader}</Text>
                <Text>{coveragePercentage}</Text>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Text>{copyText.sourceCostNormalizedHeader}</Text>
                <Text>
                  {formatCurrencyRounded({
                    number:
                      takeoutReport.annotations.coverageSummary
                        .sourceCostNormalized,
                  })}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Text>{copyText.sourceCostConvertedHeader}</Text>
                <Text>
                  {formatCurrencyRounded({
                    number:
                      takeoutReport.annotations.coverageSummary
                        .sourceCostConverted,
                  })}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Text>{copyText.sourceCostUnconvertedHeader}</Text>
                <Text>
                  {formatCurrencyRounded({
                    number:
                      takeoutReport.annotations.coverageSummary
                        .sourceCostUnconverted,
                  })}
                </Text>
              </Flex>
            </Flex>
            <Flex
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              direction="column"
              flexGrow={1}
              height="50%"
              marginBottom={theme.space_xl}
              padding={theme.space_lg}
            >
              <Text color={theme.text_color_secondary}>
                {copyText.unconvertedHeader}
              </Text>
              <Flex direction="column" overflowY="scroll">
                {Object.entries(
                  takeoutReport.annotations.pivotUnconverted.byProductName
                )
                  .sort((a, b) => {
                    if (a[1].sourcePlatformCost > b[1].sourcePlatformCost) {
                      return -1;
                    }

                    if (a[1].sourcePlatformCost < b[1].sourcePlatformCost) {
                      return 1;
                    }

                    return 0;
                  })
                  .map(([key, value]) => {
                    return (
                      <Flex
                        key={key}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Text>{key}</Text>
                        <Text>
                          {formatCurrencyRounded({
                            number: value.sourcePlatformCost,
                          })}
                        </Text>
                      </Flex>
                    );
                  })}
              </Flex>
            </Flex>
          </Flex>
          <Flex height="100%" justifyContent="flex-end" width="50%">
            <Flex
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              direction="column"
              marginBottom={theme.space_xl}
              padding={theme.space_lg}
              width="95%"
            >
              <Text color={theme.text_color_secondary}>
                {copyText.dataTransparencyHeader}
              </Text>
              <Text>{copyText.dataTransparencyIntroduction}</Text>
              <Flex justifyContent="center" width="100%">
                <Flex
                  direction="column"
                  marginVertical={theme.space_sm}
                  width="100%"
                >
                  <CSVLink
                    data={takeoutReport.sourceRecords}
                    filename={`sourceRecords${billName.replace(
                      / /g,
                      ""
                    )}-${formatDate(new Date(), "MM-dd-yyyy")}`}
                  >
                    <Button
                      marginVertical={theme.space_xxs}
                      secondary
                      width="100%"
                    >
                      {copyText.csvOriginalBillButtonLabel}
                    </Button>
                  </CSVLink>
                  <CSVLink
                    data={normalRecordsCSVData.data}
                    filename={`normalRecords${billName.replace(
                      / /g,
                      ""
                    )}-${formatDate(new Date(), "MM-dd-yyyy")}`}
                  >
                    <Button
                      marginVertical={theme.space_xxs}
                      secondary
                      width="100%"
                    >
                      {copyText.csvNormalizedUsageButtonLabel}
                    </Button>
                  </CSVLink>
                  <CSVLink
                    data={targetRecordsCSVData.data}
                    filename={`targetRecords${billName.replace(
                      / /g,
                      ""
                    )}-${formatDate(new Date(), "MM-dd-yyyy")}`}
                  >
                    <Button
                      marginVertical={theme.space_xxs}
                      secondary
                      width="100%"
                    >
                      {copyText.csvTargetOutputButtonLabel}
                    </Button>
                  </CSVLink>
                </Flex>
              </Flex>
              <Text>
                {copyText.dataTransparencyLearnMorePrefix + " "}
                <a
                  href={externalLinks.zendeskCostCompareDocumentation}
                  rel="noreferrer"
                  target="_blank"
                >
                  {copyText.dataTransparencyLearnMoreLink}
                </a>
                {" " + copyText.dataTransparencyLearnMoreSuffix}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );

  type CSVData = {
    data: { [key: string]: string | number | string[] }[];
  };

  type NormalRecord = {
    itemType: string;
    quantity: number;
    serviceCategory: string;
    serviceType: string;
    trace: {
      normalizationRuleID: string;
      pricingRuleID: string;
      sourceRecordID: string;
    };
    unit: string;
    usageTags: string[];
  };

  type TargetRecord = {
    itemType: string;
    priceCost: number;
    priceNotes: string | null;
    priceProvider: string;
    priceQuantity: number;
    priceReferenceURL: string | null;
    priceTags: string[];
    priceUnit: string;
    service: string;
    serviceCategory: string;
    totalCost: number;
    trace: {
      normalizationRuleID: string;
      pricingRuleID: string;
      sourceRecordID: string;
    };
    usageQuantity: number;
    usageTags: string[];
    usageUnit: string;
  };

  function getNormalRecordsCSVDataFromEntries(
    entries: NormalRecord[]
  ): CSVData {
    if (!entries) return { data: [] };

    const data: { [key: string]: string | number | string[] }[] = entries.map(
      (entry) => {
        return {
          ...entry,
          trace: `record:${entry.trace.sourceRecordID}::norm:${entry.trace.normalizationRuleID}::price:${entry.trace.pricingRuleID}`,
        };
      }
    );

    return { data };
  }

  function getTargetRecordsCSVDataFromEntries(
    entries: TargetRecord[]
  ): CSVData {
    if (!entries) return { data: [] };

    const data: { [key: string]: string | number | string[] }[] = entries.map(
      (entry) => {
        return {
          ...entry,
          priceNotes: entry.priceNotes ?? "",
          priceReferenceURL: entry.priceReferenceURL ?? "",
          trace: `record:${entry.trace.sourceRecordID}::norm:${entry.trace.normalizationRuleID}::price:${entry.trace.pricingRuleID}`,
        };
      }
    );

    return { data };
  }
}
