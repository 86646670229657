import { useTheme } from "@emotion/react";
import {
  faExclamationTriangle,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { DateRange } from "../../../analytics/utils";
import { DurationType } from "../../../constants/enums";
import { convertDimension } from "../../../utils/ReportUtils";
import Box from "../../components/Box";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";
import Flex from "../../components/Flex";
import Icon from "../../components/Icon";
import Text from "../../components/Text";
import copyText from "../../copyText";
import { getFormatForGranularity } from "../../utils/dates";
import { formatPercentage } from "../../utils/formatNumber";
import { Dimension, Measure, RawData, RawValue, SortRule } from "../types";
import getMergeState, {
  COMPARISON_KEY,
  PERCENT_DIFFERENCE_KEY,
  RAW_DIFFERENCE_KEY,
  formatMeasureValueWithUnit,
  formatTimestamp,
} from "../utils";
import Table from "./Table";

const AVG_PIXEL_WIDTH_OF_CHARACTER = 4;
const MAX_ALLOWED_DATA_LENGTH = 50_000;
const MAX_ALLOWED_UI_ROWS = 100;

interface Props {
  creditTypes?: string[];
  compareDateRange?: DateRange;
  compareType?: DurationType | null;
  data: RawData[];
  dateRange?: DateRange;
  dimensions: Dimension[];
  footer?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  maxRows?: number;
  measures: Measure[];
  pinnedColumns?: number;
  selectedMeasures?: Measure[];
  sortable?: boolean;
  sortRule?: SortRule;
  onInteraction?: (interaction: CrossSectionalDataTable.Interaction) => void;
}

interface State {
  sortRule: SortRule | undefined;
}

const columnHelper = createColumnHelper<RawData>();

export function CrossSectionalDataTable(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>({ sortRule: props.sortRule });

  const sortRule: SortRule | undefined = props.sortRule ?? state.sortRule;
  const mergeState = getMergeState(setState);

  const isDataSetTooLarge = props.data.length > MAX_ALLOWED_DATA_LENGTH;

  //
  // Data Pivots
  //

  const rowObjects: RawData[] = useMemo(() => {
    if (isDataSetTooLarge) return [];

    return getRowObjects({
      data: props.data,
      dimensions: props.dimensions,
      measures: props.measures.filter((measure) => measure.name),
    });
  }, [props.data]);

  const maxCharacterWidthsByColumn: { [key: string]: number } = useMemo(() => {
    // NOTE: this isused to dynamically size the table columns based on content
    return getMaxCharactersByColumn(rowObjects);
  }, [rowObjects]);

  //
  // Table Setup
  //

  function getMeasureColumns() {
    // If doing comparison, we need to know the actual selected measure to
    // do the percentage calculation.
    const selectedMeasureName = getSelectedMeasureName(props);

    const totals: [number, number] = [0, 0];

    return props.measures.map((measure) => {
      const header = props.compareType
        ? getComparisonHeaderLabel(
            props.compareDateRange ?? [],
            props.dateRange ?? [],
            measure.name
          )
        : measure.name;

      const length =
        header.length > maxCharacterWidthsByColumn[measure.name]
          ? header.length
          : maxCharacterWidthsByColumn[measure.name];

      const width = length * AVG_PIXEL_WIDTH_OF_CHARACTER;

      return columnHelper.accessor((datum) => datum[measure.name], {
        id: measure.name,
        meta: { align: "right" },
        header: header,
        cell: ({ getValue }) => {
          const value = getValue();

          if (
            measure.name === PERCENT_DIFFERENCE_KEY &&
            typeof value === "number"
          ) {
            return formatPercentage(value);
          }

          return formatMeasure(value, measure.unit);
        },
        footer: ({ table }) => {
          const { rows } = table.getRowModel();
          // In the case of `percentDifference` column. We need to get the
          // percent difference of the first 2 totaled rows instead of
          // totaling up all the rows.
          if (measure.name === PERCENT_DIFFERENCE_KEY) {
            const decimalChange = (totals[0] - totals[1]) / totals[1];

            return formatPercentage(decimalChange);
          }

          // In every other case just total the rows like normal.
          const total = rows.reduce((sum, row) => {
            const value = row.getValue(measure.name) ?? 0;

            return typeof value === "number" ? value + sum : sum;
          }, 0);

          // push these toals outside of the scope of the map so we can use them
          // on the percentDifference column
          if (measure.name === selectedMeasureName) {
            totals[0] = total;
          } else if (measure.name === `${selectedMeasureName}Previous`) {
            totals[1] = total;
          }

          return <>{formatMeasure(total, measure.unit)}</>;
        },
        sortDescFirst: true,
        sortingFn: "basic",
        size: width,
      });
    });
  }

  const dimensionColumns = props.dimensions.map((dimension) =>
    columnHelper.accessor((datum) => datum[dimension.name], {
      id: dimension.name,
      header: dimension.name,
      meta: { truncate: true },
      size:
        (dimension.name.length > maxCharacterWidthsByColumn[dimension.name]
          ? dimension.name.length
          : maxCharacterWidthsByColumn[dimension.name]) *
        AVG_PIXEL_WIDTH_OF_CHARACTER,
      sortingFn: (rowA, rowB, columnID) => {
        const valueA = rowA.getValue(columnID);
        const valueB = rowB.getValue(columnID);

        if (typeof valueA !== "string") return -1;
        if (typeof valueB !== "string") return 1;

        if (valueA.toLowerCase() > valueB.toLowerCase()) {
          return 1;
        } else if (valueA.toLowerCase() < valueB.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      },
    })
  );

  const columns = React.useMemo(
    () => [...dimensionColumns, ...getMeasureColumns()],
    [props.data]
  );

  //
  // Event Handlers
  //

  function handleChangeSort(sortRules: SortRule[]): void {
    const newSortRule = sortRules.length
      ? { id: sortRules[0].id, desc: sortRules[0].desc }
      : undefined;

    mergeState({ sortRule: newSortRule });

    props.onInteraction?.({
      type: CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED,
      sortRule: newSortRule,
    });
  }

  //
  // JSX
  //

  const maxAllowedUIRows = props.maxRows ?? MAX_ALLOWED_UI_ROWS;

  const showRowTruncationMessage = rowObjects.length > maxAllowedUIRows;

  function renderTable() {
    if (props.isLoading || props.data.length === 0) {
      return (
        <EmptyPlaceholder
          height={400}
          loading={props.isLoading}
          icon={faTable}
          text={copyText.chartEmptyPlaceholderText}
        />
      );
    }

    return (
      <Table
        compact
        columns={columns}
        data={rowObjects}
        footer={props.footer}
        initialState={{
          pagination: { pageSize: maxAllowedUIRows },
          ...(sortRule?.id ? { sorting: [sortRule] } : {}),
        }}
        pinnedColumns={props.pinnedColumns}
        sortable={props.sortable}
        isLoading={props.isLoading}
        truncateRows
        onChangeSortBy={handleChangeSort}
      />
    );
  }

  return (
    <Box width="100%">
      <Flex
        borderRadius={theme.borderRadius_2}
        maxHeight={props.pinnedColumns !== undefined ? 600 : undefined}
        overflow="auto"
      >
        {renderTable()}
      </Flex>
      {showRowTruncationMessage && (
        <Flex alignItems="center" marginTop={theme.space_md} width="100%">
          {!props.isServer && (
            <Icon
              color={theme.secondary_color}
              icon={faExclamationTriangle}
              size="sm"
            />
          )}
          <Text
            marginBottom={theme.space_sm}
            marginLeft={theme.space_sm}
            marginRight={theme.space_xs}
          >
            {copyText.dataTableRowLimitReached}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

function formatMeasure(value: RawValue, unit: string | undefined) {
  if (value === undefined) return "--";

  if (typeof value !== "number") {
    return value;
  }

  return formatMeasureValueWithUnit({ unit, value });
}

function getRowObjects(params: {
  data: RawData[];
  dimensions: Dimension[];
  measures: Measure[];
}) {
  return params.data.map((datum) => {
    const newDatum = { ...datum };

    params.dimensions.forEach((dimension) => {
      newDatum[dimension.name] = convertDimension(
        datum[dimension.name],
        dimension,
        getFormatForGranularity()
      );
    });

    return newDatum;
  });
}

function getComparisonHeaderLabel(
  compareDateRange: DateRange,
  dateRange: DateRange,
  measure: string
) {
  const timestampFormat = "MM/dd";

  if (
    measure.includes(PERCENT_DIFFERENCE_KEY) ||
    measure.includes(RAW_DIFFERENCE_KEY)
  )
    return measure;

  let range = dateRange;
  if (measure.includes(COMPARISON_KEY)) {
    range = compareDateRange;
  }

  return `${measure}
  (${formatTimestamp(range[0].toISOString(), timestampFormat)} 
  - ${formatTimestamp(range[1].toISOString(), timestampFormat)})`;
}

function getMaxCharactersByColumn(rowObjects: RawData[]) {
  return rowObjects.reduce((accum: { [key: string]: number }, datum) => {
    Object.keys(datum).forEach((key) => {
      const existingLargestLengthForKey = accum[key];
      const value = datum[key];
      let stringValue = "";

      if (value === null) {
        stringValue = "null";
      }

      if (typeof value === "string") {
        stringValue = value;
      }

      if (typeof value === "number") {
        stringValue = value.toString();
      }

      // Note: For number type with value 0, we want to preserve largest key length
      if (value || value === 0) {
        if (stringValue.length > (existingLargestLengthForKey || 0)) {
          accum[key] = stringValue.length;
        }
      } else {
        accum[key] = stringValue.length;
      }
    });

    return accum;
  }, {});
}

function getSelectedMeasureName(props: Props): string | null {
  // Reports selected measure is user driven
  if (props.selectedMeasures && props.selectedMeasures.length === 1) {
    return props.selectedMeasures[0].name;
  }
  // Dashboard widget selected measure defaults to the first column measure
  else if (props.measures && props.measures.length) {
    return props.measures[0].name;
  }

  return null;
}

CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED =
  `CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED` as const;

interface InteractionSortTableClicked {
  type: typeof CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED;
  sortRule: SortRule | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrossSectionalDataTable {
  export type Interaction = InteractionSortTableClicked;
}

export default CrossSectionalDataTable;
