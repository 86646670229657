import Permission from "@ternary/api-lib/constants/permissions";
import { isGlobalAdmin } from "@ternary/api-lib/constants/system";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type DashboardGates = ReturnType<typeof dashboardGates>;

export default function dashboardGates(params: GateGetterParameters) {
  return {
    canCreateDashboards: params._permissionsIncludeAllOf([
      Permission.CREATE_DASHBOARD,
      Permission.READ_REPORTS,
    ]),
    canListDashboards: params._permissionsIncludeAllOf([
      Permission.READ_DASHBOARDS,
      Permission.READ_REPORTS,
    ]),
    canUpdateDashboards: params._permissionsIncludeAllOf([
      Permission.UPDATE_DASHBOARD,
      Permission.READ_REPORTS,
    ]),
    canEditGlobalDashboards:
      params._permissionsIncludeAllOf([Permission.UPDATE_DASHBOARD]) &&
      Boolean(isGlobalAdmin(params.authenticatedUser.email)),
    getCanDeleteSpecificDashboard: (dashboardCreatedBy: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_DASHBOARD]) ||
      Boolean(dashboardCreatedBy === params.authenticatedUser.email),
  };
}
