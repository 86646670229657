import { useQuery } from "@tanstack/react-query";
import { FindAwsRateRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindAwsRateRecommendationsQuery";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

export default function useGetAWSCommittedUseRecommendations(
  tenantID: string,
  options?: UseQueryOptions<
    FindAwsRateRecommendationsQueryResult,
    CoreAPIClientError
  >
): UseQueryResult<FindAwsRateRecommendationsQueryResult, CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  const enabled =
    options?.enabled === undefined
      ? gatekeeper.canListRecommendations
      : options.enabled && gatekeeper.canListRecommendations;

  return useQuery({
    queryKey: ["awsCommittedUseRecommendations"],
    queryFn: () => client.getAWSCommittedUseRecommendations(tenantID),
    enabled,
    ...options,
  });
}
