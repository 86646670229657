import { Input } from "@/types";
import { useTheme } from "@emotion/react";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UserConfigStatus } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { ChangeEvent, useState } from "react";
import Form from "../../../../ui-lib/components/Form";
import LoadingSpinner from "../../../../ui-lib/components/LoadingSpinner";
import Select, { DefaultOption } from "../../../../ui-lib/components/Select";
import TextInput from "../../../../ui-lib/components/TextInput";
import getMergeState from "../../../../utils/getMergeState";
import copyText from "../../copyText";

const INPUT_SCOPED_VIEW_ID = "scopedViewID";
const INPUT_SCOPED_VIEW_STATUS = "scopedViewStatus";
const INPUT_GROUP_NAME = "groupName";

export enum Action {
  COPY = "COPY",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export type ScopedView = {
  id: string;
  name: string;
};

export type ScopedViewConfig = {
  id: string;
  status: UserConfigStatus;
};

export type UserGroupConfig = {
  id: string;
  createdAt: string;
  createdByUserID: string;
  groupName: string;
  scopedViews: ScopedViewConfig[];
  updatedAt: string | null;
  updatedByUserID: string | null;
};

export interface Props {
  action: Action;
  existingGroupNames: string[];
  isLoading: boolean;
  isProcessing: boolean;
  scopedViews: ScopedView[];
  userGroupConfig?: UserGroupConfig;
  onInteraction: (interaction: UserGroupConfigForm.Interaction) => void;
}

interface State {
  groupNameInput: Input<string>;
  scopedViewsInput: Input<ScopedViewConfig[]>;
}

export function UserGroupConfigForm(props: Props): JSX.Element {
  const theme = useTheme();

  //
  // State
  //

  const initialState: State = {
    groupNameInput: {
      hasChanged: false,
      isValid: props.action === Action.UPDATE,
      value:
        props.action === Action.UPDATE
          ? (props.userGroupConfig?.groupName ?? "")
          : "",
    },
    scopedViewsInput: {
      hasChanged: false,
      isValid: !!props.userGroupConfig,
      value: props.userGroupConfig?.scopedViews ?? [],
    },
  };

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleChange(event: ChangeEvent<HTMLInputElement>, index?: number) {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case INPUT_GROUP_NAME: {
        mergeState({
          groupNameInput: {
            hasChanged: value !== initialState.groupNameInput.value,
            isValid:
              value.trim().length > 0 &&
              !props.existingGroupNames.includes(value),
            value: value,
          },
        });
        return;
      }
      case INPUT_SCOPED_VIEW_ID: {
        if (index === undefined) return;

        const hasChanged = initialState.scopedViewsInput.value[index]
          ? initialState.scopedViewsInput.value[index].id !== value
          : true;

        setState((currentState) => {
          const newScopedViewsInputValues =
            currentState.scopedViewsInput.value.map((inputValue, i) => {
              if (i === index) {
                return { ...inputValue, id: value };
              } else return inputValue;
            });
          return {
            ...currentState,
            scopedViewsInput: {
              ...currentState.scopedViewsInput,
              hasChanged: hasChanged,
              isValid: newScopedViewsInputValues.every(
                (input) => input.id.trim().length > 0
              ),
              value: newScopedViewsInputValues,
            },
          };
        });
        return;
      }
      case INPUT_SCOPED_VIEW_STATUS: {
        if (index === undefined) return;

        const hasChanged = initialState.scopedViewsInput.value[index]
          ? initialState.scopedViewsInput.value[index].status !== value
          : true;

        setState((currentState) => ({
          ...currentState,
          scopedViewsInput: {
            ...currentState.scopedViewsInput,
            hasChanged: hasChanged,
            isValid: value.trim().length > 0,
            value: currentState.scopedViewsInput.value.map((inputValue, i) => {
              if (i === index) {
                return { ...inputValue, status: value as UserConfigStatus };
              } else return inputValue;
            }),
          },
        }));
        return;
      }
    }
  }

  function handleClickAddScopedView() {
    setState((currentState) => ({
      ...currentState,
      scopedViewsInput: {
        hasChanged: true,
        isValid: false,
        value: [
          ...currentState.scopedViewsInput.value,
          {
            id: "",
            status: UserConfigStatus.DISABLED,
          },
        ],
      },
    }));
  }

  function handleClickRemoveScopedView(index: number) {
    setState((currentState) => {
      const currentScopedViewsInputValue = currentState.scopedViewsInput.value;
      currentScopedViewsInputValue.splice(index, 1);
      return {
        ...currentState,
        scopedViewsInput: {
          hasChanged: true,
          isValid: true,
          value: currentScopedViewsInputValue,
        },
      };
    });
  }

  //
  // Submission Handlers
  //

  function handleClose(): void {
    setState(initialState);
    props.onInteraction({
      type: UserGroupConfigForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  function handleSubmit(): void {
    if (props.action === Action.UPDATE) {
      props.onInteraction({
        type: UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        scopedViews: state.scopedViewsInput.value,
      });
    } else {
      props.onInteraction({
        type: UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        groupName: state.groupNameInput.value,
        scopedViews: state.scopedViewsInput.value,
      });
    }
  }

  //
  // Validations
  //

  const hasChanged = Object.entries(state).some(
    ([_ignore, details]) => details.hasChanged === true
  );

  const isValid = Object.entries(state).every(
    ([_ignore, details]) => details.isValid === true
  );

  const canSubmit =
    props.action === Action.UPDATE ? hasChanged && isValid : isValid;

  //
  // Render
  //

  const scopedViewOptions = props.scopedViews
    .reduce((accum: DefaultOption[], scopedView) => {
      if (
        !state.scopedViewsInput.value.find(
          (scopedViewInput) => scopedViewInput.id === scopedView.id
        )
      ) {
        return [
          ...accum,
          {
            label: scopedView.name,
            value: scopedView.id,
          },
        ];
      } else return accum;
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label));

  const scopedViewStatusOptions = [
    {
      label: copyText.scopedViewAvailableLabel,
      value: UserConfigStatus.DISABLED,
    },
    {
      label: copyText.scopedViewEnabledByDefaultLabel,
      value: UserConfigStatus.ENABLED,
    },
    {
      label: copyText.scopedViewEnabledStrictLabel,
      value: UserConfigStatus.ENABLED_STRICT,
    },
    {
      label: copyText.scopedViewEnforcedLabel,
      value: UserConfigStatus.ENFORCED,
    },
  ];

  return (
    <Form>
      <Flex direction="column" justifyContent="space-between">
        {/* Group Name */}
        <Box>
          <Text fontSize={theme.h4_fontSize} marginBottom={theme.space_sm}>
            {copyText.userGroupConfigFormGroupNameLabel}
          </Text>
          <Text
            color={theme.text_color_secondary}
            marginBottom={theme.space_md}
          >
            {copyText.userGroupConfigFormGroupNameInstructions}
          </Text>
          <TextInput
            disabled={props.action === Action.UPDATE}
            value={state.groupNameInput.value}
            variant={state.groupNameInput.isValid ? "success" : "danger"}
            onChange={(event) => {
              handleChange({
                target: {
                  name: INPUT_GROUP_NAME,
                  value: event?.target.value,
                },
              } as ChangeEvent<HTMLInputElement>);
            }}
          ></TextInput>
          {props.existingGroupNames.includes(state.groupNameInput.value) &&
            props.action !== Action.UPDATE && (
              <Text color={theme.feedback_negative} marginTop={theme.space_xxs}>
                {copyText.userGroupConfigFormGroupNameDuplicateError}
              </Text>
            )}
        </Box>
        {/* Scoped Views */}
        <Box marginTop={theme.space_xl}>
          <Text fontSize={theme.h4_fontSize} marginBottom={theme.space_sm}>
            {copyText.userGroupConfigFormGroupNameScopedViewsLabel}
          </Text>
          <Text
            color={theme.text_color_secondary}
            marginBottom={theme.space_md}
          >
            {copyText.userGroupConfigFormGroupNameScopedViewsInstructions}
          </Text>
          <Flex direction="column">
            {state.scopedViewsInput.value.map((scopedViewInput, i) => {
              const selectedScopedViewName =
                props.scopedViews.find(
                  (scopedView) => scopedView.id === scopedViewInput.id
                )?.name ?? "";
              const selectedStatus = scopedViewStatusOptions.find(
                (option) => option.value === scopedViewInput.status
              );

              return (
                <Flex key={i} alignItems="center" marginBottom={theme.space_sm}>
                  <Select
                    isLoading={props.isLoading}
                    isSearchable
                    options={scopedViewOptions}
                    value={[
                      {
                        label: selectedScopedViewName,
                        value: scopedViewInput.id,
                      },
                    ]}
                    onChange={(option) =>
                      handleChange(
                        {
                          target: {
                            name: INPUT_SCOPED_VIEW_ID,
                            value: option?.value ?? "",
                          },
                        } as ChangeEvent<HTMLInputElement>,
                        i
                      )
                    }
                  />
                  <Text bold marginHorizontal={theme.space_xs}>
                    :
                  </Text>
                  <Select
                    options={scopedViewStatusOptions}
                    value={selectedStatus}
                    onChange={(option) =>
                      handleChange(
                        {
                          target: {
                            name: INPUT_SCOPED_VIEW_STATUS,
                            value: option?.value ?? "",
                          },
                        } as ChangeEvent<HTMLInputElement>,
                        i
                      )
                    }
                  />
                  <Button
                    iconStart={<Icon icon={faTimes} />}
                    marginLeft={theme.space_xs}
                    size="small"
                    type="button"
                    onClick={() => handleClickRemoveScopedView(i)}
                  ></Button>
                </Flex>
              );
            })}
            <Flex>
              <Button
                iconStart={<Icon icon={faPlus} />}
                secondary
                size="tiny"
                type="button"
                onClick={handleClickAddScopedView}
              >
                {copyText.userGroupConfigFormAddScopedViewLabel}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="end">
        <Button
          marginRight={theme.space_xs}
          secondary
          type="reset"
          width={100}
          onClick={handleClose}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isLoading || props.isProcessing}
          primary
          width={100}
          onClick={handleSubmit}
          type="button"
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
UserGroupConfigForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "UserGroupConfigForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  "UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;

UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

type InteractionCancelButtonClicked = {
  type: typeof UserGroupConfigForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  groupName: string;
  scopedViews: ScopedViewConfig[];
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  scopedViews?: ScopedViewConfig[];
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserGroupConfigForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default UserGroupConfigForm;
