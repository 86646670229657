import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type DashboardEntity = Awaited<
  ReturnType<CoreAPIClient["getDashboardsByTenantID"]>
>[0];

export default function useGetDashboardsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<DashboardEntity[], CoreAPIClientError>
): UseQueryResult<DashboardEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["dashboards"],
    queryFn: () => client.getDashboardsByTenantID(tenantID),
    enabled: gatekeeper.canListDashboards,
    meta: { errorMessage: copyText.errorLoadingDashboardsMessage },
    ...options,
  });
}
