import { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { REPORT_NAME_CHARACTER_LIMIT } from "@ternary/api-lib/constants";
import { ReportScope } from "@ternary/api-lib/constants/enums";
import { SYSTEM_TAG, isGlobalAdmin } from "@ternary/api-lib/constants/system";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import Select from "../../../ui-lib/components/Select";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
interface Report {
  name?: string;
  tags?: string[];
  description?: string | null;
}
interface ReportModalProps {
  existingTags: string[];
  isCreateModal?: boolean;
  isGlobalReport?: boolean;
  isProcessing?: boolean;
  report?: Report;
  title: string;
  onClose: () => void;
  onSubmit: (params: {
    description?: string;
    name: string;
    scope: ReportScope;
    tags: string[];
  }) => void;
}

type State = {
  descriptionInput: string;
  inputTags: string[];
  isConfirmingSystemReport: boolean;
  nameInput: string;
};

const initialState: State = {
  descriptionInput: "",
  inputTags: [],
  isConfirmingSystemReport: false,
  nameInput: "",
};

export default function ReportModal(props: ReportModalProps): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const canSaveGlobalReport = isGlobalAdmin(authenticatedUser.email);
  const theme = useTheme();

  const [state, setState] = useState({
    ...initialState,
    descriptionInput: props.report?.description ?? "",
    nameInput: props.report?.name ?? "",
    inputTags: props.report?.tags
      ? props.report?.tags.map((tag) => ({ label: tag, value: tag }))
      : [],
  });

  const mergeState = getMergeState(setState);

  function handleSubmitSystem() {
    const tags = state.inputTags.map(({ value }) => value);

    props.onSubmit({
      description: state.descriptionInput,
      name: state.nameInput,
      scope: ReportScope.GLOBAL,
      tags: tags.includes(SYSTEM_TAG) ? tags : [SYSTEM_TAG, ...tags],
    });
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={true}
      showCloseButton
      width={500}
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          autoFocus
          caption={copyText.reportNameMaxCharactersCaption
            .replace("%USED%", String(state.nameInput.length))
            .replace(/%LIMIT%/g, String(REPORT_NAME_CHARACTER_LIMIT))}
          input={TextInput}
          label={copyText.nameInputLabel}
          placeholder={copyText.reportNamePlaceholder}
          value={state.nameInput}
          onChange={(event) => mergeState({ nameInput: event.target.value })}
        />

        <Box>
          <Text>{copyText.reportFormModalTagsTitle}</Text>
          <Select
            isMulti
            isSearchable
            isCreatable
            options={props.existingTags.map((tag) => ({
              label: tag,
              value: tag,
            }))}
            value={state.inputTags}
            onChange={(selectedTags) =>
              mergeState({ inputTags: [...selectedTags] })
            }
          />
        </Box>
        <Box marginTop={theme.space_sm}>
          <FormField
            input={TextInput}
            label={copyText.descriptionInputLabel}
            placeholder={copyText.reportDescriptionPlaceholder}
            value={state.descriptionInput}
            onChange={(event) =>
              mergeState({ descriptionInput: event.target.value })
            }
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Flex justifyContent="space-between" width={"100%"}>
          {canSaveGlobalReport && props.isCreateModal && (
            <Button
              disabled={
                state.nameInput.length === 0 ||
                state.nameInput.length > REPORT_NAME_CHARACTER_LIMIT ||
                props.isProcessing
              }
              primary
              onClick={handleSubmitSystem}
            >
              {copyText.saveAsSystemButtonLabel}
            </Button>
          )}
          <Box>
            <Button
              disabled={props.isProcessing}
              secondary
              width={70}
              onClick={props.onClose}
            >
              {copyText.cancelButtonLabel}
            </Button>
            <Button
              disabled={
                state.nameInput.length === 0 ||
                state.nameInput.length > REPORT_NAME_CHARACTER_LIMIT ||
                (props.isGlobalReport && !canSaveGlobalReport) ||
                props.isProcessing
              }
              primary
              marginLeft={theme.space_md}
              width={70}
              onClick={() =>
                props.onSubmit({
                  description: state.descriptionInput,
                  name: state.nameInput,
                  scope: ReportScope.SHARED,
                  tags:
                    state.inputTags.length > 0
                      ? state.inputTags.map((inputTag) => inputTag.value)
                      : [],
                })
              }
            >
              {props.isProcessing ? (
                <LoadingSpinner />
              ) : (
                copyText.saveButtonLabel
              )}
            </Button>
          </Box>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
