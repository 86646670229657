import { useTheme } from "@emotion/react";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { LabelGroupingRuleEntity, LabelMap } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { format } from "date-fns";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import externalLinks from "../../../constants/externalLinks";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import TextInput from "../../../ui-lib/components/TextInput";
import IconExport from "../../../ui-lib/icons/IconExport";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import useCreateLabelGroupingRule from "../hooks/useCreateLabelGroupingRule";
import useDeleteLabelGroupingRule from "../hooks/useDeleteLabelGroupingRule";
import useGetLabelGroupingRulesByTenantID from "../hooks/useGetLabelGroupingRulesByTenantID";
import { useGetLabelGroupingRulesCsvData } from "../hooks/useGetLabelGroupingRulesCsvData";
import useUpdateLabelGroupingRule from "../hooks/useUpdateLabelGroupingRule";
import LabelGroupingRuleRow from "./LabelGroupingRuleRow";

interface State {
  isProcessingRuleIndex: number | undefined;
  labelGroupingRules: LabelGroupingRuleEntity[];
  outputKey: string | undefined;
  searchText: string;
  selectedDimensions: string[];
  selectedRuleIndex: number | undefined;
}

const initialState = {
  isProcessingRuleIndex: undefined,
  labelGroupingRules: [],
  outputKey: undefined,
  searchText: "",
  selectedDimensions: [],
  selectedRuleIndex: undefined,
};

export default function LabelGroupingRuleManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _labelGroupingRules = [],
    isLoading: isLoadingLabelGroupingRules,
    isFetching: isRefetchingLabelGroupingRules,
    refetch: refetchLabelGroupingRules,
  } = useGetLabelGroupingRulesByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const billingLabelMap = labelMaps?.BILLING;

  const sortedLabelGroupingRules = _labelGroupingRules.sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
  );

  const filteredLabelGroupingRules = useMemo(
    () =>
      sortedLabelGroupingRules.filter((labelGroupingRule) => {
        const str = state.searchText.toLowerCase();

        const matchedOutputKey = labelGroupingRule.outputKey
          .toLowerCase()
          .includes(str);

        const matchedInput = labelGroupingRule.inputs.some((input) => {
          return input.key.includes(str);
        });

        return matchedOutputKey || matchedInput;
      }),
    [
      isLoadingLabelGroupingRules,
      isRefetchingLabelGroupingRules,
      state.searchText,
    ]
  );

  //
  // Mutations
  //

  const {
    isPending: isCreatingLabelGroupingRule,
    mutate: createLabelGroupingRule,
  } = useCreateLabelGroupingRule({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingLabelGroupingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.CREATE_LABEL_GROUPING_RULE);
    },
    onSuccess: () => {
      postAlert({
        message: copyText.successCreatingLabelGroupingRuleMessage,
        type: AlertType.SUCCESS,
      });

      refetchLabelGroupingRules();

      mergeState({ outputKey: undefined, selectedDimensions: [] });
    },
  });

  const {
    isPending: isDeletingLabelGroupingRule,
    mutate: deleteLabelGroupingRule,
  } = useDeleteLabelGroupingRule({
    onError: () => {
      postAlert({
        message: copyText.errorDeletingLabelGroupingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.CREATE_LABEL_GROUPING_RULE);
    },
    onSuccess: () => {
      postAlert({
        message: copyText.successDeletingLabelGroupingRuleMessage,
        type: AlertType.SUCCESS,
      });

      refetchLabelGroupingRules();

      mergeState({ isProcessingRuleIndex: undefined });
    },
  });

  const {
    isPending: isUpdatingLabelGroupingRule,
    mutate: updateLabelGroupingRule,
  } = useUpdateLabelGroupingRule({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingLabelGroupingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.UPDATE_LABEL_GROUPING_RULE);
    },
    onSuccess: () => {
      postAlert({
        message: copyText.successUpdatingLabelGroupingRuleMessage,
        type: AlertType.SUCCESS,
      });

      refetchLabelGroupingRules();

      mergeState({
        isProcessingRuleIndex: undefined,
        selectedRuleIndex: undefined,
      });
    },
  });

  //
  // Effects
  //

  useEffect(
    () =>
      mergeState({
        labelGroupingRules: filteredLabelGroupingRules,
      }),
    [filteredLabelGroupingRules]
  );

  //
  // Interaction Handlers
  //

  function handleDeleteGrouping(index: number, labelGroupingRuleID: string) {
    mergeState({ isProcessingRuleIndex: index });

    deleteLabelGroupingRule({ labelGroupingRuleID });
  }

  function handleUpdateName(index: number | undefined, name: string) {
    if (index === undefined) {
      mergeState({ outputKey: name });
      return;
    }

    const labelGroupingRule = state.labelGroupingRules[index];

    mergeState({
      labelGroupingRules: [
        ...state.labelGroupingRules.slice(0, index),
        { ...labelGroupingRule, outputKey: name },
        ...state.labelGroupingRules.slice(index + 1),
      ],
    });
  }

  function handleUpdateSelectedDimensions(
    index: number | undefined,
    dimensions: string[]
  ) {
    if (index === undefined) {
      mergeState({ selectedDimensions: dimensions });
      return;
    }

    const labelGroupingRule = state.labelGroupingRules[index];

    mergeState({
      labelGroupingRules: [
        ...state.labelGroupingRules.slice(0, index),
        {
          ...labelGroupingRule,
          inputs: dimensions.map((dimension, index) => ({
            key: dimension,
            mappingIndex: getLabelMapIndex(dimension, billingLabelMap),
            priority: index + 1,
          })),
        },
        ...state.labelGroupingRules.slice(index + 1),
      ],
    });
  }

  function handleSubmit(labelGroupingRuleID: string | undefined) {
    const labelGroupingRule = state.labelGroupingRules.find(
      (labelGroupingRule) => labelGroupingRule.id === labelGroupingRuleID
    );

    if (labelGroupingRule && labelGroupingRuleID) {
      mergeState({
        isProcessingRuleIndex:
          state.labelGroupingRules.indexOf(labelGroupingRule),
      });

      updateLabelGroupingRule({
        labelGroupingRuleID: labelGroupingRuleID,
        inputs: labelGroupingRule.inputs,
        outputKey: labelGroupingRule.outputKey,
      });
      return;
    }

    if (state.outputKey) {
      createLabelGroupingRule({
        tenantID: authenticatedUser.tenant.fsDocID,
        inputs: state.selectedDimensions.map((dimension, index) => ({
          key: dimension,
          mappingIndex: getLabelMapIndex(dimension, billingLabelMap),
          priority: index + 1,
        })),
        outputKey: state.outputKey,
      });

      return;
    }
  }

  //
  // Render
  //

  const csvData = useMemo(
    () => useGetLabelGroupingRulesCsvData(filteredLabelGroupingRules),
    [filteredLabelGroupingRules]
  );

  const csvFileName =
    "custom-groupings" +
    `-${authenticatedUser.tenant.name}-` +
    format(new Date(), "MM-dd-yyyy");

  const isProcessing =
    isCreatingLabelGroupingRule ||
    isDeletingLabelGroupingRule ||
    isUpdatingLabelGroupingRule;

  return (
    <>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
      >
        <Flex
          alignItems="center"
          borderBottom={`1px solid ${theme.section_card_border}`}
          justifyContent="space-between"
          padding={theme.space_md}
        >
          <Text fontSize={theme.h4_fontSize} fontWeight={theme.h3_fontWeight}>
            {copyText.labelGroupingRulesSectionTitle}
          </Text>
          <Flex>
            {state.outputKey === undefined ? (
              <Button
                iconStart={<Icon icon={faSitemap} />}
                marginRight={theme.space_md}
                secondary
                onClick={() => mergeState({ outputKey: "" })}
              >
                {copyText.newGroupingButtonLabel}
              </Button>
            ) : (
              <Button
                marginRight={theme.space_md}
                secondary
                onClick={() =>
                  mergeState({
                    outputKey: undefined,
                    selectedDimensions: [],
                  })
                }
              >
                {copyText.resetButtonLabel}
              </Button>
            )}
            <Box width={300}>
              <TextInput
                placeholder={copyText.searchInputPlaceholder}
                onChange={(event) => {
                  mergeState({ searchText: event.target.value });
                }}
              />
            </Box>
          </Flex>
        </Flex>
        <Box padding={theme.space_lg}>
          <Text marginBottom={theme.space_md}>
            {copyText.labelGroupingRulesSectionInstructions}
          </Text>
          <Flex justifyContent="space-between">
            <Text>
              {copyText.learnMoreCaption + " "}
              <a
                href={externalLinks.zendeskCustomLabelsDocumentation}
                rel="noreferrer"
                target="_blank"
              >
                {copyText.learnMoreLink}
              </a>
            </Text>
            <CSVLink
              data={csvData.rows}
              filename={csvFileName}
              headers={csvData.headers}
            >
              <Button size="tiny" iconStart={<IconExport />} secondary>
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Box>
      </Box>
      {isLoadingLabelGroupingRules || isRefetchingLabelGroupingRules ? (
        <Box>
          <EmptyPlaceholder
            loading={
              isLoadingLabelGroupingRules || isRefetchingLabelGroupingRules
            }
            skeletonVariant="rows"
          />
        </Box>
      ) : (
        <>
          {state.labelGroupingRules.map((labelGroupingRule, index) => {
            const canSubmit = !isEqual(
              state.labelGroupingRules[index],
              filteredLabelGroupingRules[index]
            );

            return (
              <LabelGroupingRuleRow
                key={labelGroupingRule.id}
                billingLabelMap={billingLabelMap}
                canSubmit={canSubmit}
                index={index}
                isCreatingLabelGroupingRule={false}
                isDeletingLabelGroupingRule={isDeletingLabelGroupingRule}
                isProcessing={isProcessing}
                isProcessingRuleIndex={state.isProcessingRuleIndex}
                isUpdatingLabelGroupingRule={isUpdatingLabelGroupingRule}
                labelGroupingRules={state.labelGroupingRules}
                outputKey={labelGroupingRule.outputKey}
                selectedDimensions={labelGroupingRule.inputs.map(
                  (input) => input.key
                )}
                selectedRuleIndex={state.selectedRuleIndex}
                onClickEditIcon={() =>
                  mergeState({
                    selectedRuleIndex: index,
                  })
                }
                onCloseTextInput={() =>
                  setState((currentState) => ({
                    ...currentState,
                    selectedRuleIndex: undefined,
                    labelGroupingRules: [
                      ...state.labelGroupingRules.slice(0, index),
                      {
                        ...labelGroupingRule,
                        outputKey: sortedLabelGroupingRules[index].outputKey,
                      },
                      ...state.labelGroupingRules.slice(index + 1),
                    ],
                  }))
                }
                onDeleteGrouping={handleDeleteGrouping}
                onSubmit={handleSubmit}
                onUpdateName={handleUpdateName}
                onUpdateSelectedDimensions={handleUpdateSelectedDimensions}
              />
            );
          })}
          {state.outputKey !== undefined && (
            <LabelGroupingRuleRow
              billingLabelMap={billingLabelMap}
              canSubmit={state.outputKey.length > 0}
              index={undefined}
              isCreatingLabelGroupingRule={isCreatingLabelGroupingRule}
              isDeletingLabelGroupingRule={false}
              isProcessingRuleIndex={undefined}
              isProcessing={isProcessing}
              isUpdatingLabelGroupingRule={false}
              labelGroupingRules={state.labelGroupingRules}
              outputKey={state.outputKey}
              selectedDimensions={state.selectedDimensions}
              selectedRuleIndex={undefined}
              onSubmit={() => handleSubmit(undefined)}
              onUpdateName={(_index, name) => mergeState({ outputKey: name })}
              onUpdateSelectedDimensions={handleUpdateSelectedDimensions}
            />
          )}
        </>
      )}
    </>
  );
}

function getLabelMapIndex(
  dimension: string,
  labelMap: LabelMap | undefined
): number {
  const valueString = labelMap ? labelMap[dimension] : "";

  return parseInt(valueString.replace("label_", ""));
}
