import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type ReportEntity = Awaited<
  ReturnType<CoreAPIClient["getReportsByTenantID"]>
>[0];

export default function useGetReportsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<ReportEntity[], CoreAPIClientError>
): UseQueryResult<ReportEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["reports", tenantID],
    queryFn: () => client.getReportsByTenantID(tenantID),
    enabled: gatekeeper.canListReports,
    meta: { errorMessage: copyText.errorLoadingReportsMessage },
    ...options,
  });
}
