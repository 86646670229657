import copyText from "../../common.copyText";

/* prettier-ignore */
export default {
  ...copyText,
  actionDropdownOptionLabelAccessTenant: "Access Tenant",
  actionDropdownOptionLabelBillingInformation: "%ACTION% Billing Information",
  actionDropdownOptionLabelEditTenant: "Edit Tenant",
  actionDropdownOptionLabelManageFeatureFlags: "Manage Feature Flags",
  actionDropdownOptionLabelViewTenants: "View Tenants",
  actionDropdownOptionLabelViewUsers: "View Users",
  addFeatureFlagButtonLabel: "Add Feature Flag",
  addUsersButtonLabel: "Add Users",
  auditLogModalTitle: "Audit Log",
  billingInfoAccountIDLabel: "Account ID",
  billingInfoAddressLine1Label: "Address Line 1",
  billingInfoAddressLine2Label: "Address Line 2",
  billingInfoBillingIDLabel: "Billing ID",
  billingInfoCityLabel: "City",
  billingInfoModalTitle: "billing information",
  billingInfoModalRemoveButtonLabel: "Remove",
  billingInfoPaymentTermLabel: "Payment Term",
  billingInfoStateLabel: "State",
  billingInfoZipcodeLabel: "Zip Code/Postcode",
  createTenantButtonLabel: "Create Tenant",
  deactivateTenantConfirmationMessage: "Deactivating this tenant will cause the following: \n- All data ingestion will stop \n- Users will be unable to access the platform \n- Notifications will no longer be sent \n\nAre you sure you want to do this?",
  deactivateTenantConfirmationTitle: "Deactivate Tenant",
  deleteBillingInfoConfirmationTitle: "Remove Billing Information",
  deleteBillingInfoConfirmationMessage: "Removing billing information for this tenant is permanent. Are you sure you want to do this?",
  errorAddingFeatureFlagsMessage: "An error occurred while attempting to add the feature flag. Please try again.",
  errorCreatingTenantMessage: "An error occured when attempting to create the tenant. Please try again.", 
  errorCreatingBillingInformationMessage: "An error occured when attempting to create the billing information. Please try again.", 
  errorGrantingUsersGlobalRolesMessage: "An error occured when attempting to give users global roles. Please try again.",
  errorGrantingUserTenantAccessMessage: "An error occured when attempting to grant access to the tenant. Please try again.",
  errorRemovingFeatureFlagsMessage: "An error occurred while attempting to remove the feature flag. Please try again.",
  errorRemovingMspBillingInfoMessage: "An error occurred while attempting to remove billing information. Please try again.",
  errorRevokingUserGlobalRoleMessage: "An error occurred while attempting to revoke user global roles. Please try again.",
  errorUpdatingTenantMessage: "An error occurred while attempting to update the tenant. Please try again.",
  errorUpdatingBillingInfoMessage: "An error occurred while attempting to update the billing information. Please try again.",
  errorUpdatingUserTenantRolesMessage: "An error occurred while attempting to update the users roles. Please try again.",
  featureFlagOptionLabel_ALLOW_TLOS_SLOW_PATH: "Allow TLOS Slow Path",
  featureFlagOptionLabel_BYPASS_TLOS_SLOW_PATH: "Bypass TLOS Slow Path",
  featureFlagOptionLabel_COST_COMPARE: "Cost Compare",
  featureFlagOptionLabel_CUSTOM_PRICING: "Custom Pricing",
  featureFlagOptionLabel_FOCUS_DATASETS: "Focus Datasets",
  featureFlagOptionLabel_MSP_INVOICING: "MSP Invoicing",
  featureFlagsModalTitle: "Feature Flags",
  featureFlagTableHeaderCreatedAt: "Created At",
  featureFlagTableHeaderCreatedBy: "Created By",
  featureFlagTableHeaderFlag: "Flag",
  formTitleCreate: "Create New Tenant",
  formTitleUpdate: "Update Tenant",
  globalRolesTabLabel: "Global Roles",
  goBackButtonLabel: "Go Back",
  modalTitleMspPartnerTenants: "MSP Tenants",
  removeFeatureFlagLabel: "Remove Feature Flag",
  removeFeatureFlagWarningText: "Are you sure you want to delete this feature flag? This operation cannot be undone.",
  reasonInputLabel: "Reason",
  reportsTabLabel: "Reports",
  revokeUserGlobalRolesConfirmationMessage: "Are you sure you want to revoke all global roles from %name%?",
  revokeUserGlobalRolesConfirmationTitle: "Revoke Roles",
  settingsTabLabel: "Settings",
  statusInputLabel: "Status",
  successAddingFeatureFlagsMessage: "The feature flag has been successfully added.",
  successCreatingTenantMessage: "The tenant has been successfully created.",
  successCreatingBillingInformationMessage: "The billing information has been successfully created.",
  successGrantingUsersGlobalRolesMessage: "All users have successfully been granted global roles.",
  successRemovingFeatureFlagsMessage: "The feature flag has been successfully removed.",
  successRemovingBillingInfoMessage: "The billing information has been successfully removed.",
  successRevokingUserGlobalRolesMessage: "The users global roles have been successfully revoked.",
  successUpdatingTenantMessage: "The tenant has been successfully updated.",
  successUpdatingBillingInfoMessage: "The billing information has been successfully updated.",
  successUpdatingUserTenantRolesMessage: "The users roles have been successfully updated.",
  tableEmptyPlaceholderText: "No Tenants to Display",
  tableHeaderCreatedAt: "Created At",
  tableHeaderID: "ID",
  tableHeaderName: "Name",
  tableHeaderServiceAccountEmail: "Service Account",
  tableHeaderStatus: "Status",
  tableHeaderType: "Type",
  tableTypeColumnLabel_DIRECT_CUSTOMER: "Direct",
  tableTypeColumnLabel_INTERNAL: "Internal",
  tableTypeColumnLabel_MSP_CUSTOMER: "Channel Customer",
  tableTypeColumnLabel_MSP_PARENT: "MSP",
  tenantCountLabel: "Tenants",
  tenantsTabLabel: "Tenants",
  tenantTypeOption_ALL: "All",
  tenantTypeOption_DIRECT_CUSTOMER: "Direct",
  tenantTypeOption_INTERNAL: "Internal",
  tenantTypeOption_MSP_CUSTOMER: "Channel Customer",
  tenantTypeOption_MSP_PARENT: "MSP",
  typeInputLabel: "Type",
  userCountLabel: "Users",
  usersTabLabel: "Users",
};
