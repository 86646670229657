import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { ChangeEvent, useState } from "react";
import externalLinks from "../../../constants/externalLinks";
import { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import TextInput from "../../../ui-lib/components/TextInput";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

const INPUT_CHANNEL_ID = "channelID";

interface Props {
  integration: { channelID: string };
  isProcessing: boolean;
  onInteraction: (
    interaction: UpdateSlackIntegrationModalForm.Interaction
  ) => void;
}

interface State {
  channelIDInput: string;
}

function UpdateSlackIntegrationModalForm(props: Props) {
  const [state, setState] = useState<State>({
    channelIDInput: props.integration.channelID,
  });
  const mergeState = getMergeState(setState);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    mergeState({ channelIDInput: event.target.value });
  }

  function handleSubmit() {
    props.onInteraction({
      type: UpdateSlackIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      channelID: state.channelIDInput,
    });
  }

  //
  // Render
  //

  const canSubmit =
    state.channelIDInput.length > 0 &&
    state.channelIDInput !== props.integration.channelID;

  const captionFragments =
    copyText.slackIntegrationChannelIDCaption.split("%link%");

  const externalLinkCaption = (
    <Flex alignItems="center">
      <Text whiteSpace="pre">{captionFragments[0]}</Text>
      <a
        href={externalLinks.zendeskSlackIntegrationDocumentation}
        rel="noreferrer"
        target="_blank"
      >
        {copyText.slackIntegrationChannelIDCaptionLink}
      </a>
      <Text whiteSpace="pre">{captionFragments[1]}</Text>
    </Flex>
  );

  return (
    <Modal
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: UpdateSlackIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text appearance="h3">{copyText.slackIntegrationTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          name={INPUT_CHANNEL_ID}
          autoFocus
          caption={externalLinkCaption}
          input={TextInput}
          label={copyText.slackIntegrationAttributeChannelID}
          required
          value={state.channelIDInput}
          onChange={handleChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          secondary
          width={100}
          onClick={() =>
            props.onInteraction({
              type: UpdateSlackIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED,
            })
          }
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? (
            <LoadingSpinner />
          ) : (
            copyText.configureButtonLabel
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UpdateSlackIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "UpdateSlackIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
UpdateSlackIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "UpdateSlackIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof UpdateSlackIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof UpdateSlackIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  channelID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace UpdateSlackIntegrationModalForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default UpdateSlackIntegrationModalForm;
