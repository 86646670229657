import { Role } from "./roles";

export const SYSTEM_ADMIN_ID = "00000000-0000-4000-b000-000000000000";

export const SYSTEM_TENANT_ID = "00000000-0000-5000-c000-000000000000";

export const DEFAULT_DASHBOARD_ID = "00000000-0000-1000-d000-000000000000";

export const systemUser = {
  id: SYSTEM_ADMIN_ID,
  avatarSrc: "/assets/img/user.png",
  email: "system-admin@ternary.app",
  grants: [
    {
      id: `${SYSTEM_TENANT_ID}:${SYSTEM_ADMIN_ID}`,
      tenantDocID: SYSTEM_TENANT_ID,
      roles: [Role.SYSTEM_ADMIN],
    },
  ],
  firstName: "Ternary",
  lastName: "System",
};

export const SYSTEM_TAG = "System";

export const GLOBAL_ADMIN_EMAILS = [
  "aj@ternary.app",
  "david@ternary.app",
  "francisco@ternary.app",
  "ethan@ternary.app",
  "hayden@ternary.app",
  "james@ternary.app",
  "kyle@ternary.app",
  "mac@ternary.app",
  "ryland@ternary.app",
  "steven@ternary.app",
] as const;

export function isGlobalAdmin(email: string) {
  return GLOBAL_ADMIN_EMAILS.some((adminEmail) => email === adminEmail);
}

export default systemUser;
