import common from "@/common.copyText";

// prettier-ignore
export default {
  ...common,
  actionMenuItemCopy: "Create Copy",
  actionMenuItemDelete: "Delete",
  actionMenuItemDownloadCSV: "Download CSV",
  actionMenuItemEdit: "Edit",
  chartHeaderTotal: "Total",
  chartSectionCommitmentPeriod: 'Commitment Period',
  chartSectionCurrentCommitment: 'Current Commitment',
  chartSectionMonthsLeft: 'Months Left in Period',
  chartSectionProjectedVariance: 'Projected Variance',
  commitmentChartCommitmentCommitment: 'Commitment',
  commitmentChartCommitmentCompleted: 'Completed',
  commitmentChartCommitmentFuture: 'Future Period',
  commitmentChartCommitmentInProgress: 'In Progress',
  commitmentChartCumulativeSpend: 'Cumulative Spend',
  commitmentChartLabelActualSpend: 'Actual Spend',
  commitmentChartLabelBreakoint: 'Breakpoint',
  commitmentChartLabelDerivedProjection: 'Derived Projection',
  commitmentChartLabelProjected: 'Ramp Plan',
  commitmentChartMonthSpend: 'Monthly Spend',
  configurationSectionCategoryCompute: 'Compute',
  configurationSectionCategoryDatabases: 'Databases',
  configurationSectionCategoryGCPSecurity: 'GCP Security',
  configurationSectionCategoryMarketplaceServices: 'Marketplace Services',
  configurationSectionCategoryNetwork: 'Network',
  configurationSectionCategoryOpsTools: 'Ops Tools',
  configurationSectionCategoryOptionAnalytics: "Analytics",
  configurationSectionCategoryOptionAnthos: "Anthos",
  configurationSectionCategoryPlaces: 'Places',
  configurationSectionCategoryRoutes: 'Routes',
  configurationSectionCategorySearchAPIs: 'Search APIs',
  configurationSectionCategoryServerless: 'Serverless',
  configurationSectionCategoryStorage: 'Storage',
  configurationSectionExplanationBreakpoints: "Breakpoints are the mechanism for projecting your future spend. Specify how much applicable spend this billing account will have in the chosen month, and we will assume linear MoM growth between Breakpoints. Every Ramp Plan must have at least 2 Breakpoints: one at the start and one at the end.",
  configurationSectionHeadingBreakpoints: "iii. Breakpoints",
  configurationSectionBreakpointsTooltip: "Existing breakpoints are consecutive.",
  configurationSectionHeadingCommitments: "ii. Commitment Periods",
  configurationSectionHeadingGeneral: "i. General Settings",
  configurationSectionLabelAmount: "Amount:",
  configurationSectionLabelBillingAccount: "Billing Account ID:",
  configurationSectionLabelCloud: "Cloud ID:",
  configurationSectionLabelCount: "Count:",
  configurationSectionLabelEndDate: "End Date:",
  configurationSectionLabelMonth: "Month:",
  configurationSectionLabelName: "Name:",
  configurationSectionLabelPercentGrowth: "Percent Growth",
  configurationSectionLabelPercentRowTotal: "Total Monthly Spend",
  configurationSectionLabelReason: "Reason:",
  configurationSectionLabelStartDate: "Start Date:",
  configurationSectionLabelTotalSpend: "Total Monthly Spend",
  configurationSectionOptionActuals: "Actuals",
  configurationSectionOptionPercent: "Percent Growth",
  configurationSectionOffsetModalInstructions: "Actual Spend in your Ramp Plan is calculated based on the data available in the Billing Export, but it is possible that you are aware of additional spend that counts towards your commitment. You can both provide Recurring Offsets for the life of the Ramp Plan and provide Offsets that are month-specific",
  configurationSectionOffsetModalMonthly: "Month-Specific Offsets:",
  configurationSectionOffsetModalReucurring: "Recurring Offsets:",
  configurationSectionOffsetModalTitle: "Configure Actual Spend Offsets",
  configurationSectionOffsetsButton: "Actual Spend Offsets",
  configurationSectionOffsetsMultiDimensional: "Multi-Dimensional?",
  configurationSectionPlaceholderDimension: "Select Dimension",
  configurationSectionPlaceholderCloud: "Select Cloud",
  configurationSectionPlaceholderEnterReason: "Enter Reason",
  configurationSectionPlaceholderName: "Enter Name",
  configurationSectionPopulateHistoricalDataLabel: "Populate Historical Cost Data",
  configurationSectionTitleCreate: "Create New Ramp Plan",
  configurationSectionTitleCreateCopy: "Create Ramp Plan Copy",
  configurationSectionTitleEdit: "Edit Ramp Plan",
  dimensionalChartTitle: 'Daily Spend',
  dimensionalChartSelectMonth: 'Select Month',
  errorCreatingRampPlan: "An error occured while attempting to create the ramp plan. Please try again.",
  errorUpdatingRampPlan: "An error occured while attempting to update the ramp plan. Please try again.",
  otherLabel: "other",
  rampPlanConceptCommitmentPeriod: 'Commitment Period',
  rampPlanConceptVariance: 'Variance',
  saveAsCopyButtonLabel: "Save As Copy",
  saveAsCopyRampPlanTitle: "copy",
  tableButtonClickToView: "Click to View",
  tableDeleteConfirmation: "Are you sure you want to permanently delete this Ramp Plan?",
  tableHeaderBillingAccountIDs: "BA IDs",
  tableHeaderClientName: "Client Name",
  tableHeaderCurrentCommitmentAmount: "Curr. Commitment",
  tableHeaderCurrentPercent: "(%)",
  tableHeaderCurrentPeriod: "Curr. Period",
  tableHeaderManagedBy: "Managed By",
  tableHeaderName: "Name",
  tableHeaderProjectedVariance: "Projected Variance",
  tableHeaderRemaining: "Remaining",
  tableSectionCreateButton: "Create New Ramp Plan",
  successfullyCreatedRampPlan: "The ramp plan was successfully created",
  successfullyDeletedRampPlan: "The ramp plan was successfully deleted",
  successfullyUpdatedRampPlan: "The ramp plan was successfully updated",
};
