import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatDistance } from "date-fns";
import React, { useMemo } from "react";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import DataStatusTooltip from "../../admin/components/DataStatusTooltip";
import { StatusVariant } from "../constants";
import copyText from "../copyText";

type Integration = {
  tenantDocID: string;
  latestRefresh: string | null;
  latestUpstream: string | null;
  name: string;
  refreshDifference: number;
  statusVariant: StatusVariant;
  tenantName: string;
};

type TableData = Integration & { hasTenantAccess };

const columnHelper = createColumnHelper<TableData>();

interface Props {
  integrations: Integration[];
  isLoading: boolean;
  onInteraction: (interaction: MspDataIntegrationTable.Interaction) => void;
}

export function MspDataIntegrationTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const hasAccess = cell.row.original.hasTenantAccess;

          return hasAccess ? (
            <Link
              target="_blank"
              to={{
                pathname: paths._admin,
                search: `?tenant_id=${tenantDocID}&tab=clouds`,
              }}
            >
              {cell.getValue()}
            </Link>
          ) : (
            <Text
              appearance="link"
              color={theme.link_color}
              onClick={() =>
                props.onInteraction({
                  type: MspDataIntegrationTable.INTERACTION_LINK_CLICKED,
                  tenantDocID,
                })
              }
            >
              {cell.getValue()}
            </Text>
          );
        },
        header: copyText.tableHeaderTenantName,
      }),
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
      }),
      columnHelper.accessor("refreshDifference", {
        cell: (cell) => {
          const latestRefresh = cell.row.original.latestRefresh;

          if (!latestRefresh) {
            return "--";
          }

          const now = new Date();
          const absoluteRefresh = new Date(latestRefresh);

          const refreshDifference = formatDistance(now, absoluteRefresh);

          return `${refreshDifference} ago`;
        },
        header: copyText.tableHeaderLastRefresh,
      }),
      columnHelper.accessor("statusVariant", {
        cell: (cell) => {
          const latestRefresh = cell.row.original.latestRefresh;
          const latestUpstream = cell.row.original.latestUpstream;

          return (
            <DataStatusTooltip
              latestRefresh={latestRefresh}
              latestUpstream={latestUpstream}
            />
          );
        },
        header: copyText.tableHeaderStatus,
        size: 50,
      }),
    ],
    [props.integrations]
  );

  const data = props.integrations.map((summary) => {
    const grant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === summary.tenantDocID
    );

    return { ...summary, hasTenantAccess: !!grant };
  });

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "statusVariant", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspDataIntegrationTable.INTERACTION_LINK_CLICKED =
  "MspChildDataIntegrationTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspDataIntegrationTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspDataIntegrationTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspDataIntegrationTable;
