import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown from "@/ui-lib/components/Dropdown";
import LockedWrapper from "@/ui-lib/components/LockedWrapper";
import { ActionMenuButton, TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import {
  faCancel,
  faEdit,
  faLock,
  faPencil,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { DashboardScope } from "@ternary/api-lib/constants/enums";
import systemUser, { isGlobalAdmin } from "@ternary/api-lib/constants/system";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useRefFn from "../../../hooks/useRefFn";
import Checkbox from "../../../ui-lib/components/Checkbox";
import TableTags from "../../../ui-lib/components/TableTags";
import IconStarOutline from "../../../ui-lib/icons/IconStarOutline";
import copyText from "../copyText";

type Dashboard = {
  id: string;
  createdAt: string;
  createdBy: string;
  favoritedUserIDs: string[];
  name: string;
  scope: string;
  tags: string[];
  updatedAt: string | null;
};

interface Props {
  dashboards: Dashboard[];
  existingTags: string[];
  highlightedTags: string[];
  isLoading: boolean;
  preferredDashboardID: string | null;
  selectedDashboardIDs: string[];
  showMultiSelect: boolean;
  onInteraction: (interaction: DashboardList.Interaction) => void;
}

interface TableData {
  id: string;
  preferred: boolean;
  createdAt: string;
  createdBy: string;
  isFavorited: boolean;
  isGlobal: boolean;
  isMultiSelected: boolean;
  name: string;
  selectedCount: number;
  shared: string;
  tags: string[];
  tagsString: string | null;
  timeLastModified: string;
}

export function DashboardList(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [filteredFavorites, setFilteredFavorites] = useState<boolean>(false);

  const canSaveGlobalDashboard = isGlobalAdmin(authenticatedUser.email);

  if (!gatekeeper.canListDashboards) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  function handleClickRow(dashboardID: string): void {
    props.onInteraction({
      type: DashboardList.INTERACTION_ROW_CLICKED,
      dashboardID,
    });
  }

  const handleClickTag = useRefFn((tag: string) => {
    props.onInteraction({
      type: DashboardList.INTERACTION_TAG_CLICKED,
      tag,
    });
  });

  const handleClickFavorite = useRefFn((dashboardID: string) => {
    props.onInteraction({
      type: DashboardList.INTERACTION_FAVORITE_DASHBOARD_CLICKED,
      dashboardID,
    });
  });

  function handleFilterFavorites() {
    props.onInteraction({
      type: DashboardList.INTERACTION_FILTER_FAVORITES_CLICKED,
    });
  }

  const handleMultiSelectAll = useRefFn(() => {
    const selectedIDSet = new Set(props.selectedDashboardIDs);

    const visableIDs = props.dashboards
      .filter((dashboard) => dashboard.scope !== DashboardScope.GLOBAL)
      .map((dashboards) => dashboards.id);

    const visableSelectedIDs = visableIDs.filter((id) => selectedIDSet.has(id));

    let selectedIDs: string[];
    if (visableSelectedIDs.length > 0) {
      selectedIDs = [];
    } else {
      selectedIDs = visableIDs;
    }

    props.onInteraction({
      type: DashboardList.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED,
      dashboardIDs: selectedIDs,
    });
  });

  const handleMultiSelectOne = useRefFn((id: string, checked: boolean) => {
    const selectedIDSet = new Set(props.selectedDashboardIDs);

    if (checked) {
      selectedIDSet.add(id);
    } else {
      selectedIDSet.delete(id);
    }

    props.onInteraction({
      type: DashboardList.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED,
      dashboardIDs: props.dashboards
        .filter((dashboard) => selectedIDSet.has(dashboard.id))
        .map((dashboard) => dashboard.id),
    });
  });

  const handleSelectSingle = useRefFn((id: string) => {
    props.onInteraction({
      type: DashboardList.INTERACTION_EDIT_SINGLE_TAGS_CLICKED,
      dashboardID: id,
    });
  });

  const columns = useMemo((): Column<TableData>[] => {
    return [
      {
        id: "favorite",
        align: "center",
        disableClick: true,
        NoAccessorCell: ({ row }) => {
          if (row.original.id === props.preferredDashboardID) {
            return (
              <Icon
                backgroundColorOnHover={theme.report_favorite_color_hover}
                clickable
                color={theme.primary_color_background}
                icon={faStar}
                size="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickFavorite(row.original.id);
                }}
              />
            );
          }
          return (
            <Flex
              alignItems="center"
              cursor={"pointer"}
              justifyContent="center"
            >
              {row.original.isFavorited ? (
                <Icon
                  backgroundColorOnHover={theme.report_favorite_color_hover}
                  clickable
                  color={theme.report_favorite_color}
                  icon={faStar}
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickFavorite(row.original.id);
                  }}
                />
              ) : (
                <IconStarOutline
                  backgroundColorOnHover={theme.report_favorite_color}
                  clickable
                  color={theme.table_header_background_color}
                  size="20px"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickFavorite(row.original.id);
                  }}
                />
              )}
            </Flex>
          );
        },
        Header: () => (
          <Icon
            backgroundColorOnHover={theme.report_favorite_color}
            clickable
            color={filteredFavorites ? theme.report_favorite_color : undefined}
            icon={faStar}
            size="lg"
            onClick={() => {
              setFilteredFavorites(!filteredFavorites), handleFilterFavorites();
            }}
          />
        ),
        width: 30,
      },
      {
        accessor: "name",
        Header: copyText.tableHeaderName,
        width: 200,
      },
      {
        accessor: "createdBy",
        Cell: ({ row }) => {
          if (row.original.isGlobal) {
            return systemUser.email;
          } else {
            return row.original.createdBy;
          }
        },
        Header: copyText.tableHeaderCreatedBy,
        width: 130,
      },
      {
        accessor: "createdAt",
        Cell: ({ value }) => <>{formatDate(new Date(value), "MM/dd/yyyy")}</>,
        Header: copyText.tableHeaderCreatedAt,
        width: 100,
      },
      {
        accessor: "timeLastModified",
        Cell: ({ value }) => (
          <>{formatDate(new Date(value ? value : 0), "MM/dd/yyyy hh:mm a")}</>
        ),
        Header: copyText.tableHeaderTimeLastModified,
        width: 130,
      },
      {
        accessor: "shared",
        align: "center",
        Header: copyText.tableHeaderVisibility,
        width: 100,
      },
      {
        accessor: "tags",
        Cell: ({ value }) => {
          if (value.length === 0) return "--";

          return (
            <TableTags
              highlightedTags={props.highlightedTags}
              tags={value}
              onClickTag={handleClickTag}
            />
          );
        },
        Header: copyText.tableHeaderTags,
        sortType: (a, b, _id, desc) => {
          const aTagsString = a.original.tagsString;
          const bTagsString = b.original.tagsString;
          const descMult = desc ? -1 : 1;

          if (aTagsString === null || bTagsString === null) {
            if (aTagsString === null && bTagsString !== null) {
              return 1 * descMult;
            }

            if (aTagsString !== null && bTagsString === null) {
              return -1 * descMult;
            }
          } else {
            if (aTagsString < bTagsString) {
              return -1;
            }
            if (aTagsString > bTagsString) {
              return 1;
            }
          }
          return 0;
        },
        width: 300,
      },
      {
        id: "drillDown",
        disableClick: true,
        Header: function RenderMultiSelectButton({ rows }) {
          const checkedCount = rows.reduce(
            (count, row) => (row.original.isMultiSelected ? count + 1 : count),
            0
          );

          return props.showMultiSelect ? (
            <Checkbox
              checked={checkedCount > 0 && checkedCount === rows.length}
              dashed={checkedCount > 0}
              onChange={handleMultiSelectAll}
            />
          ) : (
            <Flex
              alignItems="center"
              height={theme.fontSize_base}
              justifyContent="space-between"
              width="100%"
            >
              <Tooltip content={copyText.tableHeaderEditTags}>
                <Button
                  iconStart={<Icon icon={faEdit} />}
                  size="tiny"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onInteraction({
                      type: DashboardList.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED,
                      display: !props.showMultiSelect,
                    });
                  }}
                />
              </Tooltip>
            </Flex>
          );
        },
        NoAccessorCell: function renderButton({ row }) {
          return props.showMultiSelect ? (
            <Box onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={row.original.isMultiSelected}
                disabled={row.original.isGlobal && !canSaveGlobalDashboard}
                onChange={(e) => {
                  e.preventDefault();
                  if (row.original.isGlobal && !canSaveGlobalDashboard) {
                    return;
                  }
                  handleMultiSelectOne(
                    row.original.id,
                    e.currentTarget.checked
                  );
                }}
              />
            </Box>
          ) : (
            <Button
              disabled={row.original.isGlobal && !canSaveGlobalDashboard}
              iconStart={<Icon icon={faPencil} />}
              secondary
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectSingle(row.original.id);
              }}
            />
          );
        },
        disableSortBy: true,
        width: 50,
      },
      {
        id: "multiSelectToggle",
        Header: function renderMultiEditButton({ rows }) {
          if (!props.showMultiSelect) return null;

          const selectedCount = rows[0]?.original.selectedCount ?? 0;

          if (selectedCount > 0) {
            return (
              <Flex
                alignItems="center"
                height={theme.fontSize_base}
                justifyContent="space-between"
                width="100%"
              >
                <Tooltip
                  content={copyText.dashboardListEditNTagsToolTipMessage.replace(
                    "%NUMBER%",
                    selectedCount.toString()
                  )}
                >
                  <Button
                    iconStart={<Icon icon={faEdit} />}
                    primary
                    size="tiny"
                    onClick={(e) => {
                      e.preventDefault();

                      props.onInteraction({
                        type: DashboardList.INTERACTION_EDIT_MULTI_SELECT_CLICKED,
                      });
                    }}
                  />
                </Tooltip>
              </Flex>
            );
          }

          return (
            <Flex
              alignItems="center"
              height={theme.fontSize_base}
              justifyContent="space-between"
              width="100%"
            >
              <Tooltip content={copyText.dashboardListCancelEditToolTipMessage}>
                <Button
                  iconStart={<Icon icon={faCancel} />}
                  secondary
                  size="tiny"
                  onClick={(e) => {
                    e.preventDefault();

                    props.onInteraction({
                      type: DashboardList.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED,
                      display: false,
                    });
                  }}
                />
              </Tooltip>
            </Flex>
          );
        },
        disableSortBy: true,
        width: props.showMultiSelect ? 40 : 0,
      },
      {
        id: "actionMenu",
        align: "center",
        NoAccessorCell: ({ row }) => (
          <DashboardDropdown
            createdBy={row.original.createdBy}
            dashboardID={row.original.id}
            disabled={
              !gatekeeper.canUpdateDashboards &&
              !gatekeeper.getCanDeleteSpecificDashboard(row.original.createdBy)
            }
            isGlobal={row.original.isGlobal}
            onInteraction={props.onInteraction}
          />
        ),
        Header: "",
        width: 40,
      },
    ];
  }, [
    canSaveGlobalDashboard,
    filteredFavorites,
    props.dashboards,
    props.preferredDashboardID,
    props.highlightedTags,
    props.selectedDashboardIDs,
    props.showMultiSelect,
  ]);

  const data = useMemo(() => {
    return props.dashboards
      .map((dashboard) => {
        const sortedTags = [...dashboard.tags].sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        return {
          id: dashboard.id,
          createdAt: dashboard.createdAt,
          createdBy: dashboard.createdBy,
          isGlobal: dashboard.scope === DashboardScope.GLOBAL,
          isMultiSelected: props.selectedDashboardIDs.includes(dashboard.id),
          preferred: dashboard.id === props.preferredDashboardID,
          isFavorited: dashboard.favoritedUserIDs.includes(
            authenticatedUser.id
          ),
          name: dashboard.name,
          selectedCount: props.selectedDashboardIDs.length,
          shared: getScopeText(dashboard.scope),
          tags: sortedTags,
          tagsString: sortedTags.length
            ? sortedTags.join("-").toLowerCase()
            : null,
          timeLastModified: dashboard.updatedAt ?? dashboard.createdAt,
        };
      })
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      )
      .sort((a, b) => {
        //Sort for preferredDashboard
        if (a.preferred === b.preferred) return 0;
        if (a.preferred) return -1;
        return 1;
      });
  }, [
    props.dashboards,
    props.preferredDashboardID,
    props.selectedDashboardIDs,
  ]);

  return (
    <TableLegacy
      clickableRows
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      rowHeight="70px"
      showPagination
      sortable
      onClick={(row) => handleClickRow(row.id)}
    />
  );

  interface DashboardDropdownProps {
    createdBy: string;
    dashboardID: string;
    disabled: boolean;
    isGlobal: boolean;
    onInteraction: (interaction: DashboardList.Interaction) => void;
  }

  function DashboardDropdown(
    dropdownProps: DashboardDropdownProps
  ): JSX.Element {
    const options = [
      {
        label: copyText.actionMenuItemViewDashboard,
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardList.INTERACTION_VIEW_BUTTON_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemEditDashboard,
        locked: dropdownProps.isGlobal
          ? !canSaveGlobalDashboard
          : !gatekeeper.canUpdateDashboards,
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardList.INTERACTION_UPDATE_BUTTON_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemDeleteDashboard,
        locked: dropdownProps.isGlobal
          ? !canSaveGlobalDashboard &&
            !gatekeeper.getCanDeleteSpecificDashboard(dropdownProps.createdBy)
          : !gatekeeper.getCanDeleteSpecificDashboard(dropdownProps.createdBy),
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardList.INTERACTION_DELETE_BUTTON_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemMakeHomePageDashboard,
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardList.INTERACTION_PREFERRED_DASHBOARD_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
    ];

    return (
      <LockedWrapper locked={dropdownProps.disabled}>
        <Dropdown
          disabled={dropdownProps.disabled}
          options={options}
          placement="bottom-end"
        >
          <ActionMenuButton />
        </Dropdown>
      </LockedWrapper>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DashboardList {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `DashboardList.INTERACTION_DELETE_BUTTON_CLICKED`;
  export const INTERACTION_DISPLAY_MULTI_SELECT_CLICKED = `DashboardList.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED`;
  export const INTERACTION_EDIT_MULTI_SELECT_CLICKED = `DashboardList.INTERACTION_EDIT_MULTI_SELECT_CLICKED`;
  export const INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED = `DashboardList.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED`;
  export const INTERACTION_EDIT_SINGLE_TAGS_CLICKED = `DashboardList.INTERACTION_EDIT_SINGLE_TAGS_CLICKED`;
  export const INTERACTION_PREFERRED_DASHBOARD_CLICKED = `DashboardList.INTERACTION_PREFERRED_DASHBOARD_CLICKED`;
  export const INTERACTION_FAVORITE_DASHBOARD_CLICKED = `Dashboard.INTERACTION_FAVORITE_DASHBOARD_CLICKED`;
  export const INTERACTION_FILTER_FAVORITES_CLICKED = `Dashboard.INTERACTION_FILTER_FAVORITES_CLICKED`;
  export const INTERACTION_ROW_CLICKED = `DashboardList.INTERACTION_ROW_CLICKED`;
  export const INTERACTION_TAG_CLICKED = `Dashboard.INTERACTION_TAG_CLICKED`;
  export const INTERACTION_UPDATE_BUTTON_CLICKED = `DashboardList.INTERACTION_UPDATE_BUTTON_CLICKED`;
  export const INTERACTION_VIEW_BUTTON_CLICKED = `DashboardList.INTERACTION_VIEW_BUTTON_CLICKED`;

  interface InteractionDeleteButtonClicked {
    type: typeof DashboardList.INTERACTION_DELETE_BUTTON_CLICKED;
    dashboardID: string;
  }

  interface InteractionPreferredDashboardClicked {
    type: typeof DashboardList.INTERACTION_PREFERRED_DASHBOARD_CLICKED;
    dashboardID: string;
  }
  interface InteractionDisplayMultiSelectClicked {
    type: typeof DashboardList.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED;
    display: boolean;
  }

  interface InteractionEditMultiSelectClicked {
    type: typeof DashboardList.INTERACTION_EDIT_MULTI_SELECT_CLICKED;
  }

  interface InteractionEditMultipleTagsClicked {
    type: typeof DashboardList.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED;
    dashboardIDs: string[];
  }

  interface InteractionEditSingleTagsClicked {
    type: typeof DashboardList.INTERACTION_EDIT_SINGLE_TAGS_CLICKED;
    dashboardID: string;
  }

  interface InteractionFavoriteDashboardClicked {
    type: typeof DashboardList.INTERACTION_FAVORITE_DASHBOARD_CLICKED;
    dashboardID: string;
  }

  interface InteractionFilterFavoritesClicked {
    type: typeof DashboardList.INTERACTION_FILTER_FAVORITES_CLICKED;
  }

  interface InteractionRowClicked {
    type: typeof DashboardList.INTERACTION_ROW_CLICKED;
    dashboardID: string;
  }

  interface InteractionTagClicked {
    type: typeof DashboardList.INTERACTION_TAG_CLICKED;
    tag: string;
  }

  interface InteractionUpdateButtonClicked {
    type: typeof DashboardList.INTERACTION_UPDATE_BUTTON_CLICKED;
    dashboardID: string;
  }

  interface InteractionViewButtonClicked {
    type: typeof DashboardList.INTERACTION_VIEW_BUTTON_CLICKED;
    dashboardID: string;
  }

  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionDisplayMultiSelectClicked
    | InteractionEditMultipleTagsClicked
    | InteractionEditMultiSelectClicked
    | InteractionEditSingleTagsClicked
    | InteractionPreferredDashboardClicked
    | InteractionFavoriteDashboardClicked
    | InteractionFilterFavoritesClicked
    | InteractionRowClicked
    | InteractionTagClicked
    | InteractionUpdateButtonClicked
    | InteractionViewButtonClicked;
}

export default DashboardList;

function getScopeText(scope: string) {
  switch (scope) {
    case DashboardScope.GLOBAL:
      return copyText.tableScopeGlobal;
    case DashboardScope.PRIVATE:
      return copyText.tableScopePrivate;
    case DashboardScope.SHARED:
      return copyText.tableScopeShared;
    default:
      return copyText.tableScopeShared;
  }
}
