import Modal from "@/ui-lib/components/Modal";
import SelectCheckbox, { Option } from "@/ui-lib/components/SelectCheckbox";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { sortBy, uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import TableTags from "../../../ui-lib/components/TableTags";
import copyText from "../copyText";
import { getScopeText } from "../utils";

interface Report {
  id: string;
  name: string;
  createdAt: string;
  createdByEmail: string | null;
  createdByID: string;
  scope: string;
  tags: string[];
  updatedAt: string | null;
}

interface Props {
  isLoading: boolean;
  reports: Report[];
  onClose: () => void;
  onInteraction: (interaction: ReportListModal.Interaction) => void;
}

interface State {
  createdByFilters: string[];
  searchText: string;
  tagFilters: string[];
}

interface TableData {
  id: string;
  createdBy: string;
  createdByID: string;
  name: string;
  shared: string;
  tags: string[];
  tagsString: string | null;
  timeLastModified: string;
}

const initialState: State = {
  createdByFilters: [],
  searchText: "",
  tagFilters: [],
};

export function ReportListModal(props: Props): JSX.Element {
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const theme = useTheme();

  function handleClickRow(reportID: string): void {
    props.onInteraction({
      type: ReportListModal.INTERACTION_ROW_CLICKED,
      reportID,
    });
    props.onClose();
  }

  function handleClickTag(clickedTag: string) {
    if (state.tagFilters.includes(clickedTag)) {
      mergeState({
        tagFilters: state.tagFilters.filter(
          (existingTag) => existingTag !== clickedTag
        ),
      });
    } else {
      mergeState({ tagFilters: [...state.tagFilters, clickedTag] });
    }
  }

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        accessor: "name",
        Header: copyText.tableHeaderName,
        width: 352,
      },
      {
        accessor: "createdBy",
        Header: copyText.tableHeaderCreatedBy,
        width: 162,
      },
      {
        accessor: "timeLastModified",
        Cell: ({ value }) => <>{formatDate(new Date(value), "MM/dd/yyyy")}</>,
        Header: copyText.tableHeaderTimeLastModified,
        width: 122,
      },
      {
        accessor: "shared",
        Header: copyText.tableHeaderVisibility,
        width: 112,
      },
      {
        accessor: "tags",
        Cell: ({ value }) => {
          if (value.length === 0) return "--";

          return (
            <TableTags
              compact
              highlightedTags={state.tagFilters}
              tags={value.sort()}
              onClickTag={handleClickTag}
            />
          );
        },
        Header: copyText.tableHeaderTags,
        sortType: (a, b) => {
          const aTags = a.original.tagsString;
          const bTags = b.original.tagsString;

          if (aTags === null || bTags === null) {
            return aTags === bTags ? 0 : bTags === null ? -1 : 1;
          }

          return aTags < bTags ? -1 : aTags > bTags ? 1 : 0;
        },
        width: 162,
      },
    ],
    [state.tagFilters]
  );

  const data = useMemo(() => {
    let filteredData = props.reports
      .map((report) => {
        const sortedTags = [...report.tags].sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        return {
          id: report.id,
          name: report.name,
          createdBy: report.createdByEmail ?? "--",
          createdByID: report.createdByID,
          shared: getScopeText(report.scope),
          tags: report.tags,
          tagsString: sortedTags.length
            ? sortedTags.join("-").toLowerCase()
            : null,
          timeLastModified: report.updatedAt ?? report.createdAt,
        };
      })
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      );

    if (state.createdByFilters.length > 0) {
      filteredData = filteredData.filter((report) => {
        return state.createdByFilters.includes(report.createdByID ?? "");
      });
    }

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((report) => {
        return report.name
          .toLowerCase()
          .includes(state.searchText.toLowerCase());
      });
    }

    if (state.tagFilters.length > 0) {
      filteredData = filteredData.filter((report) => {
        return state.tagFilters.every((tag) => report.tags.includes(tag));
      });
    }

    return filteredData;
  }, [
    props.reports,
    state.createdByFilters,
    state.searchText,
    state.tagFilters,
  ]);

  const createdByFilterOptions: Option[] = uniqBy(
    props.reports,
    "createdByEmail"
  )
    .reduce((accum: Option[], report) => {
      if (!report.createdByEmail) return accum;

      return [
        ...accum,
        {
          label: report.createdByEmail,
          value: report.createdByID,
        },
      ];
    }, [])
    .sort((a, b) =>
      a.value.toLowerCase() < b.value.toLowerCase()
        ? -1
        : a.value.toLowerCase() > b.value.toLowerCase()
          ? 1
          : 0
    );

  const existingTags: string[] = props.reports.reduce(
    (accum: string[], report) => {
      report.tags.forEach((tag) => {
        if (!accum.includes(tag)) {
          accum.push(tag);
        }
      });
      return accum;
    },
    []
  );

  const existingTagOptions: Option[] = sortBy(
    existingTags.map((tag) => ({
      label: tag,
      value: tag,
    })),
    (option) => option.value.toLowerCase()
  );

  return (
    <Modal isOpen={true} showCloseButton width={1000} onClose={props.onClose}>
      <Modal.Header>
        <Text appearance="h4">{copyText.modalTitleReports}</Text>
      </Modal.Header>
      <Modal.Body>
        <Flex
          justifyContent="right"
          marginBottom={theme.space_md}
          marginRight={theme.size_medium}
          marginTop={-45}
        >
          <Box marginRight={theme.space_md} width="12rem">
            <TextInput
              iconStart={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchReportsDashboardsPlaceholder}
              onChange={(event) =>
                mergeState({ searchText: event.target.value })
              }
            />
          </Box>
          <Box marginRight={theme.space_md} width="12rem">
            <SelectCheckbox
              options={createdByFilterOptions}
              placeholder={copyText.filterReportsDashboardsPlaceholder}
              selectedValues={state.createdByFilters}
              width="12rem"
              onChange={(event) => mergeState({ createdByFilters: event })}
            />
          </Box>
          <SelectCheckbox
            options={existingTagOptions}
            placeholder={copyText.filterReportsByTag}
            selectedValues={state.tagFilters}
            width="12rem"
            onChange={(event) => mergeState({ tagFilters: event })}
          />
        </Flex>
        <Box height={500}>
          <TableLegacy
            clickableRows
            columns={columns}
            compact
            data={data}
            initialState={{ pageSize: 10, sortBy: [{ id: "name" }] }}
            isLoading={props.isLoading}
            showPagination
            sortable
            onClick={(row) => handleClickRow(row.id)}
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportListModal {
  export const INTERACTION_ROW_CLICKED = `ReportListModal.INTERACTION_ROW_CLICKED`;

  interface InteractionRowClicked {
    type: typeof ReportListModal.INTERACTION_ROW_CLICKED;
    reportID: string;
  }

  export type Interaction = InteractionRowClicked;
}

export default ReportListModal;
