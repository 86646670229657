import { WidgetType } from "@ternary/api-lib/constants/enums";
import {
  BudgetEntity,
  ReportEntity,
  SavingsOpportunityFilterEntity,
  WidgetSpec,
} from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import paths from "../../../constants/paths";
import { useMatchPath } from "../../../lib/react-router";
import BudgetViewContainer from "./BudgetViewContainer";
import RealizedCommitmentSavingsContainer from "./RealizedCommitmentSavingsContainer";
import ReportViewContainer from "./ReportViewContainer";
import SavingsOpportunitysViewContainer from "./SavingsOpportunityViewContainer";

//Row height at 200 to allow for vertical half size
const ROW_HEIGHT = 200;
const COL_LIMIT = 4;

// NOTE: Generally child components should not know about the api but I am making an
// exception in this case due to this component being a "connector" for two containers.

type GridLayout = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  static?: boolean;
  isDraggable?: boolean;
  isResizable?: boolean;
  resizeHandles?: string[];
  isBounded?: boolean;
};

interface Props {
  editLayout: boolean;
  widgetSpecs: WidgetSpec[];
  filters: SavingsOpportunityFilterEntity[];
  reports: ReportEntity[];
  budgets: BudgetEntity[];
  onInteraction: (
    interaction:
      | BudgetViewContainer.Interaction
      | RealizedCommitmentSavingsContainer.Interaction
      | ReportGrid.Interaction
      | ReportViewContainer.Interaction
      | SavingsOpportunitysViewContainer.Interaction
  ) => void;
}
const ReactGridLayout = WidthProvider(RGL);

export function ReportGrid(props: Props): JSX.Element {
  const currentPath = useMatchPath();

  const canEditLayout = Boolean(
    props.editLayout && currentPath === paths._dashboard
  );

  const reportsKeyedByID = keyBy<ReportEntity | undefined>(props.reports, "id");
  const budgetsKeyedByID = keyBy<BudgetEntity | undefined>(props.budgets, "id");
  const filtersKeyedByID = keyBy<SavingsOpportunityFilterEntity | undefined>(
    props.filters,
    "id"
  );

  function handleLayoutChange(grid: GridLayout[]): void {
    const widgetSpecs: WidgetSpec[] = [];

    grid.forEach((gridItem) => {
      // This is because we allow the same resource to exist more than once in a dashboard.
      // The react key format is `resourceID:widgetType:index`. We need to split these out
      // before sending this to the container for updating state & the dashboard via api.
      const [resourceID, widgetType] = splitGridItemID(gridItem.i);

      if (!widgetType) return;

      widgetSpecs.push({
        resourceID,
        widgetType,
        height: gridItem.h,
        width: gridItem.w,
        xCoordinate: gridItem.x,
        yCoordinate: gridItem.y,
      });
    });
    props.onInteraction({
      type: ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED,
      widgetSpecs,
    });
  }

  const layouts = useMemo(() => {
    return props.widgetSpecs.map((spec, index) => ({
      // This is very important that `i` matches whatever the react key is. Otherwise
      // the library won't work as expected.
      i: getGridItemID(spec, index),
      x: spec.xCoordinate,
      y: spec.yCoordinate,
      w: spec.width,
      h: spec.height,
      resizeHandles: ["s", "w", "e", "n", "sw", "nw", "se", "ne"],
      isDraggable: canEditLayout,
      isResizable: canEditLayout,
    }));
  }, [props.widgetSpecs, canEditLayout]);

  function handleWidgetSwitch(
    widgetType: WidgetType,
    resourceID: string,
    index: number
  ) {
    switch (widgetType) {
      case WidgetType.REPORT: {
        const report = reportsKeyedByID[resourceID];
        if (report) {
          return (
            <ReportViewContainer
              isEditLayout={canEditLayout}
              index={index}
              report={report}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
      case WidgetType.BUDGET_DAILY_TRENDS:
      case WidgetType.BUDGET_CURRENT_MONTH: {
        const budget = budgetsKeyedByID[resourceID];
        if (budget) {
          return (
            <BudgetViewContainer
              budget={budget}
              budgetType={widgetType}
              index={index}
              isEditLayout={canEditLayout}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
      case WidgetType.REALIZED_COMMITMENT_SAVINGS: {
        return (
          <RealizedCommitmentSavingsContainer
            isEditLayout={canEditLayout}
            index={index}
            onInteraction={props.onInteraction}
          />
        );
      }
      case WidgetType.SAVINGS_OPPORTUNITY_FILTER: {
        const filter = filtersKeyedByID[resourceID];
        if (filter) {
          return (
            <SavingsOpportunitysViewContainer
              isEditLayout={canEditLayout}
              index={index}
              savingsOpportunity={filter}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
    }
  }

  return (
    <Box marginHorizontal="-27px" padding={"0.5rem"}>
      <ReactGridLayout
        cols={COL_LIMIT}
        isBoudned={false}
        isDraggable={canEditLayout}
        isResizable={canEditLayout}
        layout={layouts}
        margin={[15, 15]}
        rowHeight={ROW_HEIGHT}
        onLayoutChange={handleLayoutChange}
      >
        {props.widgetSpecs.map((spec, index) => {
          return (
            <div
              key={getGridItemID(spec, index)}
              data-grid={layouts[index]}
              style={{ position: "relative" }}
            >
              {handleWidgetSwitch(spec.widgetType, spec.resourceID, index)}
            </div>
          );
        })}
      </ReactGridLayout>
    </Box>
  );
}

const DELIM = ":";

function getGridItemID(spec: WidgetSpec, index: number) {
  return [spec.resourceID, spec.widgetType, index].join(DELIM);
}

function splitGridItemID(id: string) {
  const [resourceID, widgetType] = id.split(DELIM);

  if (
    widgetType !== WidgetType.BUDGET_CURRENT_MONTH &&
    widgetType !== WidgetType.BUDGET_DAILY_TRENDS &&
    widgetType !== WidgetType.REPORT &&
    widgetType !== WidgetType.SAVINGS_OPPORTUNITY_FILTER &&
    widgetType !== WidgetType.REALIZED_COMMITMENT_SAVINGS
  ) {
    return [resourceID, null] as const;
  }

  return [resourceID, widgetType] as const;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportGrid {
  export const INTERACTION_CONFIG_POSITIONS_UPDATED = `ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED`;

  interface InteractionConfigPositionsUpdated {
    type: typeof ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED;
    widgetSpecs: WidgetSpec[];
  }

  export type Interaction = InteractionConfigPositionsUpdated;
}

export default ReportGrid;
