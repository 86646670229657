import Form, { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import {
  faPlus,
  faTimes,
  faTrashAlt,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  Operator,
  ReallocationRebuildType,
  ReallocationType,
} from "@ternary/api-lib/constants/enums";
import {
  DimensionPreferenceEntity,
  ReallocationConfigDynamic,
  ReallocationConfigStatic,
  ReallocationFilter,
  ReallocationTypeConfig,
} from "@ternary/api-lib/core/types";
import { Dimension } from "@ternary/api-lib/ui-lib/charts/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { noop } from "@ternary/api-lib/utils/ReportUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, partition, uniq } from "lodash";
import React, { useState } from "react";
import DualListbox from "../../../components/DualListBox";
import { MeasureWithUnit, operatorOptions } from "../../../constants";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { Input, QueryFilter } from "../../../types";
import DatePicker from "../../../ui-lib/components/DatePicker";
import Grid from "../../../ui-lib/components/Grid";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import NumberInput from "../../../ui-lib/components/NumberInput";
import Select from "../../../ui-lib/components/Select";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import getMergeState from "../../../utils/getMergeState";
import { isOperator } from "../../../utils/typeGuards";
import { groupOptionsByPreferences } from "../../admin/utils";
import copyText from "../copyText";
import { sourceMatchKeyBundles } from "../utils";

const DYNAMIC_FILTERS_INPUT = "dynamicFiltersInput";
const FILTERS_INPUT = "filtersInput";

export enum Action {
  COPY = "COPY",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

type ReallocationTarget = {
  identifier: {
    projectID: string;
    labels: { key: string; value: string }[];
  };
  share: number;
  sourceMatchValues: { key: string; value: string | null }[];
};

export type Reallocation = {
  id: string;
  createdAt: string;
  createdByUserID: string;
  dataSource: DataSource;
  dimensions: string[];
  endTime: string | null;
  filters: ReallocationFilter[];
  measure: string;
  name: string;
  reason: string;
  rebuildType: ReallocationRebuildType;
  startTime: string;
  targetServiceDescription: string;
  targetSkuDescription: string;
  typeConfig: ReallocationTypeConfig;
  updatedAt: string | null;
  updatedByUserID: string | null;
};

interface DimensionValuesMap {
  [key: string]: string[];
}

type Option = {
  label: string;
  value: string;
};

export interface Props {
  action: Action;
  availableDimensions: Dimension[];
  availableMeasures: MeasureWithUnit[];
  dimensionValuesMap: DimensionValuesMap;
  isLoadingDimensionValuesMap: boolean;
  isLoadingDimensionPreferences: boolean;
  isProcessing: boolean;
  dimensionPreferences: DimensionPreferenceEntity[];
  reallocation?: Reallocation;
  onInteraction: (interaction: ReallocationForm.Interaction) => void;
}

interface State {
  dynamicDataSourceInput: Input<DataSource>;
  dynamicFiltersInput: Input<ReallocationFilter[]>;
  dynamicMeasureInput: Input<string>;
  dynamicMinimumShareInput: Input<number>;
  dynamicSourceMatchKeysInput: Input<string[]>;
  endDateInput: Input<string | null>;
  filtersInput: Input<ReallocationFilter[]>;
  groupingsInput: Input<string[]>;
  nameInput: Input<string>;
  rebuildTypeInput: Input<ReallocationRebuildType>;
  startDateInput: Input<string>;
  targetsInput: Input<ReallocationTarget[]>;
  type: ReallocationType;
}

const initialState: State = {
  dynamicDataSourceInput: {
    value: DataSource.BILLING,
    isValid: true,
    hasChanged: false,
  },
  dynamicFiltersInput: { value: [], isValid: true, hasChanged: false },
  dynamicMeasureInput: { value: "", isValid: false, hasChanged: false },
  dynamicMinimumShareInput: { value: 0, isValid: true, hasChanged: false },
  dynamicSourceMatchKeysInput: { value: [], isValid: false, hasChanged: false },
  endDateInput: { value: null, isValid: true, hasChanged: false },
  filtersInput: { value: [], isValid: true, hasChanged: false },
  groupingsInput: { value: [], isValid: true, hasChanged: false },
  nameInput: { value: "", isValid: false, hasChanged: false },
  startDateInput: { value: "", isValid: false, hasChanged: false },
  rebuildTypeInput: {
    value: ReallocationRebuildType.ONEOFF,
    isValid: true,
    hasChanged: false,
  },
  targetsInput: {
    value: [],
    isValid: false,
    hasChanged: false,
  },
  type: ReallocationType.STATIC,
};

export function ReallocationForm(props: Props): JSX.Element {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();

  //
  // State
  //

  const [state, setState] = useState(
    props.reallocation
      ? {
          ...initialState,
          dynamicDataSourceInput: {
            hasChanged: false,
            isValid: true,
            value:
              props.reallocation.typeConfig.type === ReallocationType.DYNAMIC
                ? props.reallocation.typeConfig.dataSource
                : DataSource.BILLING,
          },
          dynamicFiltersInput: {
            hasChanged: false,
            isValid: true,
            value:
              props.reallocation.typeConfig.type === ReallocationType.DYNAMIC
                ? props.reallocation.typeConfig.filters
                : [],
          },
          dynamicMeasureInput: {
            hasChanged: false,
            isValid:
              props.reallocation.typeConfig.type === ReallocationType.DYNAMIC,
            value:
              props.reallocation.typeConfig.type === ReallocationType.DYNAMIC
                ? props.reallocation.typeConfig.measure
                : "",
          },
          dynamicMinimumShareInput: {
            hasChanged: false,
            isValid: true,
            value:
              props.reallocation.typeConfig.type === ReallocationType.DYNAMIC
                ? props.reallocation.typeConfig.minimumShare * 100
                : 0,
          },
          dynamicSourceMatchKeysInput: {
            hasChanged: false,
            isValid: true,
            value:
              props.reallocation.typeConfig.type === ReallocationType.DYNAMIC
                ? props.reallocation.typeConfig.sourceMatchKeys
                : [],
          },
          endDateInput: {
            hasChanged: false,
            isValid: true,
            value:
              props.action === Action.UPDATE
                ? props.reallocation.endTime
                : null,
          },
          filtersInput: {
            hasChanged: false,
            isValid: true,
            value: props.reallocation.filters,
          },
          groupingsInput: {
            hasChanged: false,
            isValid: true,
            value: props.reallocation.dimensions,
          },
          nameInput: {
            hasChanged: false,
            isValid: true,
            value: `${props.reallocation.name} ${
              props.action === Action.COPY
                ? copyText.reallocationFormCopyNameLabel
                : ""
            }`,
          },
          startDateInput: {
            hasChanged: false,
            isValid: props.action === Action.UPDATE,
            value:
              props.action === Action.UPDATE
                ? props.reallocation.startTime
                : "",
          },
          rebuildTypeInput: {
            hasChanged: false,
            isValid: true,
            value: props.reallocation.rebuildType,
          },
          targetsInput: {
            value: props.reallocation.typeConfig.targets.map((target) => {
              return {
                identifier: {
                  projectID: target.identifier.projectID,
                  labels: Object.entries(target.identifier.labels).map(
                    ([key, value]) => {
                      return {
                        key: key,
                        value: typeof value === "string" ? value : "",
                      };
                    }
                  ),
                },
                share: target.share ? target.share * 100 : 0,
                sourceMatchValues: target.sourceMatchValues
                  ? Object.entries(target.sourceMatchValues).map(
                      ([key, value]) => {
                        return {
                          key: key,
                          value: typeof value === "string" ? value : "null",
                        };
                      }
                    )
                  : [],
              };
            }),
            isValid: true,
            hasChanged: false,
          },
          type: props.reallocation.typeConfig.type,
        }
      : initialState
  );
  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleChangeName(value: string) {
    const isValid = value.trim().length > 0;

    const hasChanged = !isEqual(value, props.reallocation?.name);

    mergeState({ nameInput: { value, isValid, hasChanged } });
  }

  function handleChangeStartDate(date: Date | null): void {
    if (date === null) return;

    let newEndDate: Date | undefined = undefined;

    // Do not allow end time to be sooner than or equal to start time.
    if (date.getTime() >= new Date(state.endDateInput.value ?? "").getTime()) {
      newEndDate = new Date(date);
      newEndDate.setDate(date.getDate() + 1);
    }

    const startDateHasChanged = !isEqual(
      date.toISOString(),
      props.reallocation?.startTime
    );

    const endDateHasChanged = newEndDate
      ? !isEqual(newEndDate.toISOString(), props.reallocation?.endTime)
      : false;

    mergeState({
      startDateInput: {
        hasChanged: startDateHasChanged,
        isValid: true,
        value: date.toISOString(),
      },
      ...(newEndDate
        ? {
            endDateInput: {
              hasChanged: endDateHasChanged,
              isValid: true,
              value: newEndDate.toISOString(),
            },
          }
        : {}),
    });
  }

  function handleChangeEndDate(date: Date | null): void {
    const hasChanged = !isEqual(
      date?.toISOString(),
      props.reallocation?.endTime
    );

    mergeState({
      endDateInput: {
        value: date ? date.toISOString() : date,
        isValid: true,
        hasChanged,
      },
    });
  }

  function handleChangeDynamicSourceMatchKeys(options: string[]): void {
    props.onInteraction({
      type: ReallocationForm.INTERACTION_SOURCE_MATCH_KEYS_CHANGED,
      keys: options,
    });

    setState((currentState) => {
      const newTargets = currentState.targetsInput.value.map((target) => {
        // keep source match values that are in options, remove the ones that aren't
        const existingSourceMatchValues = target.sourceMatchValues.filter(
          (sourceMatchValue) =>
            options.find((option) => option === sourceMatchValue.key)
        );

        // add new source match values for new options.
        const newSourceMatchValues = options.reduce(
          (accum: { key: string; value: string | null }[], option) => {
            if (
              !target.sourceMatchValues.find((value) => value.key === option)
            ) {
              accum.push({ key: option, value: "" });
            }
            return accum;
          },
          []
        );

        return {
          ...target,
          sourceMatchValues: [
            ...existingSourceMatchValues,
            ...newSourceMatchValues,
          ],
        };
      });

      const sourceMatchKeysHaveChanged =
        props.reallocation?.typeConfig.type === ReallocationType.DYNAMIC
          ? !isEqual(options, props.reallocation?.typeConfig.sourceMatchKeys)
          : true;

      const targetsHaveChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      const newTargetsAreOptional =
        currentState.type === ReallocationType.DYNAMIC &&
        options.includes("projectId");

      const targetsAreValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional: newTargetsAreOptional,
        type: state.type,
      });

      return {
        ...currentState,
        dynamicSourceMatchKeysInput: {
          hasChanged: sourceMatchKeysHaveChanged,
          isValid: true,
          value: options,
        },
        targetsInput: {
          hasChanged: targetsHaveChanged,
          isValid: targetsAreValid,
          value: newTargets,
        },
      };
    });
  }

  function handleClickBundleHelper(values: string[]) {
    setState((currentState) => {
      const newKeys = [
        ...currentState.dynamicSourceMatchKeysInput.value,
        ...values,
      ];

      props.onInteraction({
        type: ReallocationForm.INTERACTION_SOURCE_MATCH_KEYS_CHANGED,
        keys: newKeys,
      });

      const newTargets = currentState.targetsInput.value.map((target) => {
        // keep source match values that are in options, remove the ones that aren't
        const existingSourceMatchValues = target.sourceMatchValues.filter(
          (sourceMatchValue) =>
            newKeys.find((key) => key === sourceMatchValue.key)
        );

        // add new source match values for new values.
        const newSourceMatchValues = values.reduce(
          (accum: { key: string; value: string | null }[], value) => {
            if (
              !target.sourceMatchValues.find((value) => value.key === value)
            ) {
              accum.push({ key: value, value: "" });
            }
            return accum;
          },
          []
        );

        return {
          ...target,
          sourceMatchValues: [
            ...existingSourceMatchValues,
            ...newSourceMatchValues,
          ],
        };
      });

      const sourceMatchKeysHaveChanged =
        props.reallocation?.typeConfig.type === ReallocationType.DYNAMIC
          ? !isEqual(newKeys, props.reallocation?.typeConfig.sourceMatchKeys)
          : true;

      const targetsHaveChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      const newTargetsAreOptional =
        currentState.type === ReallocationType.DYNAMIC &&
        newKeys.includes("projectId");

      const targetsAreValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional: newTargetsAreOptional,
        type: state.type,
      });

      return {
        ...currentState,
        dynamicSourceMatchKeysInput: {
          hasChanged: sourceMatchKeysHaveChanged,
          isValid: true,
          value: newKeys,
        },
        targetsInput: {
          hasChanged: targetsHaveChanged,
          isValid: targetsAreValid,
          value: newTargets,
        },
      };
    });
  }

  function handleAddFilter(dynamic?: boolean): void {
    const targetInput = dynamic ? DYNAMIC_FILTERS_INPUT : FILTERS_INPUT;

    setState((currentState) => {
      const newFilters = [
        ...currentState[targetInput].value,
        {
          name: "",
          operator: Operator.EQUALS,
          values: [],
        },
      ];

      const hasChanged = getFiltersEquality({
        dynamic: !!dynamic,
        existingReallocation: props.reallocation,
        newFilters,
      });

      return {
        ...currentState,
        [targetInput]: {
          hasChanged,
          isValid: false,
          value: newFilters,
        },
      };
    });
  }

  function handleUpdateFilterName(
    name: string,
    index: number,
    dynamic?: boolean
  ): void {
    if (!name) return;

    const targetInput = dynamic ? DYNAMIC_FILTERS_INPUT : FILTERS_INPUT;

    setState((currentState) => {
      const filter = currentState[targetInput].value[index];

      props.onInteraction({
        type: ReallocationForm.INTERACTION_FILTER_NAME_CHANGED,
        dynamic: !!dynamic,
        index,
        filter: { ...filter, name, values: [] },
      });

      const newFilters = [
        ...currentState[targetInput].value.slice(0, index),
        {
          ...filter,
          name,
          values: [],
        },
        ...currentState[targetInput].value.slice(index + 1),
      ];

      const isValid = !newFilters.some(
        (filter) => filter.values && filter.values.length === 0
      );

      const hasChanged = getFiltersEquality({
        dynamic: !!dynamic,
        existingReallocation: props.reallocation,
        newFilters,
      });

      return {
        ...currentState,
        [targetInput]: {
          hasChanged,
          isValid,
          value: newFilters,
        },
      };
    });
  }

  function handleUpdateFilterOperator(
    value: string,
    index: number,
    dynamic?: boolean
  ): void {
    if (!isOperator(value)) return;

    const targetInput = dynamic ? DYNAMIC_FILTERS_INPUT : FILTERS_INPUT;

    setState((currentState) => {
      const filter = currentState[targetInput].value[index];

      if (!filter) return currentState;

      let values: QueryFilter["values"] = null;

      switch (value) {
        case Operator.EQUALS:
        case Operator.NOT_EQUALS:
          values = filter.values ?? [];
          break;
        case Operator.CONTAINS:
        case Operator.NOT_CONTAINS:
          values = filter.values && filter.values[0] ? [filter.values[0]] : [];
          break;
        case Operator.NOT_SET:
        case Operator.SET:
          values = null;
          break;
      }

      const newFilters = [
        ...currentState[targetInput].value.slice(0, index),
        { ...filter, operator: value, values },
        ...currentState[targetInput].value.slice(index + 1),
      ];

      const isValid = !newFilters.some(
        (filter) => filter.values && filter.values.length === 0
      );

      const hasChanged = getFiltersEquality({
        dynamic: !!dynamic,
        existingReallocation: props.reallocation,
        newFilters,
      });

      return {
        ...currentState,
        [targetInput]: {
          hasChanged,
          isValid,
          value: newFilters,
        },
      };
    });
  }

  function handleUpdateFilterValues(
    options,
    index: number,
    dynamic?: boolean
  ): void {
    let values: string[] = [];

    const targetInput = dynamic ? DYNAMIC_FILTERS_INPUT : FILTERS_INPUT;

    if (!Array.isArray(options)) {
      values = [options.value];
    } else {
      values = options;
    }

    setState((currentState) => {
      const filter = currentState[targetInput].value[index];

      if (!filter) return currentState;

      const newFilters = [
        ...currentState[targetInput].value.slice(0, index),
        { ...filter, values },
        ...currentState[targetInput].value.slice(index + 1),
      ];

      const isValid = !newFilters.some(
        (filter) => filter.values && filter.values.length === 0
      );

      const hasChanged = getFiltersEquality({
        dynamic: !!dynamic,
        existingReallocation: props.reallocation,
        newFilters,
      });

      return {
        ...currentState,
        [targetInput]: {
          hasChanged,
          isValid,
          value: newFilters,
        },
      };
    });
  }

  function handleRemoveFilter(index: number, dynamic?: boolean): void {
    props.onInteraction({
      type: ReallocationForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED,
      dynamic: !!dynamic,
      index,
    });

    const targetInput = dynamic ? DYNAMIC_FILTERS_INPUT : FILTERS_INPUT;

    setState((currentState) => {
      const newFilters = [
        ...currentState[targetInput].value.slice(0, index),
        ...currentState[targetInput].value.slice(index + 1),
      ];

      const isValid = !newFilters.some(
        (filter) => filter.values && filter.values.length === 0
      );

      const hasChanged = getFiltersEquality({
        dynamic: !!dynamic,
        existingReallocation: props.reallocation,
        newFilters,
      });

      return {
        ...currentState,
        [targetInput]: {
          hasChanged,
          isValid,
          value: newFilters,
        },
      };
    });
  }

  function handleChangeType(option): void {
    setState((currentState) => {
      const isValid =
        currentState.targetsInput.value.length > 0 &&
        currentState.targetsInput.value.every(
          (target) =>
            (option.value === ReallocationType.STATIC
              ? target.share
              : target.sourceMatchValues) &&
            target.identifier.projectID &&
            target.identifier.labels.every((label) => label.key && label.value)
        );

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: currentState.targetsInput.value,
        type: option.value,
      });

      return {
        ...currentState,
        type: option.value,
        targetsInput: {
          ...currentState.targetsInput,
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleAddTarget(): void {
    setState((currentState) => {
      const newTargets = [
        ...currentState.targetsInput.value,
        {
          identifier: {
            projectID: "",
            labels: [{ key: "", value: "" }],
          },
          share: 0,
          sourceMatchValues: state.dynamicSourceMatchKeysInput.value.map(
            (key) => {
              return { key, value: "" };
            }
          ),
        },
      ];

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid: false,
          value: newTargets,
        },
      };
    });
  }

  function handleAddTargetLabel(targetIndex: number): void {
    setState((currentState) => {
      const newTargetLabels = [
        ...currentState.targetsInput.value[targetIndex].identifier.labels,
        { key: "", value: "" },
      ];

      const newTargets = [
        ...currentState.targetsInput.value.slice(0, targetIndex),
        {
          ...currentState.targetsInput.value[targetIndex],
          identifier: {
            projectID:
              currentState.targetsInput.value[targetIndex].identifier.projectID,
            labels: newTargetLabels,
          },
        },
        ...currentState.targetsInput.value.slice(targetIndex + 1),
      ];

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid: false,
          value: newTargets,
        },
      };
    });
  }

  function handleRemoveTargetLabel(
    targetIndex: number,
    labelIndex: number
  ): void {
    setState((currentState) => {
      const newTargetLabels = [
        ...currentState.targetsInput.value[targetIndex].identifier.labels.slice(
          0,
          labelIndex
        ),
        ...currentState.targetsInput.value[targetIndex].identifier.labels.slice(
          labelIndex + 1
        ),
      ];

      const newTargets = [
        ...currentState.targetsInput.value.slice(0, targetIndex),
        {
          ...currentState.targetsInput.value[targetIndex],
          identifier: {
            ...currentState.targetsInput.value[targetIndex].identifier,
            labels: newTargetLabels,
          },
        },
        ...currentState.targetsInput.value.slice(targetIndex + 1),
      ];

      const isValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional,
        type: state.type,
      });

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      props.onInteraction({
        type: ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED,
        targetLabelKeys: getTargetLabelKeys(newTargets),
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid,
          value: newTargets,
        },
      };
    });
  }

  function handleUpdateTargetShare(number: number, index: number): void {
    if (number < 0) return;
    setState((currentState) => {
      const newTargets = [
        ...currentState.targetsInput.value.slice(0, index),
        {
          ...currentState.targetsInput.value[index],
          share: number,
        },
        ...currentState.targetsInput.value.slice(index + 1),
      ];

      const isValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional,
        type: state.type,
      });

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid,
          value: newTargets,
        },
      };
    });
  }

  function handleUpdateTargetSourceMatchValues(options: {
    option: string | null;
    targetIndex: number;
    valueIndex: number;
  }): void {
    let { option } = options;
    const { targetIndex, valueIndex } = options;
    if (option === "null") {
      option = null;
    }

    setState((currentState) => {
      const newTargets = [
        ...currentState.targetsInput.value.slice(0, targetIndex),
        {
          ...currentState.targetsInput.value[targetIndex],
          sourceMatchValues: [
            ...currentState.targetsInput.value[
              targetIndex
            ].sourceMatchValues.slice(0, valueIndex),
            {
              ...currentState.targetsInput.value[targetIndex].sourceMatchValues[
                valueIndex
              ],
              value: option,
            },
            ...currentState.targetsInput.value[
              targetIndex
            ].sourceMatchValues.slice(valueIndex + 1),
          ],
        },
        ...currentState.targetsInput.value.slice(targetIndex + 1),
      ];

      const isValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional,
        type: state.type,
      });

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid,
          value: newTargets,
        },
      };
    });
  }

  function handleUpdateTargetProject(option, index: number): void {
    setState((currentState) => {
      const newTargets = [
        ...currentState.targetsInput.value.slice(0, index),
        {
          ...currentState.targetsInput.value[index],
          identifier: {
            ...currentState.targetsInput.value[index].identifier,
            projectID: option,
          },
        },
        ...currentState.targetsInput.value.slice(index + 1),
      ];

      const isValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional,
        type: state.type,
      });

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid,
          value: newTargets,
        },
      };
    });
  }

  function handleUpdateTargetLabel(
    option,
    targetIndex: number,
    labelIndex: number,
    updateKey: "key" | "value"
  ) {
    setState((currentState) => {
      const newLabels = [
        ...currentState.targetsInput.value[targetIndex].identifier.labels.slice(
          0,
          labelIndex
        ),
        {
          ...currentState.targetsInput.value[targetIndex].identifier.labels[
            labelIndex
          ],
          [updateKey]: option,
        },
        ...currentState.targetsInput.value[targetIndex].identifier.labels.slice(
          labelIndex + 1
        ),
      ];

      const newTargets = [
        ...currentState.targetsInput.value.slice(0, targetIndex),
        {
          ...currentState.targetsInput.value[targetIndex],
          identifier: {
            ...currentState.targetsInput.value[targetIndex].identifier,
            labels: newLabels,
          },
        },
        ...currentState.targetsInput.value.slice(targetIndex + 1),
      ];

      const isValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional,
        type: state.type,
      });

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      props.onInteraction({
        type: ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED,
        targetLabelKeys: getTargetLabelKeys(newTargets),
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid,
          value: newTargets,
        },
      };
    });
  }

  function handleRemoveTarget(index: number) {
    setState((currentState) => {
      const newTargets = [
        ...currentState.targetsInput.value.slice(0, index),
        ...currentState.targetsInput.value.slice(index + 1),
      ];

      const isValid = getTargetsValidity({
        targets: newTargets,
        targetsAreOptional,
        type: state.type,
      });

      const hasChanged = getTargetsEquality({
        existingReallocation: props.reallocation,
        newTargets: newTargets,
        type: state.type,
      });

      props.onInteraction({
        type: ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED,
        targetLabelKeys: getTargetLabelKeys(newTargets),
      });

      return {
        ...currentState,
        targetsInput: {
          hasChanged,
          isValid,
          value: newTargets,
        },
      };
    });
  }

  function handleCancel(): void {
    props.onInteraction({
      type: ReallocationForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });

    setState(initialState);
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    const typeConfig =
      state.type === ReallocationType.STATIC
        ? {
            type: ReallocationType.STATIC,
            targets: state.targetsInput.value.map((target) => {
              return {
                identifier: {
                  projectID: target.identifier.projectID,
                  labels: target.identifier.labels.reduce((accum, label) => {
                    return Object.assign(accum, { [label.key]: label.value });
                  }, {}),
                },
                share: target.share / 100,
              };
            }),
          }
        : {
            type: ReallocationType.DYNAMIC,
            dataSource: DataSource.BILLING,
            filters: state.dynamicFiltersInput.value,
            measure: state.dynamicMeasureInput.value,
            minimumShare: state.dynamicMinimumShareInput.value / 100,
            sourceMatchKeys: state.dynamicSourceMatchKeysInput.value,
            targets: state.targetsInput.value.map((target) => {
              return {
                identifier: {
                  projectID: target.identifier.projectID,
                  labels: target.identifier.labels.reduce(
                    (object, label) =>
                      Object.assign(object, { [label.key]: label.value }),
                    {}
                  ),
                },
                sourceMatchValues: target.sourceMatchValues.reduce(
                  (accum, value) => {
                    return Object.assign(accum, { [value.key]: value.value });
                  },
                  {}
                ),
              };
            }),
          };

    if (props.action === Action.CREATE || props.action === Action.COPY) {
      props.onInteraction({
        type: ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        dataSource: DataSource.BILLING,
        dimensions: state.groupingsInput.value,
        endTime: state.endDateInput.value,
        filters: state.filtersInput.value,
        measure: "cost",
        name: state.nameInput.value,
        rebuildType: state.rebuildTypeInput.value,
        startTime: state.startDateInput.value,
        typeConfig: typeConfig,
      });
    } else {
      if (!props.reallocation) return;

      const dynamicFieldsHaveChanged =
        state.dynamicDataSourceInput.hasChanged ||
        state.dynamicFiltersInput.hasChanged ||
        state.dynamicMeasureInput.hasChanged ||
        state.dynamicMinimumShareInput.hasChanged ||
        state.dynamicSourceMatchKeysInput.hasChanged;

      props.onInteraction({
        type: ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        reallocationID: props.reallocation.id,
        ...(state.groupingsInput.hasChanged
          ? { dimensions: state.groupingsInput.value }
          : {}),
        ...(state.endDateInput.hasChanged
          ? { endTime: state.endDateInput.value }
          : {}),
        ...(state.filtersInput.hasChanged
          ? { filters: state.filtersInput.value }
          : {}),
        ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
        ...(state.rebuildTypeInput.hasChanged
          ? { rebuildType: state.rebuildTypeInput.value }
          : {}),
        ...(state.startDateInput.hasChanged
          ? { startTime: state.startDateInput.value }
          : {}),
        ...(dynamicFieldsHaveChanged || state.targetsInput.hasChanged
          ? { typeConfig: typeConfig }
          : {}),
      });
    }
  }

  //
  // Validations
  //

  const hasChanged = Object.values(state).some((input) => input.hasChanged);

  const isValid =
    state.type === ReallocationType.DYNAMIC
      ? Object.values(state).every((input) => input.isValid !== false)
      : Object.entries(state).every(([input, value]) => {
          if (
            [
              "dimensionsInput",
              "endDateInput",
              "filtersInput",
              "nameInput",
              "rebuildTypeInput",
              "startDateInput",
              "targetsInput",
            ].includes(input)
          ) {
            return value.isValid !== false;
          } else {
            return true;
          }
        });

  const canSubmit =
    props.action === Action.UPDATE ? hasChanged && isValid : isValid;

  //
  // Render
  //

  const dataSourceOptions = [
    {
      label: copyText.dataSource_BILLING_label,
      value: DataSource.BILLING,
    },
  ];

  const dimensionOptions = props.availableDimensions.map((dimension) => ({
    label: dimension.name,
    value: dimension.name,
  }));

  const filterOptions = props.availableDimensions.reduce(
    (accum: Option[], availableDimension) =>
      state.filtersInput.value.some(
        (filterValue) => filterValue.name === availableDimension.name
      )
        ? accum
        : [
            ...accum,
            { label: availableDimension.name, value: availableDimension.name },
          ],
    []
  );

  const dynamicFilterOptions = props.availableDimensions.reduce(
    (accum: Option[], availableDimension) =>
      state.dynamicFiltersInput.value.some(
        (filterValue) => filterValue.name === availableDimension.name
      )
        ? accum
        : [
            ...accum,
            { label: availableDimension.name, value: availableDimension.name },
          ],
    []
  );

  const typeOptions = [
    {
      label: copyText.reallocationFormStaticLabel,
      value: ReallocationType.STATIC,
    },
    {
      label: copyText.reallocationFormDynamicLabel,
      value: ReallocationType.DYNAMIC,
    },
  ];

  const selectedTypeOption =
    state.type === ReallocationType.STATIC ? typeOptions[0] : typeOptions[1];

  const groupedFilterOptions = props.isLoadingDimensionPreferences
    ? []
    : groupOptionsByPreferences(
        filterOptions,
        props.dimensionPreferences,
        DataSource.BILLING,
        gatekeeper.isLabelPreferenceAdmin
      );

  const dynamicGroupedFilterOptions = props.isLoadingDimensionPreferences
    ? []
    : groupOptionsByPreferences(
        dynamicFilterOptions,
        props.dimensionPreferences,
        DataSource.BILLING,
        gatekeeper.isLabelPreferenceAdmin
      );

  function renderFilterValueSelect(
    filter: ReallocationFilter,
    index: number,
    dynamic?: boolean
  ): JSX.Element | undefined {
    const values = props.dimensionValuesMap
      ? props.dimensionValuesMap[filter.name]
      : [];

    const valueOptions = values
      ? values.map((value) => ({ label: value, value }))
      : [];

    switch (filter.operator) {
      case Operator.EQUALS:
      case Operator.NOT_EQUALS: {
        return (
          <SelectCheckbox
            compact
            isLoading={props.isLoadingDimensionValuesMap}
            options={valueOptions}
            placeholder={copyText.selectFilterNameLabel}
            selectedValues={filter.values ?? []}
            onChange={(values: string[]) =>
              handleUpdateFilterValues(values, index, dynamic)
            }
          />
        );
      }
      case Operator.CONTAINS:
      case Operator.NOT_CONTAINS: {
        return (
          <Select
            closeMenuOnSelect={false}
            compact
            inputValue={filter.values?.[0]}
            isLoading={props.isLoadingDimensionValuesMap}
            options={valueOptions}
            placeholder={copyText.selectFilterNameLabel}
            searchable
            onChange={(option) =>
              option
                ? handleUpdateFilterValues([option.value], index, dynamic)
                : noop
            }
            onInputChange={(value, { action }) => {
              if (["input-blur", "menu-close"].includes(action)) return;
              handleUpdateFilterValues([value], index, dynamic);
            }}
          />
        );
      }
      case Operator.SET:
      case Operator.NOT_SET:
        return undefined;
    }
  }

  function renderSourceMatchValueInput(options: {
    sourceMatchKey: string;
    sourceMatchValue: string;
    targetIndex: number;
    valueIndex: number;
  }) {
    const { sourceMatchKey, targetIndex, valueIndex } = options;

    const sourceMatchValue =
      typeof options.sourceMatchValue === "string"
        ? options.sourceMatchValue
        : "null";

    const nullOption = {
      label: copyText.reallocationFormNullValueOption,
      value: "null",
    };

    const sourceMatchValueOptions =
      props.dimensionValuesMap[sourceMatchKey]?.map((value) => {
        return { label: value, value };
      }) ?? [];

    sourceMatchValueOptions.push(nullOption);

    return (
      <Box width={190} key={`${targetIndex}-${valueIndex}`}>
        <FormField label={sourceMatchKey} required>
          <Select
            compact
            isLoading={props.isLoadingDimensionValuesMap}
            menuPlacement="bottom"
            options={sourceMatchValueOptions}
            value={
              sourceMatchValue === "null"
                ? nullOption
                : {
                    label: sourceMatchValue,
                    value: sourceMatchValue,
                  }
            }
            onChange={(option) =>
              option
                ? handleUpdateTargetSourceMatchValues({
                    option: option.value,
                    targetIndex,
                    valueIndex,
                  })
                : noop
            }
          />
        </FormField>
      </Box>
    );
  }

  const rebuildTypeOptions = [
    {
      label: copyText.reallocationRebuildTypeOneoffLabel,
      value: ReallocationRebuildType.ONEOFF,
    },
    {
      label: copyText.reallocationRebuildTypeScheduledLabel,
      value: ReallocationRebuildType.SCHEDULED,
    },
  ];

  const minEndDate = new Date(state.startDateInput.value);
  minEndDate.setDate(minEndDate.getDate() + 1);

  const projectOptions = props.dimensionValuesMap.projectId?.map(
    (projectId) => {
      return { label: projectId, value: projectId };
    }
  );

  const measureOptions = props.availableMeasures.map((measure) => {
    return { label: measure.name, value: measure.name };
  });

  const overallShareTotal = state.targetsInput.value.reduce((accum, target) => {
    return target.share ? accum + target.share : accum;
  }, 0);

  const targetsAreOptional =
    state.type === ReallocationType.DYNAMIC &&
    state.dynamicSourceMatchKeysInput.value.includes("projectId");

  function renderReallocationConfigTargets() {
    return (
      <Flex direction="column">
        {state.targetsInput.value.map((target, targetIndex) => {
          const currentShareTotal = state.targetsInput.value.reduce(
            (accum, target, index) => {
              if (index === targetIndex) {
                return accum;
              }

              return target.share ? accum + target.share : accum;
            },
            0
          );

          const maxShare = 100 - currentShareTotal;

          const currentShare = state.targetsInput.value[targetIndex].share;

          return (
            <Flex key={targetIndex} direction="column">
              <Flex marginBottom={theme.space_md} />
              {state.type === ReallocationType.DYNAMIC && (
                <Grid gridTemplateColumns="1fr 1fr 1fr">
                  {/* Dynamic Reallocation: Source Match Values */}
                  {target.sourceMatchValues.map(
                    (sourceMatchValuePair, valueIndex) => {
                      return renderSourceMatchValueInput({
                        sourceMatchKey: sourceMatchValuePair.key,
                        sourceMatchValue: sourceMatchValuePair.value,
                        targetIndex,
                        valueIndex,
                      });
                    }
                  )}
                </Grid>
              )}
              <Flex>
                <Box
                  width={190}
                  marginBottom={theme.space_sm}
                  marginRight={theme.space_lg}
                >
                  {/* Project ID */}
                  <FormField
                    label={copyText.reallocationFormProjectLabel}
                    required
                  >
                    <Select
                      compact
                      isLoading={props.isLoadingDimensionValuesMap}
                      isSearchable
                      menuPlacement="bottom"
                      options={projectOptions}
                      value={{
                        label: target.identifier.projectID,
                        value: target.identifier.projectID,
                      }}
                      onChange={(option) =>
                        option
                          ? handleUpdateTargetProject(option.value, targetIndex)
                          : noop
                      }
                    />
                  </FormField>
                  {state.type === ReallocationType.STATIC && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      marginTop={theme.space_xs}
                    >
                      {/* Static Reallocation: Share */}
                      <Text marginRight={theme.space_xs}>
                        {copyText.reallocationFormShareLabel}
                      </Text>
                      <Box width={90}>
                        <Tooltip
                          content={
                            copyText.reallocationFormShareInputDescription
                          }
                          placement="bottom"
                        >
                          <NumberInput
                            variant={
                              overallShareTotal !== 100 || !(currentShare > 0)
                                ? "danger"
                                : "success"
                            }
                            formatter="percent-divided"
                            max={maxShare}
                            value={target.share}
                            onChange={(number) =>
                              handleUpdateTargetShare(number, targetIndex)
                            }
                          />
                        </Tooltip>
                      </Box>
                    </Flex>
                  )}
                </Box>
                <FormField label={copyText.reallocationFormTargetLabelsLabel}>
                  <Box>
                    {/* Target Labels */}
                    {target.identifier.labels.length > 0 ? (
                      target.identifier.labels?.map((label, labelIndex) => {
                        const keyOptions = props.availableDimensions.reduce(
                          (accum: Option[], dimension) => {
                            const usedKeys = state.targetsInput.value[
                              targetIndex
                            ].identifier.labels.map((label) => label.key);

                            if (
                              !usedKeys.find((key) => key === dimension.name)
                            ) {
                              return [
                                ...accum,
                                {
                                  label: dimension.name,
                                  value: dimension.name,
                                },
                              ];
                            } else return accum;
                          },
                          []
                        );

                        const valueOptions = props.dimensionValuesMap[
                          label.key
                        ]?.map((value) => {
                          return { label: value, value };
                        });

                        return (
                          <Flex
                            key={labelIndex}
                            alignItems="center"
                            marginBottom={theme.space_sm}
                            justifyContent="space-between"
                          >
                            <Flex width={190}>
                              <Select
                                compact
                                isLoading={props.isLoadingDimensionValuesMap}
                                menuPlacement="bottom"
                                options={keyOptions}
                                searchable
                                value={[
                                  {
                                    label: label.key,
                                    value: label.key,
                                  },
                                ]}
                                onChange={(option) =>
                                  option
                                    ? handleUpdateTargetLabel(
                                        option.value,
                                        targetIndex,
                                        labelIndex,
                                        "key"
                                      )
                                    : noop
                                }
                              />
                            </Flex>
                            <Text bold marginHorizontal={theme.space_xs}>
                              :
                            </Text>
                            <Flex width={190}>
                              <Select
                                compact
                                isCreatable
                                isLoading={props.isLoadingDimensionValuesMap}
                                menuPlacement="bottom"
                                options={valueOptions}
                                searchable
                                value={[
                                  {
                                    label: label.value,
                                    value: label.value,
                                  },
                                ]}
                                onChange={(option) =>
                                  option
                                    ? handleUpdateTargetLabel(
                                        option.value,
                                        targetIndex,
                                        labelIndex,
                                        "value"
                                      )
                                    : noop
                                }
                              />
                            </Flex>
                            <Flex>
                              <Button
                                iconStart={<Icon icon={faTimes} />}
                                marginLeft={theme.space_xs}
                                size="small"
                                type="button"
                                onClick={() =>
                                  handleRemoveTargetLabel(
                                    targetIndex,
                                    labelIndex
                                  )
                                }
                              />
                            </Flex>
                          </Flex>
                        );
                      })
                    ) : (
                      <Flex
                        alignItems="center"
                        marginBottom={theme.space_sm}
                        justifyContent="space-between"
                      >
                        <Flex width={440} />
                      </Flex>
                    )}
                  </Box>
                </FormField>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                {targetIndex > 0 || targetsAreOptional ? (
                  <Button
                    iconStart={<Icon icon={faTimes} />}
                    size="small"
                    type="button"
                    onClick={() => handleRemoveTarget(targetIndex)}
                  >
                    {copyText.reallocationFormRemoveTargetButtonLabel}
                  </Button>
                ) : (
                  <Box />
                )}
                <Button
                  iconStart={<Icon icon={faPlus} />}
                  secondary
                  size="small"
                  type="button"
                  onClick={() => handleAddTargetLabel(targetIndex)}
                >
                  {copyText.reallocationFormAddLabelButtonLabel}
                </Button>
              </Flex>
              <Divider direction="horizontal" />
            </Flex>
          );
        })}
      </Flex>
    );
  }

  function renderBundleHelper() {
    const bundledSourceMatchKeys = Object.keys(sourceMatchKeyBundles).reduce(
      (accum: string[], key) => {
        if (
          state.dynamicSourceMatchKeysInput.value.includes(key) &&
          !accum.some((dimension) =>
            sourceMatchKeyBundles[key].includes(dimension)
          )
        ) {
          accum.push(key);
        }
        return accum;
      },
      []
    );

    if (bundledSourceMatchKeys.length > 0) {
      return bundledSourceMatchKeys.map((key, index) => {
        const bundle: string[] = sourceMatchKeyBundles[key];

        const [existingValues, remainingValues] = partition(
          bundle,
          (dimension) =>
            state.dynamicSourceMatchKeysInput.value.find(
              (key) => key === dimension
            )
        );

        if (remainingValues.length > 0) {
          return (
            <Flex key={index} alignItems="center">
              <Icon color={theme.feedback_warn} icon={faWarning} />
              <Text
                appearance="link"
                color={theme.text_color_secondary}
                marginLeft={theme.space_md}
                marginTop={theme.space_sm}
                onClick={() => handleClickBundleHelper(remainingValues)}
              >
                {copyText.reallocationFormSourceMatchKeyBundleHelperText
                  .replace("%KEY%", existingValues[0])
                  .replace("%DIMENSIONS%", remainingValues.join(", "))}
              </Text>
            </Flex>
          );
        }
      });
    } else return null;
  }

  //
  // JSX
  //

  return (
    <Form>
      <Flex direction="column" justifyContent="space-between">
        <Flex justifyContent="space-between">
          {/* Name */}
          <Box minWidth="300px" marginRight={theme.space_lg}>
            <FormField
              input={TextInput}
              label={copyText.reallocationFormNameLabel}
              required
              value={state.nameInput.value}
              onChange={(event) => handleChangeName(event.target.value)}
            />
          </Box>
          {/* Data Source: Cannot be changed currently */}
          <FormField label={copyText.reallocationFormSourceLabel}>
            <Tooltip content={copyText.reallocationFormCannotBeChangedWarning}>
              <Select
                menuPlacement="bottom"
                options={dataSourceOptions}
                value={dataSourceOptions[0]}
                disabled
              />
            </Tooltip>
          </FormField>
          {/* Measure: Cannot be changed currently */}
          <FormField label={copyText.reallocationFormMeasureLabel}>
            <Tooltip content={copyText.reallocationFormCannotBeChangedWarning}>
              <Select
                menuPlacement="bottom"
                options={measureOptions}
                value={measureOptions.find(
                  (measure) => measure.value === "cost"
                )}
                disabled
              />
            </Tooltip>
          </FormField>
        </Flex>
        <Divider />
        <Text color={theme.text_color_secondary} marginBottom={theme.space_sm}>
          {copyText.reallocationFormDescriptorFrequency}
        </Text>
        <Box width={150}>
          <FormField label={copyText.reallocationFormRebuildTypeLabel}>
            <Select
              menuPlacement="bottom"
              options={rebuildTypeOptions}
              value={rebuildTypeOptions.find(
                (option) => option.value === state.rebuildTypeInput.value
              )}
              onChange={(option) =>
                option
                  ? mergeState({
                      rebuildTypeInput: {
                        value: option.value,
                        isValid: true,
                        hasChanged: !isEqual(
                          option.value,
                          props.reallocation?.rebuildType
                        ),
                      },
                    })
                  : noop
              }
            />
          </FormField>
        </Box>
        {/* Groupings */}
        <Divider />
        <Text appearance="h3" marginBottom={theme.space_sm}>
          {copyText.reallocationFormHeaderCostOrigin}
        </Text>
        <Text color={theme.text_color_secondary} marginBottom={theme.space_sm}>
          {copyText.reallocationFormDescriptorDateRange}
        </Text>
        <Flex>
          {/* Start Date */}
          <Box marginRight={theme.space_lg}>
            <FormField label={copyText.reallocationFormStartDateLabel} required>
              <DatePicker
                selected={
                  state.startDateInput.value
                    ? new Date(state.startDateInput.value)
                    : null
                }
                onChange={(date) => handleChangeStartDate(date)}
              />
            </FormField>
          </Box>
          {/* End Date */}
          <Box>
            <FormField label={copyText.reallocationFormEndDateLabel}>
              <DatePicker
                isClearable
                minDate={minEndDate}
                selected={
                  state.endDateInput.value
                    ? new Date(state.endDateInput.value)
                    : null
                }
                onChange={(date) => handleChangeEndDate(date)}
              />
            </FormField>
          </Box>
        </Flex>
        <Text color={theme.text_color_secondary} marginBottom={theme.space_sm}>
          {copyText.reallocationFormDescriptorGroupings}
        </Text>
        <Flex direction="column">
          <FormField label={copyText.reallocationFormGroupingsLabel}>
            <Flex height={300} width="100%">
              <DualListbox
                isLoading={false}
                options={dimensionOptions}
                selectedOptions={state.groupingsInput.value}
                onChange={(selectedOptions) => {
                  mergeState({
                    groupingsInput: {
                      value: selectedOptions,
                      isValid: true,
                      hasChanged: true,
                    },
                  });
                }}
              />
            </Flex>
          </FormField>
        </Flex>
      </Flex>

      {/* Filters */}
      <Text color={theme.text_color_secondary} marginBottom={theme.space_sm}>
        {copyText.reallocationFormDescriptorFilters}
      </Text>
      <Flex marginBottom={theme.space_xs} marginTop={theme.space_md}>
        <FormField label={copyText.reallocationFormFiltersLabel}>
          <Box>
            <Flex direction="column">
              {state.filtersInput.value.map((filter, i) => {
                const operatorOption = operatorOptions.find(
                  (option) => option.value === filter.operator
                );

                return (
                  <Flex
                    key={i}
                    alignItems="center"
                    marginBottom={theme.space_xs}
                  >
                    <Box marginRight={theme.space_xs}>
                      <Text>{i + 1}</Text>
                    </Box>
                    <Box marginRight={theme.space_xs} width={190}>
                      <Select
                        compact
                        defaultValue={{
                          label: filter.name,
                          value: filter.name,
                        }}
                        isLoading={props.isLoadingDimensionPreferences}
                        isSearchable
                        options={groupedFilterOptions}
                        placeholder={
                          copyText.reallocationFormSelectFilterNameLabel
                        }
                        onChange={(option) =>
                          option
                            ? handleUpdateFilterName(option.value, i)
                            : noop
                        }
                      />
                    </Box>
                    <Box marginRight={theme.space_xs} width={140}>
                      <Select
                        compact
                        defaultValue={operatorOption}
                        options={operatorOptions}
                        onChange={(option) =>
                          option
                            ? handleUpdateFilterOperator(option.value, i)
                            : noop
                        }
                      />
                    </Box>
                    <Box width={240}>{renderFilterValueSelect(filter, i)}</Box>
                    <Button
                      iconStart={<Icon icon={faTrashAlt} />}
                      size="small"
                      type="button"
                      onClick={() => handleRemoveFilter(i)}
                    />
                  </Flex>
                );
              })}
            </Flex>
            <Button
              iconStart={<Icon icon={faPlus} />}
              size="small"
              type="button"
              onClick={() => handleAddFilter()}
            >
              {copyText.reallocationFormAddFilterButtonLabel}
            </Button>
          </Box>
        </FormField>
      </Flex>
      <Box>
        <Divider />
        <Text appearance="h3" marginBottom={theme.space_sm}>
          {copyText.reallocationFormHeaderCostDestination}
        </Text>
        {/* Type */}
        <Text color={theme.text_color_secondary} marginBottom={theme.space_sm}>
          {copyText.reallocationFormDescriptorType}
        </Text>
        <FormField label={copyText.reallocationFormTypeLabel}>
          <Box width={200}>
            <Select
              menuPlacement="bottom"
              options={typeOptions}
              value={selectedTypeOption}
              onChange={(option) => (option ? handleChangeType(option) : noop)}
            />
          </Box>
        </FormField>
        {state.type === ReallocationType.DYNAMIC && (
          <Flex
            border={`2px solid ${theme.secondary_color_border}`}
            borderRadius={theme.borderRadius_1}
            marginBottom={theme.space_md}
          >
            <Flex direction="column" padding={theme.space_sm} width="100%">
              <Text
                color={theme.text_color_secondary}
                marginBottom={theme.space_sm}
              >
                {copyText.reallocationFormDescriptorMinimumShare}
              </Text>
              <Flex justifyContent="space-between">
                {/* Dynamic Reallocation Fields: Measure */}
                <FormField
                  label={copyText.reallocationFormMeasureLabel}
                  required
                >
                  <Box width={150}>
                    <Select
                      isLoading={false}
                      menuPlacement="bottom"
                      options={measureOptions}
                      value={measureOptions.find(
                        (option) =>
                          option.value === state.dynamicMeasureInput.value
                      )}
                      onChange={(option) =>
                        option
                          ? mergeState({
                              dynamicMeasureInput: {
                                value: option.value,
                                isValid: true,
                                hasChanged: true,
                              },
                            })
                          : noop
                      }
                    />
                  </Box>
                </FormField>
                {/* Dynamic Reallocation Fields: Minimum Share */}
                <FormField label={copyText.reallocationFormMinimumShareLabel}>
                  <Box width={90}>
                    <NumberInput
                      formatter="percent-divided"
                      max={100}
                      value={state.dynamicMinimumShareInput.value}
                      onChange={(number) => {
                        if (number < 0) return;
                        mergeState({
                          dynamicMinimumShareInput: {
                            value: number,
                            isValid: true,
                            hasChanged:
                              props.reallocation?.typeConfig.type ===
                              ReallocationType.DYNAMIC
                                ? !isEqual(
                                    number,
                                    props.reallocation.typeConfig.minimumShare
                                  )
                                : true,
                          },
                        });
                      }}
                    />
                  </Box>
                </FormField>
                {/* Dynamic Reallocation Fields: Data Source: Cannot be changed currently */}
                <FormField label={copyText.reallocationFormSourceLabel}>
                  <Tooltip
                    content={copyText.reallocationFormCannotBeChangedWarning}
                  >
                    <Select
                      menuPlacement="bottom"
                      options={dataSourceOptions}
                      value={dataSourceOptions[0]}
                      disabled
                    />
                  </Tooltip>
                </FormField>
              </Flex>
              {/* Dynamic Reallocation Fields: Source Match Keys */}
              <Divider />
              <Text
                color={theme.text_color_secondary}
                marginBottom={theme.space_sm}
              >
                {copyText.reallocationFormDescriptorSourceMatchKeys}
              </Text>
              <FormField label={copyText.reallocationFormSourceMatchKeysLabel}>
                <Box>
                  <Box height={300} marginBottom={theme.space_md}>
                    <DualListbox
                      isLoading={false}
                      options={dimensionOptions}
                      selectedOptions={state.dynamicSourceMatchKeysInput.value}
                      onChange={(options) =>
                        options
                          ? handleChangeDynamicSourceMatchKeys(options)
                          : noop
                      }
                    />
                  </Box>
                  {props.action !== Action.UPDATE && renderBundleHelper()}
                </Box>
              </FormField>
              <FormField label={copyText.reallocationFormFiltersLabel}>
                <Box>
                  {/* Dynamic Reallocation Fields: Filters */}
                  {state.dynamicFiltersInput.value.map((filter, i) => {
                    const operatorOption = operatorOptions.find(
                      (option) => option.value === filter.operator
                    );

                    return (
                      <Flex
                        key={i}
                        alignItems="center"
                        marginBottom={theme.space_xs}
                      >
                        <Box marginRight={theme.space_xs}>
                          <Text>{i + 1}</Text>
                        </Box>
                        <Box marginRight={theme.space_xs} width={190}>
                          <Select
                            compact
                            defaultValue={{
                              label: filter.name,
                              value: filter.name,
                            }}
                            isLoading={props.isLoadingDimensionPreferences}
                            options={dynamicGroupedFilterOptions}
                            placeholder={
                              copyText.reallocationFormSelectLabelLabel
                            }
                            onChange={(option) =>
                              option
                                ? handleUpdateFilterName(option.value, i, true)
                                : noop
                            }
                          />
                        </Box>
                        <Box marginRight={theme.space_xs} width={140}>
                          <Select
                            compact
                            defaultValue={operatorOption}
                            options={operatorOptions}
                            onChange={(option) =>
                              option
                                ? handleUpdateFilterOperator(
                                    option.value,
                                    i,
                                    true
                                  )
                                : noop
                            }
                          />
                        </Box>
                        <Box width={240}>
                          {renderFilterValueSelect(filter, i, true)}
                        </Box>
                        <Button
                          iconStart={<Icon icon={faTrashAlt} />}
                          size="small"
                          type="button"
                          onClick={() => handleRemoveFilter(i, true)}
                        />
                      </Flex>
                    );
                  })}
                  <Button
                    iconStart={<Icon icon={faPlus} />}
                    size="small"
                    type="button"
                    onClick={() => handleAddFilter(true)}
                  >
                    {copyText.reallocationFormAddFilterButtonLabel}
                  </Button>
                </Box>
              </FormField>
            </Flex>
          </Flex>
        )}
        {/* Targets */}
        <Text color={theme.text_color_secondary} marginBottom={theme.space_sm}>
          {copyText.reallocationFormDescriptorTargets}
        </Text>
        <Text>{copyText.reallocationFormTargetsLabel}</Text>
        <Flex>{renderReallocationConfigTargets()}</Flex>
        {state.type === ReallocationType.STATIC &&
          state.targetsInput.value.length > 0 &&
          overallShareTotal < 100 && (
            <Text color={theme.feedback_negative} marginBottom={theme.space_xs}>
              {copyText.reallocationFormShareInputDescription}
            </Text>
          )}
        <Button
          iconStart={<Icon icon={faPlus} />}
          size="small"
          type="button"
          onClick={handleAddTarget}
        >
          {copyText.reallocationFormAddTargetButtonLabel}
        </Button>
        {!targetsAreOptional && state.targetsInput.value.length < 1 && (
          <Text color={theme.feedback_negative} marginTop={theme.space_xs}>
            {copyText.reallocationFormUnfinishedTargetsWarning}
          </Text>
        )}
      </Box>
      <Flex
        justifyContent="flex-end"
        marginTop={theme.space_sm}
        paddingBottom={theme.space_sm}
      >
        <Button
          disabled={props.isProcessing}
          secondary
          type="reset"
          width={100}
          onClick={handleCancel}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing}
          marginLeft={theme.space_sm}
          primary
          type="button"
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

function getFiltersEquality(options: {
  dynamic: boolean;
  existingReallocation?: Reallocation;
  newFilters: ReallocationFilter[];
}): boolean {
  const { dynamic, existingReallocation, newFilters } = options;

  if (dynamic) {
    if (existingReallocation?.typeConfig.type === ReallocationType.DYNAMIC) {
      return !isEqual(newFilters, existingReallocation.typeConfig.filters);
    } else {
      return true;
    }
  } else {
    return !isEqual(newFilters, existingReallocation?.filters);
  }
}

function getTargetsEquality(options: {
  existingReallocation?: Reallocation;
  newTargets: ReallocationTarget[];
  type: ReallocationType;
}): boolean {
  const { existingReallocation, newTargets, type } = options;

  const targetsWithRelevantFields = newTargets.map((target) => ({
    identifier: {
      projectID: target.identifier.projectID,
      labels: target.identifier.labels.reduce((accum, label) => {
        return Object.assign(accum, { [label.key]: label.value });
      }, {}),
    },
    ...(type === ReallocationType.STATIC
      ? { share: target.share / 100 }
      : {
          sourceMatchValues: target.sourceMatchValues.reduce((accum, value) => {
            return Object.assign(accum, { [value.key]: value.value });
          }, {}),
        }),
  }));

  return !isEqual(
    targetsWithRelevantFields,
    existingReallocation?.typeConfig.targets
  );
}

export function getTargetLabelKeys(newTargets: ReallocationTarget[]): string[] {
  return newTargets.reduce((accum: string[], target) => {
    const keys = target.identifier.labels.reduce((accum: string[], label) => {
      if (label.key) {
        return accum.concat(label.key);
      } else return accum;
    }, []);

    return uniq(accum.concat(keys));
  }, []);
}

function getTargetsValidity(options: {
  targets: ReallocationTarget[];
  targetsAreOptional: boolean;
  type: ReallocationType;
}) {
  const { targets, targetsAreOptional, type } = options;

  const shareTotal = targets.reduce((accum, target) => {
    return target.share ? accum + target.share : accum;
  }, 0);

  return (
    (targetsAreOptional ? true : targets.length > 0) &&
    targets.every(
      (target) =>
        (type === ReallocationType.STATIC
          ? target.share
          : target.sourceMatchValues.every(
              (value) => value.value === null || value.value?.length > 0
            )) &&
        target.identifier.projectID &&
        target.identifier.labels.every((label) => label.key && label.value)
    ) &&
    (type === ReallocationType.STATIC ? shareTotal === 100 : true)
  );
}

ReallocationForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "ReallocationForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

ReallocationForm.INTERACTION_FILTER_NAME_CHANGED =
  "ReallocationForm.INTERACTION_FILTER_NAME_CHANGED" as const;

ReallocationForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED =
  "ReallocationForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED" as const;

ReallocationForm.INTERACTION_SOURCE_MATCH_KEYS_CHANGED =
  "ReallocationForm.INTERACTION_SOURCE_MATCH_KEYS_CHANGED" as const;

ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  "ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;

ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED =
  "ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED" as const;

type InteractionCancelButtonClicked = {
  type: typeof ReallocationForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

interface InteractionFilterNameChanged {
  type: typeof ReallocationForm.INTERACTION_FILTER_NAME_CHANGED;
  dynamic: boolean;
  index: number;
  filter: ReallocationFilter;
}

interface InteractionRemoveFilterButtonClicked {
  type: typeof ReallocationForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED;
  dynamic: boolean;
  index: number;
}

type InteractionSourceMatchKeysChanged = {
  type: typeof ReallocationForm.INTERACTION_SOURCE_MATCH_KEYS_CHANGED;
  keys: string[];
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  dataSource: DataSource;
  dimensions: string[];
  endTime: string | null;
  filters: ReallocationFilter[];
  measure: string;
  name: string;
  rebuildType: ReallocationRebuildType;
  startTime: string;
  typeConfig: ReallocationConfigDynamic | ReallocationConfigStatic;
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  reallocationID: string;
  dataSource?: DataSource;
  dimensions?: string[];
  endTime?: string | null;
  filters?: ReallocationFilter[];
  measure?: string;
  name?: string;
  rebuildType?: ReallocationRebuildType;
  startTime?: string;
  typeConfig?: ReallocationConfigDynamic | ReallocationConfigStatic;
};

type InteractionTargetLabelKeysChanged = {
  type: typeof ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED;
  targetLabelKeys: string[];
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReallocationForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionFilterNameChanged
    | InteractionRemoveFilterButtonClicked
    | InteractionSourceMatchKeysChanged
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate
    | InteractionTargetLabelKeysChanged;
}

export default ReallocationForm;
