import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateDashboardSubscriptionParameters } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { subscriptionID: string } & UpdateDashboardSubscriptionParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { subscriptionID: string } & UpdateDashboardSubscriptionParameters
>;

export default function useUpdateDashboardSubscription(
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ subscriptionID, ...params }) =>
      client.updateDashboardSubscription(subscriptionID, params),
    ...options,
  });
}
