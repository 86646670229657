import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { Role } from "@ternary/api-lib/constants/roles";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours } from "date-fns";
import { keyBy } from "lodash";
import React, { useState } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGrantUsersTenantAccess from "../../admin/hooks/useGrantUsersTenantAccess";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import { StatusVariant } from "../constants";
import copyText from "../copyText";
import useGetMspChildDataIntegrationsByParentTenantID from "../hooks/useGetMspChildDataIntegrations";
import MspDataIntegrationTable from "./MspDataIntegrationTable";

type Interaction = MspDataIntegrationTable.Interaction;

type State = {
  selectedTenantID: string | null;
  showModal: boolean;
  statusOptions: string[];
};

const initialState: State = {
  selectedTenantID: null,
  showModal: false,
  statusOptions: [],
};

const statusOptions = Object.values(StatusVariant).map((value) => ({
  label: copyText[`statusVariantLabel_${value}`],
  value,
}));

interface Props {
  parentTenantID: string;
}

export default function MspDataIntegrationViewContainer(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const { data: _integrations = [], isLoading: isLoadingIntegrations } =
    useGetMspChildDataIntegrationsByParentTenantID(props.parentTenantID);

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspDataIntegrationTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      grants: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roles: [Role.TENANT_ADMIN],
        },
      ],
    });
  }

  //
  // Render
  //

  const isLoading = isLoadingIntegrations || isLoadingTenants;

  const datehelper = new DateHelper();

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  let integrations = _integrations.map((integration) => {
    const absoluteRefresh = integration.latestRefresh
      ? new Date(integration.latestRefresh)
      : null;

    const absoluteUpstream = integration.latestUpstreamTimestamp
      ? new Date(integration.latestUpstreamTimestamp)
      : null;

    const refreshDifference = differenceInHours(
      datehelper.date,
      absoluteRefresh ?? 0
    );

    const upstreamDifference = differenceInHours(
      datehelper.date,
      absoluteUpstream ?? 0
    );

    let statusVariant: StatusVariant = StatusVariant.DANGER;

    if (refreshDifference <= 8 && upstreamDifference <= 24) {
      statusVariant = StatusVariant.SUCCESS;
    } else if (refreshDifference <= 24 && upstreamDifference <= 72) {
      statusVariant = StatusVariant.WARNING;
    }

    return {
      tenantDocID: integration.tenantDocID,
      latestRefresh: integration.latestRefresh,
      latestUpstream: integration.latestUpstreamTimestamp,
      name: integration.name,
      refreshDifference,
      statusVariant,
      tenantName: tenantsKeyedByDocID[integration.tenantDocID]?.name ?? "null",
    };
  });

  if (state.statusOptions.length > 0) {
    integrations = integrations.filter((integration) =>
      state.statusOptions.includes(integration.statusVariant)
    );
  }

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        <Text appearance="h3">{copyText.sectionHeaderCloudDataStatus}</Text>
        <SelectCheckbox
          options={statusOptions}
          placeholder={copyText.inputPlaceholderFilterStatus}
          selectedValues={state.statusOptions}
          width={250}
          onChange={(value) => {
            mergeState({ statusOptions: value.sort() });
          }}
        />
      </Flex>
      <MspDataIntegrationTable
        integrations={integrations}
        isLoading={isLoading}
        onInteraction={handleInteraction}
      />
    </Box>
  );
}
