import { UpdateRecommendationParameters } from "@/api/core/types";
import useGatekeeper from "@/hooks/useGatekeeper";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CloudProviderType,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { keyBy, noop } from "lodash";
import React, { useEffect, useState } from "react";
import { DateHelper } from "../../../lib/dates";
import DatePicker from "../../../ui-lib/components/DatePicker";
import { FormField } from "../../../ui-lib/components/Form";
import MarkdownWrapper from "../../../ui-lib/components/MarkdownWrapper";
import Select from "../../../ui-lib/components/Select";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

export const SIDE_DRAWER_WIDTH = "55rem";

type RecommendationDetail = {
  key: string;
  value: string;
};

type RecommendationLabel = {
  key: string;
  value: string;
};

type RecommendationType = {
  id: string;
  detailTemplate: string;
  displayName: string;
};

type Recommendation = {
  id: string;
  applicable: boolean;
  cloudProviderType: CloudProviderType;
  details: RecommendationDetail[];
  estimateCurrency: string;
  estimateValue: number;
  labels: RecommendationLabel[];
  number: string;
  projectID: string;
  resource: string;
  snoozeUntil: string | null;
  state: string;
  typeID: string;
};

type State = {
  applicable: boolean;
  inputStatus: string;
  status: Recommendation["state"];
  snoozeUntil: string | null;
};

interface Props {
  cloudProviderType: CloudProviderType;
  isOpen: boolean;
  loadingSubmit: boolean;
  recommendation: Recommendation | undefined;
  recommendationTypes: RecommendationType[];
  onClose: () => void;
  onSubmitModification: (
    params: { recommendationID: string } & UpdateRecommendationParameters
  ) => void;
}

export const recommendationStatusText = {
  accepted: copyText.recommendationStatusAccepted,
  asDesigned: copyText.recommendationStatusAsDesigned,
  implemented: copyText.recommendationStatusImplemented,
  inaccurate: copyText.recommendationStatusInaccurate,
  new: copyText.recommendationStatusNew,
};

const statusOptions = Object.keys(recommendationStatusText).map((key) => ({
  value: key,
  label: recommendationStatusText[key],
}));

const emptyState: State = {
  applicable: false,
  inputStatus: "",
  status: "",
  snoozeUntil: null,
};

export default function RecommendationDetails(props: Props) {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  const caseManagement = useCaseManagementStore();

  const [state, setState] = useState<State>(emptyState);
  const mergeState = getMergeState(setState);

  const now = new DateHelper();

  useEffect(() => {
    if (props.isOpen === false) {
      mergeState(emptyState);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (!props.recommendation) return;

    const applicable = props.recommendation.applicable;
    const inputStatus = props.recommendation.state;
    const snoozeUntil = props.recommendation.snoozeUntil as string;

    mergeState({
      applicable,
      inputStatus,
      snoozeUntil: snoozeUntil ?? null,
    });
  }, [props.recommendation]);

  const recommendationTypesKeyedByID = keyBy(props.recommendationTypes, "id");

  const recommendationType = props.recommendation
    ? (recommendationTypesKeyedByID[props.recommendation.typeID] ?? "")
    : "";

  const selectedStatus = statusOptions.find(
    (status) => status.value === state.inputStatus
  );

  function canSubmitModifiedRec(): boolean {
    if (!props.recommendation) return false;

    return (
      state.inputStatus !== props.recommendation.state ||
      state.applicable !== props.recommendation.applicable ||
      state.snoozeUntil !== props.recommendation.snoozeUntil
    );
  }

  function handleSubmit() {
    if (!props.recommendation) return;

    if (canSubmitModifiedRec()) {
      props.onSubmitModification({
        recommendationID: props.recommendation.id,
        ...(state.snoozeUntil ? { snoozeUntil: state.snoozeUntil } : {}),
        ...(state.inputStatus !== props.recommendation.state
          ? { state: state.inputStatus }
          : {}),
        ...(state.applicable !== props.recommendation.applicable
          ? { applicable: state.applicable }
          : {}),
      });
    }
  }

  const projectID = props.recommendation ? props.recommendation.projectID : "";
  const gcpLink = `https://console.cloud.google.com/home/dashboard?project=${projectID}`;

  if (!props.recommendation) return null;

  const canUpdate = gatekeeper.canUpdateRecommendations;

  return (
    <Modal
      title={
        props.recommendation
          ? `${recommendationType && recommendationType.displayName} (#${
              props.recommendation?.id
            })`
          : "--"
      }
      isOpen={props.isOpen}
      width={SIDE_DRAWER_WIDTH}
      onClose={props.onClose}
    >
      <Box
        height={450}
        minWidth={`calc(${SIDE_DRAWER_WIDTH} - ${theme.space_jumbo})`}
        overflow="scroll"
      >
        <Box marginBottom={theme.space_md}>
          <MarkdownWrapper>
            {recommendationType && recommendationType.detailTemplate}
          </MarkdownWrapper>
        </Box>
        {renderDetails(props.recommendation.details)}
        <Flex justifyContent="space-between" marginVertical={theme.space_md}>
          <div>
            <Text as="h3" appearance="h3">
              {copyText.recommendationDetailsHeaderLabels}
            </Text>
            {renderLabels(props.recommendation.labels)}
          </div>

          <div>
            <Text as="h3" appearance="h3">
              {copyText.recommendationDetailsHeaderResource}
            </Text>
            <Text>{props.recommendation.resource}</Text>
          </div>

          <div>
            <Text as="h3" appearance="h3">
              {props.cloudProviderType === CloudProviderType.GCP
                ? copyText.recommendationDetailsHeaderProject
                : copyText.recommendationDetailsHeaderLinkedAccount}
            </Text>
            <Text>
              {props.cloudProviderType === CloudProviderType.GCP ? (
                <a
                  href={gcpLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  {projectID}
                </a>
              ) : (
                projectID
              )}
            </Text>
          </div>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Box width="48%">
            {!canUpdate && (
              <Box marginRight={theme.space_sm}>
                <FontAwesomeIcon icon={faLock} />
              </Box>
            )}
            <FormField label={copyText.recommendationDetailsHeaderChangeStatus}>
              <Box flexGrow={1}>
                <Select
                  disabled={props.loadingSubmit || !canUpdate}
                  options={statusOptions}
                  placeholder={
                    copyText.recommendationDetailsPlaceholderSelectStatus
                  }
                  value={selectedStatus}
                  onChange={(option) =>
                    option && mergeState({ inputStatus: option.value })
                  }
                />
              </Box>
            </FormField>
          </Box>
        </Flex>
        <Flex>{renderSnoozeControls(canUpdate)}</Flex>
        <Flex justifyContent="space-between">
          <Button
            secondary
            onClick={() => {
              props.recommendation
                ? caseManagement.set({
                    isSideDrawerOpen: true,
                    selectedResourceID: props.recommendation.id,
                    isResourceSelectionMode: false,
                    selectedResourceName: props.recommendation.projectID,
                    selectedResourceType: ResourceType.RECOMMENDATION,
                  })
                : noop();
            }}
          >
            {copyText.recommendationDetailsCreateCaseButtonLabel}
          </Button>
          <Flex justifyContent="flex-end">
            <Button
              disabled={props.loadingSubmit}
              secondary
              onClick={props.onClose}
            >
              {copyText.actionClose}
            </Button>
            <Button
              disabled={
                !canSubmitModifiedRec() || props.loadingSubmit || !canUpdate
              }
              marginLeft={theme.space_md}
              primary
              width={100}
              onClick={handleSubmit}
            >
              {props.loadingSubmit ? <LoadingSpinner /> : copyText.actionSubmit}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );

  function renderSnoozeControls(canUpdate: boolean) {
    const checked = Boolean(state.snoozeUntil);

    return (
      <Flex alignItems="center" minHeight={theme.space_xxl}>
        {canUpdate ? (
          <input
            type="checkbox"
            checked={checked ?? false}
            disabled={props.loadingSubmit || !canUpdate}
            onChange={(e) => {
              if (!e.target.checked) {
                mergeState({ snoozeUntil: null });
              } else {
                mergeState({ snoozeUntil: now.date.toISOString() });
              }
            }}
          />
        ) : (
          <Box marginRight={theme.space_sm}>
            <FontAwesomeIcon icon={faLock} />
          </Box>
        )}
        {checked ? (
          <>
            <Text marginHorizontal={theme.space_md} marginBottom="0 !important">
              {copyText.recommendationDetailsSnoozeLabel}
            </Text>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              disabled={props.loadingSubmit || !canUpdate}
              minDate={now.date}
              selected={
                typeof state.snoozeUntil === "string"
                  ? new Date(state.snoozeUntil)
                  : null
              }
              onChange={(date) => {
                mergeState({ snoozeUntil: date ? date.toISOString() : null });
              }}
            />
          </>
        ) : (
          <Text marginHorizontal={theme.space_md} marginBottom="0 !important">
            {copyText.recommendationDetailsSnoozeToggle}
          </Text>
        )}
      </Flex>
    );
  }

  function renderDetails(details: RecommendationDetail[] | null) {
    if (!details || details.length === 0) return null;

    return (
      <table>
        <tbody>
          {details
            .filter((detail) => !detail.key.includes("CHART"))
            .map((detail) => (
              <tr key={detail.key}>
                <td style={{ verticalAlign: "top" }}>
                  <Text bold marginRight="1rem" marginTop={theme.space_md}>
                    {detail.key}
                  </Text>
                </td>
                <td>
                  <Text marginTop={theme.space_md}>{detail.value}</Text>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  function renderLabels(labels: RecommendationLabel[] | null) {
    if (!labels || labels.length === 0) return null;

    return (
      <table>
        <tbody>
          {labels.map((label) => (
            <tr key={label.key}>
              <td style={{ verticalAlign: "top" }}>
                <Text bold marginRight="1rem">
                  {label.key}
                </Text>
              </td>
              <td>
                <Text>{label.value}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
