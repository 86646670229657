import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type BudgetEntity = Awaited<
  ReturnType<CoreAPIClient["getBudgetsByTenantID"]>
>[number];

export default function useGetBudgetsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<BudgetEntity[], CoreAPIClientError>
): UseQueryResult<BudgetEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["budgets"],
    queryFn: () => client.getBudgetsByTenantID(tenantID),
    enabled: gatekeeper.canListBudgets,
    meta: { errorMessage: copyText.errorLoadingBudgetsMessage },
    ...options,
  });
}
