import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { FunctionComponent } from "react";

interface Props {
  children: React.ReactNode;
  height?: string;
  minHeight?: string;
  title?: string | JSX.Element;
  width?: string;
  renderControls?: () => JSX.Element;
}

const SectionCard: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={props.height}
      marginBottom={theme.space_lg}
      minHeight={props.minHeight}
      width={props.width}
    >
      {props.title || props.renderControls ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          borderBottom={`1px solid ${theme.section_card_border}`}
          padding={theme.space_md}
        >
          {typeof props.title === "string" ? (
            <Text
              fontSize={theme.fontSize_base}
              fontWeight={theme.h3_fontWeight}
            >
              {props.title}
            </Text>
          ) : (
            props.title
          )}
          <Flex flexGrow={1} justifyContent="flex-end">
            {props.renderControls && props.renderControls()}
          </Flex>
        </Flex>
      ) : null}
      <Box padding={theme.space_md}>{props.children}</Box>
    </Box>
  );
};

export default SectionCard;
