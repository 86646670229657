import externalLinks from "@/constants/externalLinks";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import copyText from "../copyText";
import useCreateBill from "../hooks/useCreateBill";
import useDeleteBill from "../hooks/useDeleteBill";
import useGetBillsByTenantID from "../hooks/useGetBillsByTenantID";
import BillTable from "./BillTable";

const MODAL_CREATE = "CREATE";
const MODAL_DELETE = "DELETE";

type Interaction = BillTable.Interaction;

type State = {
  file: string;
  modalKey: string;
  nameInput: string;
  selectedBillID: string;
};

const initialState: State = {
  file: "",
  modalKey: "",
  nameInput: "",
  selectedBillID: "",
};

export default function CostCompareContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: bills = [],
    isLoading: isLoadingBills,
    refetch: refetchBills,
  } = useGetBillsByTenantID(authenticatedUser.tenant.fsDocID);

  //
  // Mutations
  //

  const { isPending: isCreatingBill, mutate: createBill } = useCreateBill({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorCreatingBillMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      refetchBills();

      mergeState({ file: "", modalKey: "", nameInput: "" });

      postAlert({
        message: copyText.successCreatingBillMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isDeletingBill, mutate: deleteBill } = useDeleteBill({
    onError: () => {
      mergeState({ modalKey: "", selectedBillID: "" });
      postAlert({
        message: copyText.errorDeletingBillMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      refetchBills();

      mergeState({ modalKey: "", selectedBillID: "" });
      postAlert({
        message: copyText.successDeletingBillMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Side Effects

  useEffect(() => {
    if (!gatekeeper.canViewCostCompare) {
      navigate("/", { replace: true });
    }
  }, []);

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case BillTable.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE,
          selectedBillID: interaction.billID,
        });
        return;
      }
      case BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED: {
        const { billID } = interaction;

        navigate(billID);
        return;
      }
    }
  }

  function handleCreateBill(name: string): void {
    activityTracker.captureAction(actions.CREATE_BILL);

    if (!state.file) return;

    createBill({
      tenantID: authenticatedUser.tenant.fsDocID,
      cloudProviderType: CloudProviderType.AWS,
      data: state.file,
      name,
    });
  }

  function handleDeleteBill(): void {
    activityTracker.captureAction(actions.DELETE_BILL);

    deleteBill({ billID: state.selectedBillID });
  }

  async function handleFileUpload(file: Blob): Promise<void> {
    const blobString = await file.text();

    const base64CSV = Buffer.from(blobString).toString("base64");

    mergeState({ file: base64CSV });
  }

  //
  // Render
  //

  if (!gatekeeper.canListBills) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  function renderModal() {
    switch (state.modalKey) {
      case MODAL_CREATE: {
        return (
          <Modal
            closeOnClickOutside={false}
            isOpen={true}
            showCloseButton
            width={500}
            onClose={() => mergeState({ modalKey: "", nameInput: "" })}
          >
            <Modal.Header>
              <Text appearance="h4">{copyText.modalTitleCreateBill}</Text>
            </Modal.Header>
            <Modal.Body>
              <FormField
                autoFocus
                input={TextInput}
                label={copyText.nameInputLabel}
                placeholder={copyText.createBillPlaceholder}
                value={state.nameInput}
                onChange={(event) =>
                  mergeState({ nameInput: event.target.value })
                }
              />
              <Box marginBottom={theme.space_sm}>
                <FileUploader
                  handleChange={(file) => handleFileUpload(file)}
                  multiple={false}
                  types={["CSV"]}
                />
              </Box>
              <Text>
                {copyText.modalFooterLearnMore + " "}
                <a
                  href={externalLinks.zendeskCostCompareDocumentation}
                  rel="noreferrer"
                  target="_blank"
                >
                  {copyText.modalFooterLearnMoreLink}
                </a>
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isCreatingBill}
                secondary
                width={70}
                onClick={() =>
                  mergeState({ nameInput: "", modalKey: "", file: "" })
                }
              >
                {copyText.cancelButtonLabel}
              </Button>
              <Button
                disabled={
                  !gatekeeper.canCreateBills ||
                  isCreatingBill ||
                  state.nameInput.length === 0 ||
                  !state.file
                }
                primary
                width={70}
                onClick={() => handleCreateBill(state.nameInput)}
              >
                {isCreatingBill ? <LoadingSpinner /> : copyText.saveButtonLabel}
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }
      case MODAL_DELETE: {
        return (
          <ConfirmationModal
            isLoading={isDeletingBill}
            message={copyText.deleteBillConfirmationMessage}
            title={copyText.deleteBillConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "", selectedBillID: "" })}
            onConfirm={() => handleDeleteBill()}
          />
        );
      }
    }
  }

  return (
    <>
      {renderModal()}
      <Flex justifyContent="flex-end" marginBottom={theme.space_md}>
        <Button
          disabled={!gatekeeper.canCreateBills}
          iconStart={<Icon icon={faPlus} />}
          primary
          onClick={() => mergeState({ modalKey: MODAL_CREATE })}
        >
          {copyText.createBillButtonLabel}
        </Button>
      </Flex>
      <BillTable
        bills={bills}
        isLoading={isLoadingBills}
        onInteraction={handleInteraction}
      />
    </>
  );
}
