import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useState } from "react";
import copyText from "../constants/copyText";
import Modal from "../ui-lib/components/Modal";
import Select from "../ui-lib/components/Select";
import getMergeState from "../utils/getMergeState";

type Tenant = {
  id: string;
  fsDocID: string;
  name: string;
};

interface Props {
  currentTenantID: string;
  isOpen: boolean;
  tenants: Tenant[];
  onClose: () => void;
  onSelect: (tenantId: string) => void;
}

interface State {
  selectedTenantID: string | null;
}

export default function TenantModal(props: Props): JSX.Element {
  const [state, setState] = useState<State>({
    selectedTenantID: props.currentTenantID,
  });
  const mergeState = getMergeState(setState);

  useEffect(() => {
    mergeState({ selectedTenantID: props.currentTenantID });
  }, [props.currentTenantID]);

  function handleSubmit() {
    if (state.selectedTenantID) {
      props.onSelect(state.selectedTenantID);
    }
  }

  const tenantOptions = props.tenants.map((tenant) => ({
    value: tenant.id,
    label: tenant.name,
  }));

  const selectedTenant = tenantOptions.find(
    (option) => option.value === state.selectedTenantID
  );

  return (
    <Modal isOpen={props.isOpen} showCloseButton onClose={props.onClose}>
      <Modal.Header>
        <Text appearance="h4">{copyText.tenantModalTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <Select
          autoFocus
          isSearchable
          options={tenantOptions}
          placeholder={copyText.tenantModalPlaceholder}
          value={selectedTenant}
          onChange={(option) =>
            option && mergeState({ selectedTenantID: option.value })
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button secondary width={75} onClick={props.onClose}>
          {copyText.actionClose}
        </Button>
        <Button primary width={75} onClick={handleSubmit}>
          {copyText.actionSubmit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
