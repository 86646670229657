import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column, SortByFn } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSComputeInstanceGroup,
  AWSComputeInstanceGroupFilters,
} from "../types";

type TableData = {
  diskReadBytes: number;
  diskWriteBytes: number;
  family: string;
  familyType: string;
  instanceCount: number;
  instanceUnits: number;
  lineItemUsageAccountId: string;
  networkInBytes: number;
  networkOutBytes: number;
  operatingSystem: string;
  productMemoryBytes: number;
  productVCPU: number;
  region: string;
  totalCost: number;
};

type Props = {
  instanceGroups: AWSComputeInstanceGroup[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: AWSComputeInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

function AWSComputeInstanceGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        diskReadBytes: instanceGroup.diskReadBytes,
        diskWriteBytes: instanceGroup.diskWriteBytes,
        family: instanceGroup.family,
        familyType: instanceGroup.familyType,
        instanceCount: instanceGroup.instanceIDCount,
        instanceUnits: instanceGroup.instanceUnits,
        lineItemUsageAccountId: instanceGroup.lineItemUsageAccountId,
        networkInBytes: instanceGroup.networkInBytes,
        networkOutBytes: instanceGroup.networkOutBytes,
        operatingSystem: instanceGroup.operatingSystem,
        productMemoryBytes: instanceGroup.productMemoryBytes,
        productVCPU: instanceGroup.productVCPU,
        region: instanceGroup.region,
        totalCost: instanceGroup.totalCost,
      })),
    [props.instanceGroups]
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "viewInstances",
        NoAccessorCell: ({ row }) => {
          return (
            <Tooltip content={"View Instances"}>
              <LinkWithSearchParams
                searchParams={{
                  selected_group: getSelectedGroupJSON(row.original),
                }}
              >
                <Button
                  iconStart={<Icon icon={faTableList} />}
                  primary
                  size="tiny"
                />
              </LinkWithSearchParams>
            </Tooltip>
          );
        },
        disableSortBy: true,
        Header: "",
        width: 50,
      },
      {
        accessor: "family",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "family",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsComputeTableNull}
          </Text>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_family,
        sortType: stringSort,
        width: 60,
      },
      {
        accessor: "region",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsComputeTableNull}
          </Text>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_region,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "lineItemUsageAccountId",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "lineItemUsageAccountId",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsComputeTableNull}
          </Text>
        ),
        Header: (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsComputeTableInstanceGroupHeader_lineItemUsageAccountId}
          </Tooltip>
        ),
        sortType: stringSort,
        width: 160,
      },
      {
        accessor: "operatingSystem",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "operatingSystem",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsComputeTableNull}
          </Text>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_operatingSystem,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "familyType",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "familyType",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsComputeTableNull}
          </Text>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_familyType,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "instanceCount",
        align: "right",
        Header: copyText.awsComputeTableInstanceGroupHeader_instanceCount,
        sortDescFirst: true,
        sortType: numberSort,
        width: 140,
      },
      {
        accessor: "instanceUnits",
        align: "right",
        Cell: ({ value: usageUnit }) => (
          <>{usageUnit || copyText.awsComputeTableNull}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_instanceUnits,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_totalCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "productVCPU",
        align: "right",
        Header: copyText.awsComputeTableInstanceGroupHeader_productVCPU,
        sortDescFirst: true,
        sortType: numberSort,
        width: 145,
      },
      {
        accessor: "diskReadBytes",
        align: "right",
        Cell: ({ value: diskReadBytes }) => (
          <>{prettyBytes(diskReadBytes, { binary: true })}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_diskReadBytes,
        sortDescFirst: true,
        width: 110,
      },
      {
        accessor: "diskWriteBytes",
        align: "right",
        Cell: ({ value: diskWriteBytes }) => (
          <>{prettyBytes(diskWriteBytes, { binary: true })}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_diskWriteBytes,
        sortDescFirst: true,
        sortType: numberSort,
        width: 110,
      },
      {
        accessor: "productMemoryBytes",
        align: "right",
        Cell: ({ value: productMemoryBytes }) => (
          <>{prettyBytes(productMemoryBytes, { binary: true })}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_productMemoryBytes,
        sortDescFirst: true,
        sortType: numberSort,
        width: 140,
      },
      {
        accessor: "networkInBytes",
        align: "right",
        Cell: ({ value: networkInBytes }) => (
          <>{prettyBytes(networkInBytes, { binary: true })}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_networkInBytes,
        sortDescFirst: true,
        sortType: numberSort,
        width: 110,
      },
      {
        accessor: "networkOutBytes",
        align: "right",
        Cell: ({ value: networkOutBytes }) => (
          <>{prettyBytes(networkOutBytes, { binary: true })}</>
        ),
        Header: copyText.awsComputeTableInstanceGroupHeader_networkOutBytes,
        sortDescFirst: true,
        sortType: numberSort,
        width: 120,
      },
    ],
    [props.instanceGroups]
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      family: datum.family,
      familyType: datum.familyType,
      lineItemUsageAccountId: datum.lineItemUsageAccountId,
      operatingSystem: datum.operatingSystem,
      region: datum.region,
    });
  } catch (error) {
    return "";
  }
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKeys: (keyof TableData)[] = [
    "diskReadBytes",
    "diskWriteBytes",
    "family",
    "familyType",
    "instanceCount",
    "lineItemUsageAccountId",
    "networkInBytes",
    "networkOutBytes",
    "operatingSystem",
    "productMemoryBytes",
    "productVCPU",
    "region",
    "totalCost",
  ];

  return tableDataKeys.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

const stringSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSComputeInstanceGroupFilters;
  filterValue: AWSComputeInstanceGroupFilters[keyof AWSComputeInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSComputeInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AWSComputeInstanceGroupTable;
