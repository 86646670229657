import useGatekeeper from "@/hooks/useGatekeeper";
import { LinkWithSearchParams } from "@/lib/react-router";
import CSVDownloader from "@/ui-lib/components/CSVDownloader";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { useTheme } from "@emotion/react";
import { faChartLine, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResourceType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import { differenceInCalendarMonths, isAfter, isBefore } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { Skeleton, TableLegacy } from "../../../ui-lib/components/Table";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import {
  CommitmentPeriod,
  NetCostByDateDimensional,
  NetCostByRampPlanID,
  RampPlanBreakpoint,
} from "../types";
import {
  applyOffetsToActualSpend,
  getCSVFromRampPlan,
  getComparisonStringForRampPlans,
  getProjectionsForRampPlan,
} from "../utils/rampPlans";

export const TABLE_INTERACTION_COPY_BUTTON_CLICKED =
  "TABLE_INTERACTION_COPY_BUTTON_CLICKED";
export const TABLE_INTERACTION_CSV_DOWNLOAD_CLICKED =
  "TABLE_INTERACTION_CSV_DOWNLOAD_CLICKED";
export const TABLE_INTERACTION_DELETE_BUTTON_CLICKED =
  "TABLE_INTERACTION_DELETE_BUTTON_CLICKED";
export const TABLE_INTERACTION_DISPLAY_BUTTON_CLICKED =
  "TABLE_INTERACTION_DISPLAY_BUTTON_CLICKED";
export const TABLE_INTERACTION_UPDATE_BUTTON_CLICKED =
  "TABLE_INTERACTION_UPDATE_BUTTON_CLICKED";

const CSV_HEADERS = [
  { label: "Commitment Start", key: "commitmentStart" },
  { label: "Commitment End", key: "commitmentEnd" },
  { label: "Commitment Period", key: "commitmentPeriod" },
  { label: "Grouping", key: "grouping" },
  { label: "Type", key: "type" },
  { label: "Date", key: "date" },
  { label: "Amount", key: "amount" },
];

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

interface TableInteractionCopyButtonClicked {
  type: typeof TABLE_INTERACTION_COPY_BUTTON_CLICKED;
  rampPlanID: string;
}

interface TableInteractionCSVDownloadClicked {
  type: typeof TABLE_INTERACTION_CSV_DOWNLOAD_CLICKED;
  rampPlan: RampPlan;
}

interface TableInteractionDeleteButtonClicked {
  type: typeof TABLE_INTERACTION_DELETE_BUTTON_CLICKED;
  rampPlanID: string;
}

interface TableInteractionDisplayButtonClicked {
  type: typeof TABLE_INTERACTION_DISPLAY_BUTTON_CLICKED;
  rampPlanID: string;
}

interface TableInteractionUpdateButtonClicked {
  type: typeof TABLE_INTERACTION_UPDATE_BUTTON_CLICKED;
  rampPlanID: string;
}

export type RampPlansTableInteraction =
  | TableInteractionCopyButtonClicked
  | TableInteractionCSVDownloadClicked
  | TableInteractionDeleteButtonClicked
  | TableInteractionDisplayButtonClicked
  | TableInteractionUpdateButtonClicked;

interface Props {
  loading: boolean;
  loadingAllActuals: boolean;
  loadingDelete: boolean;
  previousNetCostByRampPlanID: NetCostByRampPlanID;
  rampPlanCSVQueryResultList: {
    rampPlanID: string;
    data: NetCostByDateDimensional[] | undefined;
    isLoading: boolean;
    selectedPricing: string;
  }[];
  rampPlans: RampPlan[];
  selectedRampPlanID: string | undefined;
  onInteraction: (interaction: RampPlansTable.Interaction) => void;
}

interface State {
  rampPlanIDSelectedToDelete?: string;
}

interface TableData {
  id: string;
  actionButtonID: string;
  commitmentPeriodPosition: string;
  currentCommitmentAmount: number | string;
  name: string;
  monthsRemainingInCurrentPeriod: string;
  projectedVarianceAbsolute: number | string;
  projectedVariancePercentage: number | string;
  resourceID: string;
  resourceName: string;
  showGraphsButtonID: string;
}

export function RampPlansTable(props: Props): JSX.Element {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  const [state, setState] = useState<State>({});
  const mergeState = getMergeState(setState);

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "showGraphsButtonID",
        NoAccessorCell: function renderButton({ row }) {
          return (
            <Flex alignItems="center">
              {!(
                props.selectedRampPlanID === row.original.showGraphsButtonID
              ) && (
                <Tooltip content={copyText.tableButtonClickToView}>
                  <LinkWithSearchParams
                    searchParams={{
                      selectedRampPlanIDToDisplay:
                        row.original.showGraphsButtonID,
                    }}
                  >
                    <Button
                      iconStart={<FontAwesomeIcon icon={faChartLine} />}
                      primary
                      size="tiny"
                    />
                  </LinkWithSearchParams>
                </Tooltip>
              )}
            </Flex>
          );
        },
        disableSortBy: true,
        Header: "",
        width: 40,
      },
      {
        accessor: "actionButtonID",
        Cell: function renderButton({ row }) {
          const dropdownItems = [
            {
              label: copyText.actionMenuItemCopy,
              locked: !gatekeeper.canUpdateRampPlans,
              onClick: () =>
                props.onInteraction({
                  type: RampPlansTable.INTERACTION_COPY_BUTTON_CLICKED,
                  rampPlanID: row.original.actionButtonID,
                }),
            },
            {
              label: copyText.actionMenuItemDownloadCSV,
              onClick: () => {
                const found = props.rampPlans.find(
                  (rp) => rp.id === row.original.actionButtonID
                );
                if (!found) return;

                props.onInteraction({
                  type: RampPlansTable.INTERACTION_CSV_DOWNLOAD_CLICKED,
                  rampPlan: found,
                });
              },
            },
            {
              label: copyText.actionMenuItemEdit,
              locked: !gatekeeper.canUpdateRampPlans,
              onClick: () =>
                props.onInteraction({
                  type: RampPlansTable.INTERACTION_UPDATE_BUTTON_CLICKED,
                  rampPlanID: row.original.actionButtonID,
                }),
            },
            {
              label: copyText.actionMenuItemDelete,
              locked: !gatekeeper.canDeleteRampPlans,
              onClick: () =>
                mergeState({
                  rampPlanIDSelectedToDelete: row.original.actionButtonID,
                }),
            },
          ];

          const matchingQueries = props.rampPlanCSVQueryResultList.filter(
            (query) => query.rampPlanID === row.original.actionButtonID
          );

          if (matchingQueries[matchingQueries.length - 1]?.isLoading) {
            return renderLoader(
              props.selectedRampPlanID === row.original.actionButtonID,
              theme,
              true
            );
          }

          return (
            <Flex alignItems="center">
              {
                <Dropdown options={dropdownItems} placement="bottom-start">
                  <Button
                    iconStart={<FontAwesomeIcon icon={faEllipsisV} />}
                    primary
                    size="tiny"
                  />
                </Dropdown>
              }
            </Flex>
          );
        },
        disableSortBy: true,
        Header: "",
        width: 40,
      },
      {
        accessor: "name",
        Header: copyText.tableHeaderName,
        sortDescFirst: false,
        truncate: true,
        width: 140,
      },
      {
        accessor: "currentCommitmentAmount",
        Header: copyText.tableHeaderCurrentCommitmentAmount,
        sortDescFirst: true,
        truncate: false,
        align: "right",
        Cell: (props) => {
          if (typeof props.value === "string") return <>{props.value}</>;

          return (
            <>
              {formatCurrencyRounded({
                number: props.value,
              })}
            </>
          );
        },
        width: 150,
      },
      {
        accessor: "projectedVarianceAbsolute",
        Header: copyText.tableHeaderProjectedVariance,
        sortDescFirst: false,
        truncate: false,
        align: "right",
        Cell: function renderAbsolute({ row, value }) {
          if (props.loadingAllActuals) {
            return renderLoader(
              props.selectedRampPlanID === row.original.actionButtonID,
              theme
            );
          }

          if (typeof value === "string") return <>{value}</>;

          return renderVariance({
            value,
            formattedValue: formatCurrencyRounded({
              accounting: true,
              number: value,
            }),
            theme,
          });
        },
      },
      {
        accessor: "projectedVariancePercentage",
        align: "left",
        Header: copyText.tableHeaderCurrentPercent,
        sortDescFirst: false,
        truncate: false,
        width: 60,
        Cell: function renderPercentage({ row, value }) {
          if (props.loadingAllActuals) {
            return renderLoader(
              props.selectedRampPlanID === row.original.actionButtonID,
              theme
            );
          }

          if (typeof value === "string") return <>{value}</>;

          return renderVariance({
            value,
            formattedValue: formatPercentage(value),
            theme,
          });
        },
      },
      {
        accessor: "commitmentPeriodPosition",
        align: "center",
        Header: copyText.tableHeaderCurrentPeriod,
        sortDescFirst: false,
        truncate: false,
        width: 100,
      },

      {
        accessor: "monthsRemainingInCurrentPeriod",
        Header: copyText.tableHeaderRemaining,
        sortDescFirst: true,
        truncate: false,
        align: "left",
        width: 90,
      },
    ],
    [props]
  );

  const data = useMemo(() => {
    if (props.loading) return [];

    const tableData = props.rampPlans.map((rampPlan) => {
      const totalPeriods = rampPlan.commitments.length;
      const currentPeriodIndex = rampPlan.commitments.findIndex(
        (commitment) => {
          return (
            !isAfter(new Date(commitment.start), new Date()) &&
            !isBefore(new Date(commitment.end), new Date())
          );
        }
      );

      const isNotCurrent = currentPeriodIndex === -1;

      const commitmentPeriodPosition = isNotCurrent
        ? copyText.notAvailable
        : `${currentPeriodIndex + 1} of ${totalPeriods}`;

      const currentCommitmentAmount = isNotCurrent
        ? copyText.notAvailable
        : rampPlan.commitments[currentPeriodIndex].amount;

      const monthsRemainingInCurrentPeriod = isNotCurrent
        ? copyText.notAvailable
        : differenceInCalendarMonths(
            new Date(),
            new Date(rampPlan.commitments[currentPeriodIndex].end)
          ).toString() +
          " " +
          copyText.unitMonthLabel;

      const { projectedVarianceAbsolute, projectedVariancePercentage } =
        props.loadingAllActuals
          ? { projectedVarianceAbsolute: 0, projectedVariancePercentage: 0 }
          : isNotCurrent
            ? {
                projectedVarianceAbsolute: copyText.notAvailable,
                projectedVariancePercentage: copyText.notAvailable,
              }
            : getProjectionsForRampPlan(
                rampPlan,
                applyOffetsToActualSpend(
                  props.previousNetCostByRampPlanID[rampPlan.id],
                  rampPlan.nonExportOffsetByMonth,
                  rampPlan.nonExportOffsetRecurring
                )
              );

      return {
        id: rampPlan.id,
        actionButtonID: rampPlan.id,
        commitmentPeriodPosition,
        currentCommitmentAmount,
        name: rampPlan.name,
        monthsRemainingInCurrentPeriod,
        projectedVarianceAbsolute,
        projectedVariancePercentage,
        resourceID: rampPlan.id,
        resourceName: rampPlan.name,
        showGraphsButtonID: rampPlan.id,
      };
    });

    return tableData;
  }, [
    getComparisonStringForRampPlans(props.rampPlans),
    props.loadingAllActuals,
    props.previousNetCostByRampPlanID,
  ]);

  useEffect(() => {
    if (props.selectedRampPlanID) return;

    const firstItem = data.sort((a, b) => {
      if (typeof a.currentCommitmentAmount === "string") return -1;
      if (typeof b.currentCommitmentAmount === "string") return -1;

      return b.currentCommitmentAmount - a.currentCommitmentAmount;
    })[0];

    if (firstItem) {
      props.onInteraction({
        type: RampPlansTable.INTERACTION_DISPLAY_BUTTON_CLICKED,
        rampPlanID: firstItem.id,
      });
    }
  }, [getComparisonStringForRampPlans(props.rampPlans), props.loading]);

  return (
    <>
      <ConfirmationModal
        text={copyText.tableDeleteConfirmation}
        loading={props.loadingDelete}
        error={false}
        isOpen={state.rampPlanIDSelectedToDelete !== undefined}
        onAffirmative={() => {
          if (!state.rampPlanIDSelectedToDelete) {
            return;
          }

          props.onInteraction({
            type: RampPlansTable.INTERACTION_DELETE_BUTTON_CLICKED,
            rampPlanID: state.rampPlanIDSelectedToDelete,
          });
        }}
        onClose={() => mergeState({ rampPlanIDSelectedToDelete: undefined })}
      />
      {props.rampPlanCSVQueryResultList.map(renderCSVDownloader)}
      <TableLegacy
        columns={columns}
        data={data}
        initialState={{
          sortBy: [{ id: "currentCommitmentAmount", desc: true }],
        }}
        isLoading={props.loading}
        resourceType={ResourceType.RAMP_PLAN}
        selectedRowID={props.selectedRampPlanID ?? undefined}
        showPagination
        sortable
      />
    </>
  );

  function renderCSVDownloader(
    rampPlanCSVQuery: {
      rampPlanID: string;
      data: NetCostByDateDimensional[] | undefined;
      selectedPricing: string;
    },
    i: number
  ) {
    const rampPlan = props.rampPlans.find(
      (rp) => rp.id === rampPlanCSVQuery.rampPlanID
    );
    if (!rampPlan) return;

    // don't mount component until loading is finished
    if (rampPlanCSVQuery.data === undefined) return;

    const actuals = rampPlanCSVQuery.data;

    const data = getCSVFromRampPlan(rampPlan, actuals);

    return (
      <CSVDownloader
        key={`${rampPlanCSVQuery.rampPlanID}-${i}`}
        data={data}
        fileName={`rampPlan-${
          gatekeeper.canApplyCustomPricing
            ? `${rampPlanCSVQuery.selectedPricing}-`
            : ""
        }${rampPlan.name.replace(" ", "-")}-${rampPlan.billingAccountIDs}.csv`}
        headers={CSV_HEADERS}
      />
    );
  }
}

export const renderVariance = (options: {
  value: number;
  formattedValue: string;
  theme: Theme;
}): JSX.Element => {
  const isNotNegative = options.value >= 0;
  return (
    // Non-Negative values get margin to align with negatives values, since they have parens on the right side
    <Text
      color={
        isNotNegative
          ? options.theme.feedback_positive
          : options.theme.feedback_negative
      }
      marginRight={isNotNegative ? options.theme.space_xxs : 0}
    >
      {options.formattedValue}
    </Text>
  );
};

function renderLoader(
  isSelected: boolean,
  theme: Theme,
  showSpinner?: boolean
) {
  if (showSpinner) {
    return (
      <Box position="relative" padding={theme.space_md}>
        <LoadingSpinner
          color={
            isSelected
              ? theme.primary_color_background_inverse
              : theme.primary_color_background
          }
        />
      </Box>
    );
  }

  return <Skeleton height="100%" theme={theme} />;
}

RampPlansTable.INTERACTION_COPY_BUTTON_CLICKED =
  `RampPlansTableSection.INTERACTION_COPY_BUTTON_CLICKED` as const;
RampPlansTable.INTERACTION_CSV_DOWNLOAD_CLICKED =
  `RampPlansTableSection.INTERACTION_CSV_DOWNLOAD_CLICKED` as const;
RampPlansTable.INTERACTION_DELETE_BUTTON_CLICKED =
  `RampPlansTableSection.INTERACTION_DELETE_BUTTON_CLICKED` as const;
RampPlansTable.INTERACTION_DISPLAY_BUTTON_CLICKED =
  `RampPlansTableSection.INTERACTION_DISPLAY_BUTTON_CLICKED` as const;
RampPlansTable.INTERACTION_UPDATE_BUTTON_CLICKED =
  `RampPlansTableSection.INTERACTION_UPDATE_BUTTON_CLICKED` as const;

interface InteractionCopyButtonClicked {
  type: typeof RampPlansTable.INTERACTION_COPY_BUTTON_CLICKED;
  rampPlanID: string;
}

interface InteractionCSVDownloadClicked {
  type: typeof RampPlansTable.INTERACTION_CSV_DOWNLOAD_CLICKED;
  rampPlan: RampPlan;
}

interface InteractionDeleteButtonClicked {
  type: typeof RampPlansTable.INTERACTION_DELETE_BUTTON_CLICKED;
  rampPlanID: string;
}

interface InteractionDisplayButtonClicked {
  type: typeof RampPlansTable.INTERACTION_DISPLAY_BUTTON_CLICKED;
  rampPlanID: string;
}

interface InteractionUpdateButtonClicked {
  type: typeof RampPlansTable.INTERACTION_UPDATE_BUTTON_CLICKED;
  rampPlanID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RampPlansTable {
  export type Interaction =
    | InteractionCopyButtonClicked
    | InteractionCSVDownloadClicked
    | InteractionDeleteButtonClicked
    | InteractionDisplayButtonClicked
    | InteractionUpdateButtonClicked;
}

export default RampPlansTable;
