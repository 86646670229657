import Permission from "@ternary/api-lib/constants/permissions";
import { isGlobalAdmin } from "@ternary/api-lib/constants/system";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type SavingOpportunityGates = ReturnType<typeof savingOpportunityGates>;

export default function savingOpportunityGates(params: GateGetterParameters) {
  return {
    canCreateSavingOpportunityFilters: params._permissionsIncludeAllOf([
      Permission.CREATE_SAVING_OPPORTUNITY,
    ]),
    canListSavingOpportunityFilters: params._permissionsIncludeAllOf([
      Permission.READ_RECOMMENDATIONS,
    ]),
    canCreateGlobalSavingsOpp:
      params._permissionsIncludeAllOf([Permission.CREATE_SAVING_OPPORTUNITY]) &&
      Boolean(isGlobalAdmin(params.authenticatedUser.email)),
    canEditGlobalSavingsOpp:
      params._permissionsIncludeAllOf([Permission.UPDATE_RECOMMENDATION]) &&
      Boolean(isGlobalAdmin(params.authenticatedUser.email)),
    getCanDeleteSpecificSavingOpp: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_SAVING_OPPORTUNITY]) ||
      Boolean(createdByID === params.authenticatedUser.id),
  };
}
